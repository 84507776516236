import React from 'react';
import { classes } from './iconStyle.st.css';

export const ImgTag = (props: any) => {
  const { isMobile } = props;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 22H4.5C4.225 22 4 21.775 4 21.5V2.5C4 2.225 4.225 2 4.5 2H12V6.5C12 7.327 12.673 8 13.5 8H18V21.5C18 21.775 17.775 22 17.5 22ZM13 2.706L17.294 7H13.5C13.225 7 13 6.775 13 6.5V2.706ZM18.561 6.853L13.147 1.439C12.868 1.16 12.481 1 12.087 1H4.5C3.673 1 3 1.673 3 2.5V21.5C3 22.327 3.673 23 4.5 23H17.5C18.327 23 19 22.327 19 21.5V7.913C19 7.513 18.844 7.136 18.561 6.853Z"
        fill="black"
        className={classes.labelIconLineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 19H21V11H7V19Z"
        fill="#FFCD00"
        className={classes.labelIconLabelColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73401 17.0201H9.67101V12.9821H8.73401V17.0201ZM13.0479 16.5284H12.3649L11.4669 14.5174V17.0194H10.5639V12.9814H11.6029L12.7149 15.5064L13.8329 12.9814H14.8559V17.0194H13.9409V14.5514L13.0479 16.5284ZM19.4072 17.0196H18.5492V16.6186C18.3192 16.9466 17.9652 17.1106 17.4872 17.1106C17.2122 17.1106 16.9592 17.0566 16.7272 16.9526C16.4962 16.8466 16.2932 16.6986 16.1202 16.5086C15.9472 16.3186 15.8122 16.0946 15.7172 15.8366C15.6202 15.5796 15.5722 15.2986 15.5722 14.9986C15.5722 14.6936 15.6242 14.4126 15.7272 14.1566C15.8312 13.9006 15.9762 13.6776 16.1632 13.4876C16.3482 13.2966 16.5692 13.1506 16.8232 13.0466C17.0772 12.9436 17.3552 12.8916 17.6562 12.8916C17.9802 12.8916 18.2852 12.9576 18.5712 13.0916C18.8572 13.2256 19.0932 13.4096 19.2772 13.6426L18.6782 14.2696C18.4032 13.9086 18.0622 13.7276 17.6562 13.7276C17.4412 13.7276 17.2522 13.7816 17.0862 13.8886C16.9202 13.9956 16.7912 14.1446 16.6992 14.3336C16.6062 14.5246 16.5602 14.7466 16.5602 14.9986C16.5602 15.2536 16.6062 15.4776 16.6992 15.6696C16.7912 15.8626 16.9212 16.0106 17.0892 16.1166C17.2562 16.2216 17.4552 16.2746 17.6842 16.2746C18.0682 16.2746 18.3542 16.1186 18.5432 15.8056V15.4996H17.5942V14.7486H19.4072V17.0196Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 41H2.5C2.225 41 2 40.775 2 40.5V3.5C2 3.225 2.225 3 2.5 3H20V11.5C20 12.327 20.673 13 21.5 13H30V40.5C30 40.775 29.775 41 29.5 41ZM21 3.708L29.293 12H21.5C21.225 12 21 11.775 21 11.5V3.708ZM30.561 11.854L21.146 2.439C20.862 2.156 20.485 2 20.085 2H2.5C1.673 2 1 2.673 1 3.5V40.5C1 41.327 1.673 42 2.5 42H29.5C30.327 42 31 41.327 31 40.5V12.914C31 12.52 30.84 12.133 30.561 11.854Z"
        fill="black"
        className={classes.labelIconLineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 35H36V18H7V35Z"
        fill="#FFCD00"
        className={classes.labelIconLabelColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.634 30.433H12.767V22.568H11.634V30.433ZM19.2568 29.6846H18.4318L16.0438 24.3376V30.4326H14.9448V22.5676H16.3088L18.8498 28.3536L21.4128 22.5676H22.7658V30.4326H21.6648V24.3376L19.2568 29.6846ZM31.6758 30.4326H30.6198V29.5406C30.3698 29.8856 30.0438 30.1506 29.6408 30.3326C29.2378 30.5166 28.7788 30.6086 28.2658 30.6086C27.7308 30.6086 27.2338 30.5056 26.7758 30.3006C26.3178 30.0946 25.9168 29.8076 25.5758 29.4366C25.2358 29.0666 24.9718 28.6316 24.7838 28.1336C24.5968 27.6346 24.5038 27.0876 24.5038 26.4946C24.5038 25.9086 24.6008 25.3656 24.7958 24.8666C24.9888 24.3686 25.2588 23.9336 25.6038 23.5626C25.9478 23.1926 26.3558 22.9046 26.8248 22.6996C27.2938 22.4946 27.8038 22.3916 28.3538 22.3916C28.9848 22.3916 29.5658 22.5196 30.0968 22.7766C30.6288 23.0336 31.0718 23.3996 31.4228 23.8756L30.6538 24.6466C30.0948 23.8176 29.3248 23.4036 28.3428 23.4036C27.8288 23.4036 27.3728 23.5356 26.9738 23.7996C26.5728 24.0636 26.2598 24.4286 26.0318 24.8936C25.8058 25.3596 25.6928 25.8936 25.6928 26.4946C25.6928 27.1036 25.8078 27.6426 26.0378 28.1116C26.2688 28.5806 26.5918 28.9456 27.0068 29.2056C27.4208 29.4656 27.8998 29.5966 28.4428 29.5966C28.9108 29.5966 29.3368 29.4926 29.7178 29.2836C30.0988 29.0746 30.3958 28.7826 30.6088 28.4086V27.1986H28.3218V26.2416H31.6758V30.4326Z"
        fill="white"
      />
    </svg>
  );
};
