import {
  ITEM_MENU_OPTIONS,
  STATUS,
} from '../../../../../../../../../Constants/ItemMenu.Constant';

export const getViewDownloadOptions = (t: any) => {
  const selectOption = t('app.widget.table.item.menu.select');
  const downloadOption = t('app.widget.table.item.menu.download');
  const shareOption = t('app.widget.table.item.menu.share');

  return [
    { type: ITEM_MENU_OPTIONS.SELECT, content: selectOption },
    {
      type: ITEM_MENU_OPTIONS.DOWNLOAD,
      content: downloadOption,
    },
    { type: ITEM_MENU_OPTIONS.SHARE, content: shareOption },
  ];
};

export const getManageOptions = (t: any) => {
  const renameOption = t('app.widget.table.item.menu.rename');
  const moveOption = t('app.widget.table.item.menu.move');
  const deleteOption = t('app.widget.table.item.menu.delete');
  return [
    { type: ITEM_MENU_OPTIONS.RENAME, content: renameOption },
    {
      type: ITEM_MENU_OPTIONS.MOVE,
      content: moveOption,
    },
    { type: ITEM_MENU_OPTIONS.DELETE, content: deleteOption },
  ];
};
export const getReportOption = (t: any) => {
  const reportOption = t('app.widget.table.item.menu.report');

  return [
    {
      type: ITEM_MENU_OPTIONS.REPORT,
      content: reportOption,
    },
  ];
};

export const getActionAuthStatus = (item: any, type: string) =>
  item.authorizeActions?.find((itemData: any) => itemData.action === type)
    .status === STATUS.ALLOWED;
