import { getObservableById, setObservableById } from './helper';

export const isDownLoadEnabledSubject$ = new Map();

export const getIsDownloadEnabled$ = (compId: string) =>
  getObservableById(isDownLoadEnabledSubject$, compId, false);
export const setIsDownloadEnabled$ = (
  isDownloadEnabled: boolean,
  compId: string,
) => {
  setObservableById(isDownLoadEnabledSubject$, isDownloadEnabled, compId);
};
