import React from 'react';
import { Availability, Reason, Role } from '../../../../Constants/Permissions';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getUser } from '../../../Services/User.service';
import { requestAccessService } from '../../../Services/AccessModals.service';
import { getSelectedFolderId } from '../../../Services/SelectedFolder.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import withMemberAuthorization from '../../../../Shared/HOC/withMemberAuthorization';
import EmptyStates from '../EmptyStates';
import { getRootFolder } from '../../../Services/RootFolder.service';
import { useAppContext } from '../../AppContext';

const AccessModalsWrapper = ({
  viewPermissions,
  availability,
  memberAuthorization,
  navigateToPricingPlansApp,
  folderName,
}: any) => {
  const { t } = useTranslation();
  const { compId } = useAppContext();
  const [error2, selectedFolderId] = useObservable(getSelectedFolderId(compId));
  const [error3, user]: any = useObservable(getUser());
  const [, rootFolder]: any = useObservable(getRootFolder(compId));
  const openLoginModal = () => {
    memberAuthorization &&
      memberAuthorization({
        args: [],
        callBack: () => {},
        isAccessModal: true,
      });
  };
  const requestAccessFn = () => {
    if (selectedFolderId) {
      requestAccessService(selectedFolderId, t);
    }
  };
  const choosePlanFn = async () => {
    if (navigateToPricingPlansApp) {
      await navigateToPricingPlansApp();
    }
  };

  const renderAccessModals = () => {
    const isUserLoggedIn = user && user?.isLoggedIn;
    const isAdmin = user && user?.role === Role.ADMIN;
    let title = '';
    let subTitle = '';
    let primaryText = '';
    let secondaryText = '';
    let primaryButtonOnClick = () => {};
    let secondaryButtonOnClick = () => {};

    switch (viewPermissions?.reason) {
      case Reason.MISSING_PERMISSION: {
        if (availability !== undefined) {
          if (availability === Availability.PAID) {
            if (rootFolder !== undefined && rootFolder?.noPlansAvailable) {
              title = t(
                'app.widget.pricingPlansEmptyState.NoPlansAvailable.title',
              );
              subTitle = t(
                'app.widget.pricingPlansEmptyState.NoPlansAvailable.subTitle',
              );
              secondaryText = t(
                'app.widget.pricingPlansEmptyState.NoPlansAvailable.primaryButtonText',
              );
            } else {
              title = t('app.widget.accessModals.paid.title');
              subTitle = isUserLoggedIn
                ? t('app.widget.accessModals.paid.member.description')
                : t('app.widget.accessModals.paid.description');
              primaryText = isUserLoggedIn
                ? t('app.widget.accessModals.paid.SwitchAccount.button')
                : t('app.widget.accessModals.paid.login.button');
              secondaryText = t(
                'app.widget.accessModals.paid.ChoosePlan.button',
              );
              primaryButtonOnClick = openLoginModal;
              secondaryButtonOnClick = choosePlanFn;
            }
          } else {
            title = isUserLoggedIn
              ? t('app.widget.accessModals.private.title')
              : t('app.widget.accessModals.private.title.notLogin');
            subTitle = isUserLoggedIn
              ? t('app.widget.accessModals.private.description', { folderName })
              : t('app.widget.accessModals.private.description.notLogin', {
                  folderName,
                });
            primaryText = isUserLoggedIn
              ? t('app.widget.accessModals.private.switchAccount.button')
              : '';
            secondaryText = isUserLoggedIn
              ? t('app.widget.accessModals.private.sendRequest.button')
              : t('app.widget.accessModals.private.login.button');
            primaryButtonOnClick = openLoginModal;
            secondaryButtonOnClick = isUserLoggedIn
              ? requestAccessFn
              : openLoginModal;
          }
        }
        break;
      }
      case Reason.NOT_FOUND:
        title = isAdmin
          ? t('app.widget.emptyStateNoFolders.admin.title')
          : t('app.widget.emptyStateNoFolders.member.title');
        subTitle = isAdmin
          ? t('app.widget.emptyStateNoFolders.admin.subTitle')
          : t('app.widget.emptyStateNoFolders.member.subTitle');
        break;

      // TODO: not working when site vistors can view, and logged out
      case Reason.MUST_BE_A_MEMBER: {
        secondaryButtonOnClick = openLoginModal;
        title = t('app.widget.accessModals.private.title.notLogin');
        subTitle = t('app.widget.accessModals.private.description.notLogin', {
          folderName,
        });
        primaryText = '';
        secondaryText = t('app.widget.accessModals.private.login.button');
        break;
      }
      default:
        break;
    }
    return (
      <EmptyStates
        title={title}
        subTitle={subTitle}
        primaryText={primaryText}
        secondaryText={secondaryText}
        primaryButtonOnClick={primaryButtonOnClick}
        secondaryButtonOnClick={secondaryButtonOnClick}
      />
    );
  };
  return <div>{renderAccessModals()}</div>;
};
export default withMemberAuthorization(AccessModalsWrapper);
