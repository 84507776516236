export const ITEM_MENU_OPTIONS = {
  SELECT: 'SELECT',
  DOWNLOAD: 'DOWNLOAD',
  SHARE: 'SHARE',
  RENAME: 'RENAME',
  PERMISSIONS: 'PERMISSIONS',
  MOVE: 'MOVE',
  DELETE: 'DELETE',
  REPORT: 'REPORT',
  DIVIDER: 'DIVIDER',
};

export const STATUS = {
  FORBIDDEN: 'FORBIDDEN',
  ALLOWED: 'ALLOWED',
};
