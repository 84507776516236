import React, { useEffect, useState } from 'react';
import {
  getMoveObservable,
  moveService,
  moveItems,
  setAddNewFolderMoveObservable,
  getAddNewFolderMoveObservable,
  filterItems,
} from '../../Services/Move/Move.service';
import FooterWrapper from './MoveFooterWrapper/FooterWrapper';
import HeaderWrapper from './MoveHeaderWrapper/HeaderWrapper';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import { MoveContent } from './MoveContent/MoveContent';
import { st, classes } from './MoveWrapper.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { getMoveLoader } from '../../Services/ItemsMove.service';
import { MoveLoadingWrapper } from './MoveLoadingWrapper/MoveLoadingWrapper';
import { getSelectedItems } from '../../Services/SelectedItems.service';
import MoveAddNewFolderOutSideClick from './MoveAddNewFolderOutSideClick';
import MoveAddNewFolderRowClick from './MoveAddNewFolderRowClick';
import { cameFromBack, startMoving } from '../../Services/Move/MovingProccess';
import { getBreadCrumbs } from '../../Services/BreadCrumbs.service';
import { Divider } from 'wix-ui-tpa';
import { moveFileClickBi } from '../../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getAppSettings } from '../../Services/AppSettings.service';
import {getSelectedFolder} from "../../Services/SelectedFolder.service";
import {Status} from "../../../Constants/Permissions";
import {getUser} from "../../Services/User.service";
import {ADMIN} from "../../../Constants/Modals.Constants";
import { useAppContext } from '../AppContext';

const MoveWrapper = (props: any) => {
  const { handleOnCloseMove } = props;
  const { t } = useTranslation();
  const { compId } = useAppContext();
  const [state, setState]: any = useState({
    isRowClicked: false,
    movingToFolderId: '',
    movingToFolderName: '',
    selectedFolderLibraryItems: [],
    createFolder: {},
  });
  const settings = useSettings();
  const selectedFolderIdFromSettings = settings.get(settingsParams.folderId);
  const [error1, moveData]: any = useObservable(getMoveObservable());
  const [error2, moveLoader] = useObservable(getMoveLoader());
  const [error3, selectedItemsIds]: any = useObservable(getSelectedItems(compId));
  const [error4, MoveAddFolder]: any = useObservable(
    getAddNewFolderMoveObservable(),
  );
  const [error5, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = getAppSettings !== undefined ? AppSettings?.isMobile : '';
  const [error6, breadcrumbs]: any = useObservable(getBreadCrumbs(compId));
  const [, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [, user]: any = useObservable(getUser());
  const currentFolderId =
    breadcrumbs?.length > 0 ? breadcrumbs[breadcrumbs.length - 1].id : '';
  const isAdmin: boolean = user?.role === ADMIN;
  const showFolderButton =
    state?.createFolder?.status === Status.ALLOWED || isAdmin;
  useEffect(() => {
    if (selectedFolder) {
      const createFolderObj =
        selectedFolder?.folderAuthorizedActions?.[1] || {};
      setState((prevState: any) => ({
        ...prevState,
        createFolder: createFolderObj,
      }));
    }
  }, [selectedFolder]);
  useEffect(() => {
    moveService(compId);
    return () => {
      setAddNewFolderMoveObservable({ status: false, folderName: '' });
    };
  }, []);
  useEffect(() => {
    const getSelectedFolderLibraryItems = async () => {
      if (selectedItemsIds) {
        const selectedFolderLibraryItems: any = await filterItems(
          selectedItemsIds,
          compId,
        );
        moveFileBiHelper('click', selectedFolderLibraryItems);
      }
    };
    getSelectedFolderLibraryItems();
  }, [selectedItemsIds]);
  const moveFileBiHelper = (
    description: string,
    selectedFolderLibraryItems: any,
  ) => {
    if (selectedFolderLibraryItems && selectedFolderLibraryItems.length > 0) {
      selectedFolderLibraryItems.map((item: any) => {
        moveFileClickBi(item, description, selectedFolderLibraryItems.length, compId);
      });
    }
  };
  const handleMoveClick = () => {
    if (moveData !== undefined) {
      moveItems({
        newParentFolderId: moveData?.currentFolderId,
        destFolder: {
          name: moveData?.currentFolderName,
          id: moveData?.currentFolderId,
        },
        t,
        compId,
      });
    }
  };

  const handleIsRowClicked = (
    isRowClicked: boolean,
    movingToFolderId: string,
    movingToFolderName: string,
  ) => {
    if (MoveAddFolder !== undefined && MoveAddFolder.folderName === '') {
      startMoving(compId,movingToFolderId, movingToFolderName);
      return;
    } else {
      setState((prevState: any) => ({
        ...prevState,
        isRowClicked,
        movingToFolderId,
        movingToFolderName,
      }));
    }
  };
  return (
    <>
      {moveData !== undefined ? (
        <div
          className={
            st(classes.moveWrapper, { isMobile }) +
            ' ' +
            (selectedItemsIds !== undefined && selectedItemsIds.length > 0
              ? classes.moveWrapperSelectionMode
              : '') +
            ' ' +
            (isMobile ? classes.moveWrapperMobileView : '')
          }
        >
          <HeaderWrapper
            isMobile={isMobile}
            name={moveData.currentFolderName}
            onClick={() => {
              if (moveData.currentFolderId !== selectedFolderIdFromSettings) {
                cameFromBack(
                  moveData.parentFolderId,
                  moveData.parentFolderName,
                  compId,
                );
              }
            }}
            disabled={moveData.currentFolderId !== selectedFolderIdFromSettings}
            onClose={handleOnCloseMove}
          />
          <Divider className={classes.rowDivider} />
          {moveLoader && moveLoader === true ? (
            <MoveLoadingWrapper isMobile={isMobile}/>
          ) : (
            <div className={st(classes.rowContentWrapper, { isMobile })}>
              {MoveAddFolder !== undefined && MoveAddFolder?.status === true ? (
                <>
                  {state.isRowClicked === false ? (
                    <MoveAddNewFolderOutSideClick
                      isMobile={isMobile}
                      isRowClicked={state.isRowClicked}
                      handleIsRowClicked={handleIsRowClicked}
                      moveAddFolderFolderName={MoveAddFolder.folderName}
                      moveAddFolderFolderStatus={MoveAddFolder.status}
                    />
                  ) : (
                    <MoveAddNewFolderRowClick
                      isMobile={isMobile}
                      isRowClicked={state.isRowClicked}
                      handleIsRowClicked={handleIsRowClicked}
                      movingToFolderId={state.movingToFolderId}
                      movingToFolderName={state.movingToFolderName}
                    />
                  )}
                </>
              ) : null}
              <>
                <MoveContent
                  isMobile={isMobile}
                  handleIsRowClicked={handleIsRowClicked}
                  currentSubFolders={moveData.currentSubFolders}
                />
              </>
            </div>
          )}
          <Divider className={classes.rowDivider} />
          <FooterWrapper
            showFolderButton={showFolderButton}
            handleMoveClick={handleMoveClick}
            disabled={
              currentFolderId && currentFolderId === moveData?.currentFolderId
            }
            isLoading={moveLoader !== undefined && moveLoader === true}
            handleNewFolder={() => {
              if (
                MoveAddFolder !== undefined &&
                MoveAddFolder.status === false
              ) {
                const newObj = {
                  status: true,
                  folderName: '',
                };
                setState((prevState: any) => ({
                  ...prevState,
                  isRowClicked: false,
                }));
                setAddNewFolderMoveObservable(newObj);
              }
            }}
            isMobile={isMobile}
          />
        </div>
      ) : null}
    </>
  );
};

export default MoveWrapper;
