import { EditorFileSharingService } from '../Api/Api';
import { setAllFolders$ } from '../Observables/Folders.observable';
import { FileSharingService } from '../../Widget/Api/Api';

let API = null;

export const GetAllFolders = async (from = 'settings') => {
  API =
    from === 'settings'
      ? new EditorFileSharingService()
      : new FileSharingService();
  const response = await API.getAllFolders();
  const tempResults = { ...response };
  if (tempResults?.data?.libraryItems) {
    const filterForFoldersOnly = tempResults?.data?.libraryItems.filter(
      (item: any) => {
        return item.hasOwnProperty('folderFields');
      },
    );
    tempResults.data.libraryItems = filterForFoldersOnly;
    return tempResults;
  }
};

export const setAllFolders = (folders: []) => {
  setAllFolders$(folders);
};
