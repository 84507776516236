import React from 'react';
import { classes } from './iconStyle.st.css';

export const PdfTag = (props: any) => {
  const { isMobile } = props;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 22H4.5C4.225 22 4 21.775 4 21.5V2.5C4 2.225 4.225 2 4.5 2H12V6.5C12 7.327 12.673 8 13.5 8H18V21.5C18 21.775 17.775 22 17.5 22ZM13 2.706L17.294 7H13.5C13.225 7 13 6.775 13 6.5V2.706ZM18.561 6.853L13.147 1.439C12.868 1.16 12.481 1 12.087 1H4.5C3.673 1 3 1.673 3 2.5V21.5C3 22.327 3.673 23 4.5 23H17.5C18.327 23 19 22.327 19 21.5V7.913C19 7.513 18.844 7.136 18.561 6.853Z"
        fill="black"
        className={classes.labelIconLineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99899 19H20.999V11H6.99899V19Z"
        fill="#FA6050"
        className={classes.labelIconLabelColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4189 13.7772H9.7639V14.7712H10.4189C10.5689 14.7712 10.6889 14.7262 10.7769 14.6362C10.8649 14.5452 10.9099 14.4252 10.9099 14.2752C10.9099 14.1212 10.8649 13.9982 10.7769 13.9102C10.6889 13.8222 10.5689 13.7772 10.4189 13.7772ZM9.7639 17.0192H8.8259V12.9802H10.4189C10.7009 12.9802 10.9499 13.0352 11.1669 13.1442C11.3839 13.2542 11.5519 13.4062 11.6719 13.5992C11.7929 13.7932 11.8539 14.0192 11.8539 14.2752C11.8539 14.5312 11.7929 14.7562 11.6719 14.9532C11.5519 15.1482 11.3839 15.2982 11.1669 15.4062C10.9499 15.5132 10.7009 15.5682 10.4189 15.5682H9.7639V17.0192ZM13.4619 16.2226H13.9649C14.3119 16.2226 14.5819 16.1136 14.7779 15.8926C14.9739 15.6716 15.0719 15.3726 15.0719 14.9976C15.0719 14.6246 14.9739 14.3276 14.7779 14.1076C14.5819 13.8886 14.3119 13.7776 13.9649 13.7776H13.4619V16.2226ZM12.5249 17.0196V12.9806H13.9649C14.3869 12.9806 14.7549 13.0666 15.0689 13.2386C15.3829 13.4096 15.6269 13.6466 15.7999 13.9496C15.9739 14.2536 16.0599 14.6016 16.0599 14.9976C16.0599 15.3926 15.9739 15.7426 15.7999 16.0446C15.6269 16.3476 15.3829 16.5856 15.0689 16.7596C14.7549 16.9316 14.3869 17.0196 13.9649 17.0196H12.5249ZM17.7139 17.0194H16.7769V12.9804H19.4479V13.7774H17.7139V14.6244H19.3469V15.4214H17.7139V17.0194Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 41H2.5C2.225 41 2 40.775 2 40.5V3.5C2 3.225 2.225 3 2.5 3H20V11.5C20 12.327 20.673 13 21.5 13H30V40.5C30 40.775 29.775 41 29.5 41ZM21 3.708L29.293 12H21.5C21.225 12 21 11.775 21 11.5V3.708ZM30.561 11.854L21.146 2.439C20.862 2.156 20.485 2 20.085 2H2.5C1.673 2 1 2.673 1 3.5V40.5C1 41.327 1.673 42 2.5 42H29.5C30.327 42 31 41.327 31 40.5V12.914C31 12.52 30.84 12.133 30.561 11.854Z"
        fill="black"
        className={classes.labelIconLineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 35H36V18H7V35Z"
        fill="#FA6050"
        className={classes.labelIconLabelColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6807 23.5469H12.8767V26.1769H14.6807C15.1137 26.1769 15.4537 26.0599 15.7027 25.8239C15.9527 25.5889 16.0767 25.2669 16.0767 24.8569C16.0767 24.4449 15.9527 24.1239 15.7027 23.8939C15.4537 23.6629 15.1137 23.5469 14.6807 23.5469ZM12.8767 30.4329H11.7427V22.5679H14.6807C15.1867 22.5679 15.6297 22.6629 16.0117 22.8549C16.3927 23.0449 16.6897 23.3109 16.9027 23.6509C17.1157 23.9929 17.2217 24.3939 17.2217 24.8569C17.2217 25.3119 17.1157 25.7119 16.9027 26.0609C16.6897 26.4089 16.3927 26.6789 16.0117 26.8689C15.6297 27.0599 15.1867 27.1549 14.6807 27.1549H12.8767V30.4329ZM19.9824 29.4424H21.4454C22.0094 29.4424 22.4964 29.3204 22.9034 29.0754C23.3094 28.8294 23.6234 28.4844 23.8434 28.0404C24.0634 27.5964 24.1734 27.0824 24.1734 26.4954C24.1734 25.9094 24.0634 25.3954 23.8434 24.9554C23.6234 24.5144 23.3094 24.1724 22.9034 23.9264C22.4964 23.6804 22.0094 23.5574 21.4454 23.5574H19.9824V29.4424ZM18.8494 30.4324V22.5674H21.4454C22.2224 22.5674 22.9044 22.7374 23.4914 23.0744C24.0784 23.4114 24.5364 23.8764 24.8664 24.4654C25.1964 25.0554 25.3614 25.7324 25.3614 26.4954C25.3614 27.2644 25.1964 27.9454 24.8664 28.5364C24.5364 29.1264 24.0784 29.5894 23.4914 29.9264C22.9044 30.2644 22.2224 30.4324 21.4454 30.4324H18.8494ZM28.2432 30.4326H27.1102V22.5676H31.9612V23.5466H28.2432V25.9556H31.7642V26.9346H28.2432V30.4326Z"
        fill="white"
      />
    </svg>
  );
};
