import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getUser } from '../../../Services/User.service';
import { ADMIN } from '../../../../Constants/Modals.Constants';
import EmptyStates from '../EmptyStates';

const EmptyStateNoFolders = () => {
  const { t } = useTranslation();
  const [, user]: any = useObservable(getUser());
  const isAdmin: boolean = user?.role === ADMIN;
  const text1 =
    isAdmin !== undefined && isAdmin
      ? t('app.widget.emptyStateNoFolders.admin.title')
      : t('app.widget.emptyStateNoFolders.member.title');
  const text2 =
    isAdmin !== undefined && isAdmin
      ? t('app.widget.emptyStateNoFolders.admin.subTitle')
      : t('app.widget.emptyStateNoFolders.member.subTitle');

  return <EmptyStates title={text1} subTitle={text2} />;
};

export default EmptyStateNoFolders;
