import React, { useEffect, useMemo } from 'react';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import {
  getSelectedFolderId,
  setSelectedFolder,
} from '../../Services/SelectedFolder.service';
import HeaderWrapper from './HeaderWrapper/HeaderWrapper';
import TableWrapper from './TableWrapper/TableWrapper';
import DialogWrapper from '../../Shared/DialogWrapper/DialogWrapper';
import { getOpenedModal } from '../../Services/Modals.service';
import ToastWrapper from '../../Shared/ToastWrapper/ToastWrapper';
import { getToast } from '../../Services/Toast.service';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { setTableSettings } from '../../Services/TableSettings.service';
import { setSort } from '../../Services/Sort.service';
import { SORT } from '../../../Constants/TableHeader.Constants';
import { classes } from './FolderTable.st.css';
import { getAppSettings } from '../../Services/AppSettings.service';
import MobileHeaderWrapper from './MobileView/MobileHeaderWrapper/MobileHeaderWrapper';
import { useWixSdk } from '@wix/yoshi-flow-editor';
import { widgetDetailsBi } from '../../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';
import { getRootFolder } from '../../Services/RootFolder.service';
import {setSelectedFolderIdInSettings} from "../../Shared/add-ons/helpers";
import { useAppContext}  from '../../Shared/AppContext';

const FolderTable = () => {
  const { compId } = useAppContext();
  const [, selectedFolderId] = useObservable(getSelectedFolderId(compId));
  const [, openedModal] = useObservable(getOpenedModal(compId));
  const [, Toast]: any = useObservable(getToast());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, rootFolder]: any = useObservable(getRootFolder(compId));

  const settings = useSettings();
  const { Wix } = useWixSdk();
  const settingsForTable = settings.get(settingsParams.displaySettings);
  const sortBySettings = settings.get(settingsParams.sortBySettings);
  const alignmentSettings = settings.get(settingsParams.alignment);
  const fileIconStyleSettings = settings.get(settingsParams.fileIconStyle);
  const folderIdSettings = settings.get(settingsParams.folderId);
  const isMobile = getAppSettings !== undefined ? appSettings?.isMobile : '';
  const setSortSettings = () => {
    let sortBy = '';
    let orientation = '';
    if (sortBySettings === 'NAME_DESC') {
      orientation = SORT.ORIENTATION.DESC;
      sortBy = SORT.SORT_BY.NAME;
    } else if (sortBySettings === 'NAME_ASC') {
      orientation = SORT.ORIENTATION.ASC;
      sortBy = SORT.SORT_BY.NAME;
    } else {
      orientation = SORT.ORIENTATION.DESC;
      sortBy = sortBySettings;
    }
    setSort({ sortBy, orientation }, compId);
  };
  const _setTableSettings = () => {
    const results: any = [];
    settingsForTable.map((item: any) => {
      if (item.hasOwnProperty('SEARCH_BAR')) {
        results.push({ id: 'SEARCH_BAR', value: item.SEARCH_BAR });
      } else if (item.hasOwnProperty('FILE_SIZE_NUMBER_OF_ITEMS')) {
        results.push({
          id: 'FILE_SIZE_NUMBER_OF_ITEMS',
          value: item.FILE_SIZE_NUMBER_OF_ITEMS,
        });
      } else if (item.hasOwnProperty('LAST_UPDATED')) {
        results.push({ id: 'LAST_UPDATED', value: item.LAST_UPDATED });
      } else if (item.hasOwnProperty('VIEWS_COUNTER')) {
        results.push({ id: 'VIEWS_COUNTER', value: item.VIEWS_COUNTER });
      } else if (item.hasOwnProperty('FAVORITES')) {
        results.push({ id: 'FAVORITES', value: item.FAVORITES });
      } else if (item.hasOwnProperty('FAVORITES_COUNTER')) {
        results.push({
          id: 'FAVORITES_COUNTER',
          value: item.FAVORITES_COUNTER,
        });
      } else if (item.hasOwnProperty('CONTRIBUTORS')) {
        results.push({ id: 'CONTRIBUTORS', value: item.CONTRIBUTORS });
      }
    });
    setTableSettings(results, compId);
  };

  // const memoizedValue = useMemo(() => {
  //   const _selectedFolderId = {
  //     selectedFolderId,
  //   };
  //   return _selectedFolderId;
  // }, [selectedFolderId]);

  useEffect(() => {
    setSortSettings();
    if (selectedFolderId && selectedFolderId !== '') {
      setSelectedFolder(selectedFolderId, compId);
    }
  }, [sortBySettings]);

  useEffect(() => {
    _setTableSettings();
  }, [settingsForTable]);

  useEffect(() => {
    if (
      selectedFolderId &&
      selectedFolderId !== ''
      // memoizedValue !== selectedFolderId
    ) {
      setSelectedFolder(selectedFolderId, compId);
    }
  }, [selectedFolderId, appSettings?.instance]);

  useEffect(() => {
    let id: any = '';
    if (Wix) {
      id = Wix?.addEventListener(Wix?.Events.SITE_PUBLISHED, (res) => {
        setSelectedFolderIdInSettings(
          'selectedFolderIdInLiveSite',
          settings.get(settingsParams.folderId),
          appSettings.isEditor,
          Wix,
        );
        widgetDetailsBi(
          settingsForTable,
          sortBySettings,
          alignmentSettings,
          fileIconStyleSettings,
          folderIdSettings,
          rootFolder.value,
          compId,
        );
      });
    }
    return () => {
      if (Wix && id !== '' && 'removeEventListener' in Wix) {
        // @ts-ignore
        Wix?.removeEventListener(Wix?.Events.SITE_PUBLISHED, id);
      }
    };
  }, [
    Wix,
    alignmentSettings,
    fileIconStyleSettings,
    folderIdSettings,
    rootFolder,
    settingsForTable,
    sortBySettings,
  ]);

  return (
    <div>
      {Toast && Toast?.skin.length > 0 && Toast?.content ? (
        <div
          className={
            (!isMobile ? classes.toastContainer : '') +
            ' ' +
            classes.toastContainerZindex
          }
        >
          <ToastWrapper />
        </div>
      ) : null}
      <>
        {appSettings !== undefined && appSettings.isMobile === true ? (
          <>
            <MobileHeaderWrapper />
            <TableWrapper />
          </>
        ) : (
          <div
            className={classes.desktopContainer + ' ' + classes.appContainer}
            style={{ zIndex: 'calc(var(--above-all-in-container) - 1 )' }}
          >
            <HeaderWrapper />
            <TableWrapper />
          </div>
        )}
        {openedModal && openedModal !== '' && (
          <DialogWrapper type={openedModal} isMobile={appSettings?.isMobile} />
        )}
      </>
    </div>
  );
};

export default FolderTable;
