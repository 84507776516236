import React from "react";
import { FILE_ICON_STYLE } from "../../../Constants/Display.Constants";
import { FolderFill } from "../../icons/Folder_Fill";
import { FolderOutline } from "../../icons/Folder_Outline";

export const getFolderIcon = (folderIconStyle: string, isMobile: boolean) => {
  return folderIconStyle === FILE_ICON_STYLE.FILL_ICON ? (
    <FolderFill isMobile={isMobile} />
  ) : (
    <FolderOutline folderIconStyle={folderIconStyle} isMobile={isMobile} />
  );
};
