import React from 'react';
import ActionsToolBar from './ActionsToolBar/ActionsToolBar';
import MainToolBar from './MainToolBar/MainToolBar';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../../Services/SelectedItems.service';
import { useAppContext } from '../../../../Shared/AppContext';

const ToolBarWrapper = () => {
  const { compId } = useAppContext();

  const [error1, selectedItems]: any = useObservable(getSelectedItems(compId));

  return (
    <>
      {selectedItems && selectedItems.length > 0 ? (
        <ActionsToolBar />
      ) : (
        <MainToolBar />
      )}
    </>
  );
};

export default ToolBarWrapper;
