export const SEARCH_BAR = 'SEARCH_BAR';
export const FILE_SIZE_NUMBER_OF_ITEMS = 'FILE_SIZE_NUMBER_OF_ITEMS';
export const LAST_UPDATED = 'LAST_UPDATED';
export const VIEWS_COUNTER = 'VIEWS_COUNTER';
export const FAVORITES = 'FAVORITES';
export const FAVORITES_COUNTER = 'FAVORITES_COUNTER';
export const CONTRIBUTORS = 'CONTRIBUTORS';
export const SORT_BY_OPTIONS = {
  LAST_MODIFIED: 'LAST_MODIFIED',
  UNIQUE_VIEWS: 'UNIQUE_VIEWS',
  TIMES_FAVORITED: 'TIMES_FAVORITED',
  TYPE: 'TYPE',
  SIZE: 'SIZE',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
};
export const FILE_ICON_STYLE = {
  LABEL_ICON: 'LABEL_ICON',
  FILL_ICON: 'FILL_ICON',
  LINE_ICON: 'LINE_ICON',
};
