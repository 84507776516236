import React from 'react';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import settingsParams from '../../../../../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { getFileIcon } from '../../../../../../Shared/FileAndFolderIcon/getFileIcon';
import { getFolderIcon } from '../../../../../../Shared/FileAndFolderIcon/getFolderIcon';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';

const ItemNameIcon = (props: any) => {
  const [error1, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const { item } = props;
  const settings = useSettings();
  const fileIconStyle: string = settings.get(settingsParams.fileIconStyle);
  return (
    <>
      {ITEMTYPE.FILE in item
        ? getFileIcon(
            item?.fileFields?.extension?.toUpperCase(),
            fileIconStyle,
            isMobile,
          )
        : ITEMTYPE.FOLDER in item
        ? getFolderIcon(fileIconStyle, isMobile)
        : null}
    </>
  );
};

export default ItemNameIcon;
