import { ITEM_MENU_OPTIONS } from './Constants';

export const getManageOptions = (t: any, itemsLength: number) => {
  const moveOption = t('app.widget.table.item.menu.move');
  const deleteOption = t('app.widget.table.item.menu.delete');
  const downloadOption = t('app.widget.table.item.menu.download');
  const selectAllOption = t('app.widget.moreActionsMobile.menu.selectAll');
  const deSelectAllOption = t('app.widget.moreActionsMobile.menu.deselectAll');
  return [
    {
      type:
        itemsLength !== undefined && itemsLength === 0
          ? ITEM_MENU_OPTIONS.SELECT_ALL
          : ITEM_MENU_OPTIONS.DESELECT_ALL,
      content:
        itemsLength !== undefined && itemsLength === 0
          ? selectAllOption
          : deSelectAllOption,
    },
    { type: ITEM_MENU_OPTIONS.DELETE, content: deleteOption },
    {
      type: ITEM_MENU_OPTIONS.MOVE,
      content: moveOption,
    },
    {
      type: ITEM_MENU_OPTIONS.DOWNLOAD,
      content: downloadOption,
    },
  ];
};
