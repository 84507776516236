import {
  getIsServerError$,
  setIsServerError$,
} from '../Observables/ServerError.observable';

export const setIsServerError = (error: any, compId: string) => {
  setIsServerError$(error, compId);
};

export const getIsServerError = (compId: string) => {
  return getIsServerError$(compId);
};
