import {
  UPLOAD_GLITCH,
  UPLOAD_OFFLINE,
} from '../../../Constants/UploadTosts.Constants';
import { FileSharingService } from '../../Api/Api';
import { demoFile } from '../../Components/FolderTable/TableWrapper/ItemsWrapper/FileSkeletonWrapper/SkeletonItem/FileSkeletonItemDemo';
import {
  getUploadingFileStatus$,
  setUploadingFileStatus$,
  addFilesToSelectedFolder$,
} from '../../Observables/File.observable';
import { checkConnection } from '../../Shared/CheckConnection';
import { getCurrentSelectedId } from '../Search.service';
import { UploadOfflineErrorNotifications } from './UploadOfflineErrorNotifications';

import { uploadFile } from './UploadFile';
import { UploadGlitchErrorNotifications } from './UploadGlitchErrorNotifications';
import { completeFileUpload } from './CompleteFileUpload';
import { checkFilesSizes } from './CheckFilesSizes';
import { checkTotalAmount } from './CheckTotalAmount';

let API: any = null;
API = new FileSharingService();

export const getUploadingFileStatus = (compId: string) => {
  return getUploadingFileStatus$(compId);
};

export const setUploadingFileStatus = (
  newUploadingStatus: any,
  compId: string,
) => {
  setUploadingFileStatus$(newUploadingStatus, compId);
};

export const addFilesToSelectedFolder = (
  newLibraryItems: any,
  compId: string,
) => {
  addFilesToSelectedFolder$(newLibraryItems, compId);
};

export const _startFileUpload = async (files: any, t: any, compId: string) => {
  let isConnected = false;
  try {
    const response = await checkConnection();
    if (response) {
      isConnected = true;
    }
  } catch (e: any) {
    const tempType =
      files.length === 1
        ? UPLOAD_OFFLINE.FILEUPLOAD_SINGLE_OFFLINE
        : UPLOAD_OFFLINE.FILEUPLOAD_MULTIPLE_OFFLINE;
    UploadOfflineErrorNotifications(t, tempType, files.length);
  }

  if (isConnected === false) {
    return;
  }

  if (files.length > 100) {
    checkTotalAmount(t, files);
    return;
  }

  const isExceed = checkFilesSizes(t, files);
  if (isExceed > 0) {
    return;
  }

  const tempDemoLibraryItems = Array(files.length).fill(demoFile);
  const parentFolderId = await getCurrentSelectedId(compId);
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < tempDemoLibraryItems.length; i++) {
    const tempObj = JSON.parse(JSON.stringify(tempDemoLibraryItems[0]));
    tempObj.name = files[i].name;
    tempObj.fileFields.size = files[i].size;
    tempObj.fileFields.sizeInBytes = files[i].size;
    tempDemoLibraryItems[i] = tempObj;
  }
  setUploadingFileStatus(
    {
      status: 'OPENED',
      libraryItems: tempDemoLibraryItems,
    },
    compId,
  );
  const totalFilesAmount = files.length;
  const tempFilesArr: any[] = [];
  let success: any = [];
  let failure: any = [];
  const listOfCompletion = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < totalFilesAmount; i++) {
    const tempObj: any = {};
    tempObj.name = files[i].name;
    tempObj.lastModified = files[i].lastModified;
    tempObj.lastModifiedDate = files[i].lastModifiedDate;
    tempObj.sizeInBytes = files[i].size;
    tempObj.type = files[i].type;
    tempObj.webkitRelativePath = files[i].webkitRelativePath;
    tempObj.parentFolderId = parentFolderId;
    tempObj.actionId = 'action' + [i];
    tempFilesArr.push(tempObj);
  }
  // add the following lines to create a filtering
  // const tempObj2: any = {};
  // tempObj2.name = 'test';
  // tempObj2.lastModified = '';
  // tempObj2.lastModifiedDate = '';
  // tempObj2.sizeInBytes = 10;
  // tempObj2.type = '';
  // tempObj2.webkitRelativePath = '';
  // tempObj2.actionId = 'action1';
  // tempFilesArr.push(tempObj2);
  try {
    const response = await API.startFileUpload({
      actions: tempFilesArr,
    });
    // console.log(response);
    if (response?.data) {
      success = [...response.data.urls];
      failure = [...response.data.failures];
      const failureActionIds = failure.map((item: any) => item.actionId);
      // console.log(files);
      // console.log(tempFilesArr);
      // console.log(success);
      // console.log(failure);
      // console.log(newTempFilesArr);
      // console.log(failureActionIds);
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < success.length; i++) {
        if (!failureActionIds.includes(success[i].actionId)) {
          listOfCompletion.push(
            await uploadFile(files[i], success[i], parentFolderId),
          );
        }
      }
      completeFileUpload(t, listOfCompletion, success, compId);
    } else {
      UploadGlitchErrorNotifications(
        t,
        UPLOAD_GLITCH.FILEUPLOAD_GENERAL_GLITCH,
        success.length,
      );
      setUploadingFileStatus(
        {
          status: 'CLOSED',
          libraryItems: [],
        },
        compId,
      );
    }
  } catch {
    UploadGlitchErrorNotifications(
      t,
      UPLOAD_GLITCH.FILEUPLOAD_GENERAL_GLITCH,
      files.length,
    );
    setUploadingFileStatus(
      {
        status: 'CLOSED',
        libraryItems: [],
      },
      compId,
    );
  }
};
