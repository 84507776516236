import React, { createContext, useContext } from 'react';

interface AppContextType {
  compId: string;
}

const AppContext = createContext<AppContextType>({ compId: '' });

export const AppContextProvider = ({
  value,
  children,
}: {
  value: AppContextType;
  children: React.ReactNode;
}) => <AppContext.Provider value={value}>{children}</AppContext.Provider>;

export const useAppContext = () => {
  return useContext(AppContext);
};
