import { BehaviorSubject, map, lastValueFrom } from 'rxjs';
import { AppSettingsInterface } from '../Interfaces/AppSettings.Interface';
import { take } from 'rxjs/operators';

export const AppSubject$ = new BehaviorSubject(
  (undefined as unknown) as AppSettingsInterface,
);

export const addItemToTestArr = async (item: string) => {
  const tempResult = AppSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.testarr = [...newResult.testarr, item];
  AppSubject$.next(newResult);
};

export const getAppSettings$ = () => AppSubject$;

export const setAppSettings$ = (AppSettings: AppSettingsInterface) =>
  AppSubject$.next(AppSettings);

export const getIsAccessibilityEnabled$ = () =>
  AppSubject$.pipe(map(item => item.accessibilityEnabled));
export const getDimensions$ = () =>
  AppSubject$.pipe(map(item => item.dimensions));
export const getFormFactor$ = () =>
  AppSubject$.pipe(map(item => item.formFactor));
export const getPageId$ = () => AppSubject$.pipe(map(item => item.pageId));
export const getStyle$ = () => AppSubject$.pipe(map(item => item.style));
export const getStyleId$ = () => AppSubject$.pipe(map(item => item.styleId));
export const getViewMode$ = () => AppSubject$.pipe(map(item => item.viewMode));
export const getIsMobile$ = () => AppSubject$.pipe(map(item => item.isMobile));
export const getIsRTL$ = () => AppSubject$.pipe(map(item => item.isRTL));
export const getappDefinition$ = () =>
  AppSubject$.pipe(map(item => item._appDefinitionId));
export const getWidgetId$ = () => AppSubject$.pipe(map(item => item._widgetId));
export const getTranslations$ = () => AppSubject$.pipe(map(item => item.t));
