import React from 'react';
import { ActionsMenuLayout, ActionsMenuLayoutItemAlignment } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getSortIds, getSortLabels } from './helpers';
import customBuildActionsMenuLayoutItem from './customBuildActionsMenuLayoutItem';
import { getSelectedFolderId } from '../../../../../../Services/SelectedFolder.service';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { st, classes } from './ActionsMenuLayoutComponent.st.css';
import { useAppContext } from '../../../../../../Shared/AppContext';

const ActionsMenuLayoutComponent = ({
  sortBy,
  orientation,
  isMobile,
  setIsOpen,
}: any) => {
  const { t }: { t: any } = useTranslation();
  const { compId } = useAppContext();
  const sortIds = getSortIds();
  const sortLabels = getSortLabels(t);
  const [, selectedFolderId]: any = useObservable(getSelectedFolderId(compId));
  return (
    <ActionsMenuLayout
      className={st(classes.sortMenuWrapper, { isMobile })}
      alignment={ActionsMenuLayoutItemAlignment.left}
    >
      {sortIds?.map((item: any, index: any) => {
        return customBuildActionsMenuLayoutItem({
          id: item,
          content: sortLabels[index],
          orientation,
          sortBy,
          selectedFolderId,
          isMobile,
          setIsOpen,
          compId,
        });
      })}
    </ActionsMenuLayout>
  );
};
export default ActionsMenuLayoutComponent;
