import React, { ChangeEvent, useRef } from 'react';
import { Button } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../MainToolBar.st.css';
import withMemberAuthorization from '../../../../../../../Shared/HOC/withMemberAuthorization';
import { _startFileUpload } from '../../../../../../Services/Upload/Upload.service';
import { supportedTypes } from '../../../../../../Services/Upload/SupportedFilesTypes';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK } from '../../../../../../BiEvents/Constants/BiConstants';
import { handleMemberAuth } from '../../../../../../Shared/memberAuthorizationHelper';
import { useAppContext } from '../../../../../../Shared/AppContext';

const FileButton = (props: any) => {
  const {
    memberAuthorization,
    uploadFilePermissions,
    openPrivacyModal,
    isUploadFileDisabled,
    isMobile,
  } = props;
  const { t } = useTranslation();
  const { compId } = useAppContext();
  const inputFile = useRef<HTMLInputElement>(null);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files.length) {
      _startFileUpload(files, t, compId);
    }
  };

  const onClick = () => {
    if (inputFile) {
      inputFile?.current?.click();
    }
    biService({ is_first: false }, BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK, compId);
  };
  const handleUploadFileClick = () => {
    handleMemberAuth({
      permissions: uploadFilePermissions,
      callBack: onClick,
      openPrivacyModal,
      memberAuthorization,
    });
  };
  return (
    <>
      <input
        style={{ display: 'none' }}
        multiple
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
        accept={supportedTypes}
      />
      {isUploadFileDisabled !== undefined ? (
        <Button
          disabled={isUploadFileDisabled}
          className={st(classes.fileButton, { isMobile })}
          onClick={handleUploadFileClick}
        >
          {t('app.widget.header.file.button')}
        </Button>
      ) : null}
    </>
  );
};

export default withMemberAuthorization(FileButton);
