import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Text } from 'wix-ui-tpa';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import BreadcumbsMulti from './BreadcrumbsMulti/BreadcrumbsMulti';
import SetBreadcrumbsCorrectPath from './SetBreadcrumbsCorrectPath';
import { st, classes } from './BreadcrumbsWrapper.st.css';
import {
  getIsLoading,
  setIsLoading,
} from '../../../../Services/Loading.service';
import { getBreadCrumbs } from '../../../../Services/BreadCrumbs.service';
import SearchResultsText from '../SearchResultsText/SearchResultsText';
import { getSearchStatus } from '../../../../Services/Search.service';
import { getRootFolder } from '../../../../Services/RootFolder.service';
import { getAppSettings } from '../../../../Services/AppSettings.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getSelectedFolder } from '../../../../Services/SelectedFolder.service';
import { Reason } from '../../../../../Constants/Permissions';
import { useAppContext } from '../../../../Shared/AppContext';

const BreadcrumbsWrapper = () => {
  const { compId } = useAppContext();
  const [error1, breadCrumbs]: any = useObservable(getBreadCrumbs(compId));
  const [items, setItems] = useState<any>([]);
  const [error3, searchStatus]: any = useObservable(getSearchStatus(compId));
  const [error4, rootFolder]: any = useObservable(getRootFolder(compId));
  const [error5, AppSettings]: any = useObservable(getAppSettings());
  const [, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [, isLoading]: any = useObservable(getIsLoading(compId));
  const isMobile = getAppSettings !== undefined ? AppSettings?.isMobile : '';
  const { t } = useTranslation();
  const handleOnClick = (item: any) => {
    if (item.id === 'multi') {
      return;
    } else {
      SetBreadcrumbsCorrectPath(item, breadCrumbs, compId);
    }
    setIsLoading(true, compId);
  };

  useEffect(() => {
    if (breadCrumbs) {
      const tempBreadCrumb = {
        id: breadCrumbs[0]?.id,
        value: t('app.widget.header.folderName', { 0: breadCrumbs[0]?.value }),
      };
      if (breadCrumbs.length <= 3 || isMobile) {
        isMobile
          ? setItems(breadCrumbs)
          : setItems([tempBreadCrumb, ...breadCrumbs.slice(1)]);
      } else {
        const tempObj = {
          id: 'multi',
          value: <BreadcumbsMulti />,
        };

        const tempBreads = [
          tempBreadCrumb,
          tempObj,
          breadCrumbs[breadCrumbs.length - 1],
        ];
        setItems(tempBreads);
      }
    } else {
      setItems([]);
    }
  }, [breadCrumbs]);
  const viewPermissions =
    rootFolder &&
    rootFolder.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );
  return (
    <div>
      {rootFolder !== undefined &&
      searchStatus !== undefined &&
      searchStatus === true ? (
        <SearchResultsText />
      ) : searchStatus !== undefined &&
        searchStatus === false &&
        items &&
        items.length > 1 ? (
        <Breadcrumbs
          className={st(classes.folderName, { isMobile })}
          items={items}
          onClick={(item: any) => handleOnClick(item)}
        />
      ) : rootFolder !== undefined && items.length === 1 ? (
        <Text className={st(classes.folderNameText, { isMobile })}>
          {t('app.widget.header.folderName', { 0: rootFolder.value })}
        </Text>
      ) : (selectedFolder === undefined &&
          items &&
          items.length === 0 &&
          rootFolder === undefined &&
          isLoading !== undefined &&
          isLoading === false) ||
        viewPermissions?.reason === Reason.NOT_FOUND ? (
        <Text className={st(classes.folderNameText, { isMobile })}>
          {t('app.widget.shared.folder')}
        </Text>
      ) : null}
    </div>
  );
};

export default BreadcrumbsWrapper;
