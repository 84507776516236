import { take, lastValueFrom } from 'rxjs';
import { demoFolder } from '../../Components/FolderTable/TableWrapper/ItemsWrapper/SkeletonItemWrapper/SkeletonItem/SkeletonItemDemo';
import { authorizeAddedFolder } from '../AddNewFolder.service';
import { getSelectedFolder } from '../Search.service';
import { setSelectedFolder } from '../SelectedFolder.service';
import { moveObservableHelper } from './MovingProccess';
import { getBreadCrumbs } from '../BreadCrumbs.service';
import {setSelectedFolder$} from "../../Observables/SelectedFolder.observable";

export const moveUpdateTable = async (newFolder: any, compId: string) => {
  const breadCrumbs = await breadCrumbObservableHelper(compId);
  const moveObservable = await moveObservableHelper();

  const tempObj: any = {
    ...demoFolder,
    id: newFolder.folderId,
    name: newFolder.folderName,
  };
  const selectedFolder: any = await getSelectedFolder(compId);
  if (
    moveObservable.currentFolderId === breadCrumbs[breadCrumbs.length - 1].id
  ) {
    selectedFolder?.libraryItems.unshift(tempObj);
    const authActions = await authorizeAddedFolder(
      newFolder.folderId,
      moveObservable.currentFolderId,
    );
    tempObj.authorizeActions = authActions.authorizedActions.filter(
      (data: any) => {
        return data.itemId === newFolder.folderId;
      },
    );
    setSelectedFolder$(JSON.parse(JSON.stringify(selectedFolder)), compId);
  } else if (
    moveObservable.parentFolderId === breadCrumbs[breadCrumbs.length - 1].id
  ) {
    selectedFolder.libraryItems = selectedFolder?.libraryItems.map(
      (item: any) => {
        if (item.id === moveObservable.currentFolderId) {
          if (item?.folderFields?.childrenCount !== undefined) {
            item.folderFields.childrenCount++;
          }
        }
        return item;
      },
    );
    setSelectedFolder$(JSON.parse(JSON.stringify(selectedFolder)), compId);
  }
};

const breadCrumbObservableHelper = async (compId: string) => {
  const tempResult = getBreadCrumbs(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
