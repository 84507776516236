import { HttpClient } from '@wix/http-client';
import {
  queryLibraryItems,
  createFolder,
  getLibraryItemsById,
  authorizeActions,
  _delete,
  reportLibraryItem,
  share,
  download,
  rename,
  favorite,
  viewFolder,
  getFolderTree,
  move,
  viewFile,
  startFileUpload,
  completeFileUpload,
  queryLibraryFolderItems,
} from '@wix/ambassador-file-sharing-v1-library-item/http';
import {
  requestAccess,
  listRoles,
} from '@wix/ambassador-file-sharing-v1-role/http';
import { getAppSettings } from '../Services/AppSettings.service';
import { setMoveLoader } from '../Services/ItemsMove.service';

export class FileSharingService {
  fileSharingService: any;
  instance: string = '';
  baseUrl: string = '';
  constructor() {
    getAppSettings().subscribe((data: any) => {
      const tempData: any = data;
      let isEditor_X = false;
      if (tempData && 'isEditor_X' in tempData) {
        isEditor_X = data.isEditor_X;
      }
      this.instance = data?.instance;
      if (data?.viewMode === 'Editor') {
        this.baseUrl = 'https://editor.wixapps.net';
      } else if (data?.viewMode === 'Preview') {
        this.baseUrl = isEditor_X
          ? 'https://create.editorx.com/'
          : 'https://editor.wix.com';
      } else {
        this.baseUrl = data?.baseUrl;
      }
      if (this.instance) {
        this.fileSharingService = new HttpClient({
          baseURL: this.baseUrl,
          headers: { Authorization: this.instance },
        });
      }
    });
  }

  getListRoles = async (query: any) => {
    try {
      const result = await this.fileSharingService.request(
        listRoles(query),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };
  getFolderById = async (folderId: string, sortBy: any) => {
    try {
      const result = await this.fileSharingService.request(
        queryLibraryItems({
          filter: {
            parentLibraryItemIds: [folderId],
          },
          sort: {
            orientation: sortBy.orientation,
            sortBy: sortBy.sortBy,
          },
        }),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };

  loadMoreItemsApi = async (folderId: string, cursor: string) => {
    try {
      const result = await this.fileSharingService.request(
        queryLibraryItems({
          filter: {
            parentLibraryItemIds: [folderId],
          },
          paging: {
            cursor,
          },
        }),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };

  getFolderInfoById = async (folderId: string) => {
    try {
      const result = await this.fileSharingService.request(
        queryLibraryItems({
          filter: {
            itemIds: [folderId],
          },
        }),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };
  searchByValue = async (query: any) => {
    try {
      return await this.fileSharingService.request(queryLibraryItems(query));
    } catch (e) {
      return e;
    }
  };
  createFolder = async (query: any) => {
    try {
      return await this.fileSharingService.request(createFolder(query));
    } catch (e) {
      return e;
    }
  };
  authorizeActions = async (query: any) => {
    try {
      return await this.fileSharingService.request(authorizeActions(query));
    } catch (e) {
      return e;
    }
  };
  delete = async (query: any) => {
    try {
      return await this.fileSharingService.request(_delete(query));
    } catch (e) {
      return e;
    }
  };
  report = async (query: any) => {
    try {
      return await this.fileSharingService.request(
        reportLibraryItem({ action: query }),
      );
    } catch (e) {
      return e;
    }
  };
  shareItem = async (query: any) => {
    try {
      return await this.fileSharingService.request(share(query));
    } catch (e) {
      return e;
    }
  };
  downloadItems = async (query: any) => {
    try {
      return await this.fileSharingService.request(download(query));
    } catch (e) {
      return e;
    }
  };

  renameItem = async (query: any) => {
    try {
      return await this.fileSharingService.request(rename(query));
    } catch (e) {
      return e;
    }
  };

  favoriteChangeAPI = async (query: any) => {
    try {
      return await this.fileSharingService.request(favorite(query));
    } catch (e) {
      return e;
    }
  };

  addViewFolderAPI = async (query: any) => {
    try {
      return await this.fileSharingService.request(viewFolder(query));
    } catch (e) {
      return e;
    }
  };
  getFolderTree = async (query: any) => {
    try {
      return await this.fileSharingService.request(getFolderTree(query));
    } catch (e) {
      // setMoveLoader(false);
      return e;
    }
  };
  moveItems = async (query: any) => {
    try {
      return await this.fileSharingService.request(move(query));
    } catch (e) {
      return e;
    }
  };
  viewFileApi = async (query: any) => {
    try {
      return await this.fileSharingService.request(viewFile(query));
    } catch (e) {
      return e;
    }
  };

  startFileUpload = async (query: any) => {
    try {
      return await this.fileSharingService.request(startFileUpload(query));
    } catch (e) {
      return e;
    }
  };

  completeFileUpload = async (query: any) => {
    try {
      return await this.fileSharingService.request(completeFileUpload(query));
    } catch (e) {
      return e;
    }
  };

  requestAccess = async (query: any) => {
    try {
      return await this.fileSharingService.request(requestAccess(query));
    } catch (e) {
      return e;
    }
  };
  getAllFolders = async () => {
    try {
      const result = await this.fileSharingService.request(
        queryLibraryFolderItems({}),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };
}
