import { SORT } from '../../../../../../../Constants/TableHeader.Constants';

const getSortIds = () => [
  SORT.SORT_BY.NAME,
  SORT.SORT_BY.LAST_MODIFIED,
  SORT.SORT_BY.UNIQUE_VIEWS,
  SORT.SORT_BY.TIMES_FAVORITED,
  SORT.SORT_BY.TYPE,
  SORT.SORT_BY.SIZE,
];
const getSortLabels = (t: any) => [
  t('app.widget.sort.name'),
  t('app.widget.sort.last_modified'),
  t('app.widget.sort.views_count'),
  t('app.widget.sort.favorites'),
  t('app.widget.sort.type'),
  t('app.widget.sort.size'),
];
const changeSortDirection = ({ orientation }: any) => {
  if (orientation === SORT.ORIENTATION.DESC) {
    return SORT.ORIENTATION.ASC;
  } else if (orientation === SORT.ORIENTATION.ASC) {
    return SORT.ORIENTATION.DESC;
  }
};
const getButtonDescriptionBi = (selectedId: string) => {
  switch (selectedId) {
    case SORT.SORT_BY.NAME:
      return 'name';
    case SORT.SORT_BY.TYPE:
      return 'type';
    case SORT.SORT_BY.TIMES_FAVORITED:
      return 'stars';
    case SORT.SORT_BY.UNIQUE_VIEWS:
      return 'views';
    case SORT.SORT_BY.CREATED_AT:
      return 'date';
    default:
  }
};
export {
  getSortIds,
  getSortLabels,
  changeSortDirection,
  getButtonDescriptionBi,
};
