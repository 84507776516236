import { TOAST } from '../../Constants/Toast.Constants';
import { FileSharingService } from '../Api/Api';
import {
  addMoreFolderItems$,
  getSelectedFolder$,
  getSelectedFolderId$,
  setSelectedFolder$,
  setSelectedFolderId$,
} from '../Observables/SelectedFolder.observable';
import { setIsLoading } from './Loading.service';
import { setIsServerError } from './ServerError.service';
import { getSortLastValue } from './Sort.service';
import { setSelectedItems } from './SelectedItems.service';
import { lastValueFrom, take } from 'rxjs';

let API: any = null;
API = new FileSharingService();

export const getSelectedFolderId = (compId: string) => {
  return getSelectedFolderId$(compId);
};
export const setSelectedFolderId = (
  selectedFolderId: string,
  compId: string,
) => {
  if (selectedFolderId && selectedFolderId !== '') {
    setSelectedFolderId$(selectedFolderId, compId);
    addFolderView(selectedFolderId);
    setSelectedItems([], compId);
  } else {
    setSelectedFolderId$(undefined, compId);
    setIsLoading(false, compId);
  }
};

export const getSelectedFolder = (compId: string) => {
  return getSelectedFolder$(compId);
};

export const setSelectedFolder = async (
  selectedFolder: any,
  compId: string,
  idsToRemove?: any,
) => {
  if (selectedFolder !== '' && selectedFolder !== undefined) {
    const sortBy = await getSortLastValue(compId);
    try {
      setIsLoading(true, compId);
      const folder = await API.getFolderById(selectedFolder, sortBy);
      if (!folder.data) {
        const tempError: any = folder;
        setIsLoading(false, compId);
        setIsServerError(
          {
            isError: true,
            message: tempError.message,
          },
          compId,
        );
      } else {
        if (folder?.data?.libraryItems) {
          const authorizedFolders = await authorizeActions(
            folder.data,
            selectedFolder,
            compId,
          );
          if (
            authorizedFolders !== undefined &&
            authorizedFolders?.libraryItems &&
            authorizedFolders?.folderAuthorizedActions
          ) {
            const data = {
              libraryItems: authorizedFolders.libraryItems,
              folderAuthorizedActions:
                authorizedFolders.folderAuthorizedActions,
              metaData: folder.data.metaData,
            };
            if (idsToRemove) {
              setSelectedFolderFiltered(data, idsToRemove, compId);
            } else {
              setSelectedFolder$(data, compId);
            }
          }
          setIsLoading(false, compId);
        } else {
          if (idsToRemove) {
            setSelectedFolderFiltered(folder.data, idsToRemove, compId);
          } else {
            setSelectedFolder$(folder.data, compId);
          }
          setIsLoading(false, compId);
        }
      }
    } catch (e) {
      const error: any = e;
      setIsServerError(
        {
          isError: true,
          message: error.message,
        },
        compId,
      );
      return error.message;
    }
  } else {
    setIsServerError(
      {
        isError: false,
        message: '',
      },
      compId,
    );
    setSelectedFolder$(undefined, compId);
    setIsLoading(false, compId);
  }
};

export const loadMoreFolderItems = async (
  folderId: string,
  nextCursor: string,
  compId: string,
) => {
  try {
    const response = await API.loadMoreItemsApi(folderId, nextCursor);
    if (!response.data) {
      const tempError: any = response;
      setIsServerError(
        {
          isError: true,
          message: tempError.message,
        },
        compId,
      );
    } else {
      if (response.data.libraryItems && response.data.libraryItems.length > 0) {
        const authorizedFolders = await authorizeActions(
          response.data,
          response.data.libraryItems[0].parentFolderId,
          compId,
        );
        if (authorizedFolders) {
          const data = {
            libraryItems: authorizedFolders.libraryItems,
            folderAuthorizedActions: authorizedFolders.folderAuthorizedActions,
            metaData: response.data.metaData,
          };
          addMoreFolderItems$(data, compId);
        }

        setIsLoading(false, compId);
      } else {
        addMoreFolderItems$(response.data, compId);
      }
    }
  } catch (e) {
    setIsLoading(false, compId);
    const error: any = e;
    setIsServerError(
      {
        isError: true,
        message: error.message,
      },
      compId,
    );
    return error.message;
  }
};

export const addFolderView = async (id: string) => {
  try {
    const response = await API.addViewFolderAPI({
      actions: [
        {
          libraryItemId: id,
        },
      ],
    });
  } catch (e) {
    const error: any = e;
    throw error;
  }
};

export const authorizeActions = async (
  folders: any,
  parentFolderId: any,
  compId: string,
) => {
  // if (folders.libraryItems.length > 0) {
  const libraryItemIds = folders.libraryItems.map((item: any) => item.id);
  const query = { libraryItemIds, parentFolderId };
  const res = await API.authorizeActions(query);
  if (!res.data) {
    const tempError: any = res;
    setIsLoading(false, compId);
    setIsServerError(
      {
        isError: true,
        message: tempError.message,
      },
      compId,
    );
    return;
  }
  const authorizedLibraryItems = folders.libraryItems.map((folder: any) => {
    if (res?.data?.authorizedActions) {
      folder.authorizeActions = res.data.authorizedActions.filter(
        (data: any) => {
          return data.itemId === folder.id;
        },
      );
    }
    // folder.folderAuthorizedActions = { ...res.data.folderAuthorizedActions };
    return folder;
  });

  return {
    libraryItems: authorizedLibraryItems,
    folderAuthorizedActions: res?.data?.folderAuthorizedActions || {},
  };
  // } else {
  //   return { libraryItems: [] };
  // }
};

export const viewFileService = async (id: string, compId: string) => {
  try {
    const response = await API.viewFileApi({
      actions: [
        {
          libraryItemId: id,
        },
      ],
    });
    if (response && response?.data && response.data?.urls) {
      const urls = response.data.urls;
      if (urls.length > 0) {
        const url = urls[0]?.url;
        const libraryItemId = urls[0]?.libraryItemId;
        if (url) {
          setTimeout(() => window.open(urls[0].url));
        }
        if (libraryItemId) {
          const tempResult = getSelectedFolder$(compId).asObservable();
          const lastTempResult = tempResult.pipe(take(1));
          const previousResult = await lastValueFrom(lastTempResult);
          const newResult = JSON.parse(JSON.stringify(previousResult));
          newResult.libraryItems.forEach((item: any) => {
            if (item.id === libraryItemId && !item.isViewed) {
              item.isViewed = true;
              item.uniqueViews += 1;
            }
          });
          setSelectedFolder$(newResult, compId);
        }
      }
      setIsLoading(false, compId);
    }
  } catch (e) {
    setIsLoading(false, compId);
    const error: any = e;
    throw error;
  }
};
const setSelectedFolderFiltered = async (
  data: any,
  idsToRemove: any,
  compId: string,
) => {
  const newData: any = { ...data };
  const filteredItems = data?.libraryItems?.filter(
    (item: any) => !idsToRemove?.includes(item?.id),
  );
  newData.libraryItems = filteredItems;
  setSelectedItems([], compId);
  setSelectedFolder$(newData, compId);
  setIsLoading(false, compId);
};
