import { BehaviorSubject, map, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

export const allFoldersSubject$ = new BehaviorSubject(
  (undefined as unknown) as any,
);

// export const deleteFolder = async (item: string) => {
//   const tempResult = AppSubject$.asObservable();
//   const lastTempResult = tempResult.pipe(take(1));
//   const previousResult = await lastValueFrom(lastTempResult);
//   const newResult = { ...previousResult };
//   newResult.testarr = [...newResult.testarr, item];
//   AppSubject$.next(newResult);
// };

export const getAllFolders$ = () => allFoldersSubject$;

export const setAllFolders$ = (folders: []) => allFoldersSubject$.next(folders);

export const getFolderById$ = (id: string) =>
  allFoldersSubject$.pipe(map((item: any) => item.id === id));
