import React from 'react';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import {
  getSearchStatus,
  getSearchSubject,
} from '../../../../../Services/Search.service';
import BreadcrumbsWrapper from '../../../HeaderWrapper/BreadcrumbsWrapper/BreadcrumbsWrapper';
import SearchResultsText from '../../../HeaderWrapper/SearchResultsText/SearchResultsText';
import MobileActionsMenuTop from './ActionsMenu/MobileActionsMenuTop';
import SortWrapper from '../../../TableWrapper/TableHeaderWrapper/TableHeader/Sort/SortWrapper';
import { classes } from './MobileHeaderTop.st.css';
import { useAppContext } from '../../../../../Shared/AppContext';

const MobileHeaderTop = () => {
  const { compId } = useAppContext();

  const [error1, searchStatus]: any = useObservable(getSearchStatus(compId));
  const [error2, searchSubject]: any = useObservable(getSearchSubject(compId));

  return (
    <div className={classes.mobileHeaderContainer}>
      {searchStatus === undefined || searchStatus === false ? (
        <div className={classes.BreadcrumbsContainer}>
          <BreadcrumbsWrapper />
        </div>
      ) : (
        <div className={classes.searchResultsContainer}>
          <SearchResultsText />
        </div>
      )}
      <div className={classes.iconsContainer}>
        {searchSubject !== undefined &&
        (searchSubject.value === '' || searchSubject.value === undefined) ? (
          <SortWrapper />
        ) : null}
        <div>
          <MobileActionsMenuTop />
        </div>
      </div>
    </div>
  );
};

export default MobileHeaderTop;
