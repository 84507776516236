import React, { useEffect, useRef } from 'react';
import { Checkbox } from 'wix-ui-tpa';
import { SELECTED_ITEMS_BEHAVIOR } from '../../../../../../../Constants/SelectedItems.Constants';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import {
  getSelectedItems,
  handleSelectedItemsChange,
} from '../../../../../../Services/SelectedItems.service';
import { useAppContext } from '../../../../../../Shared/AppContext';

const ItemCheckBox = (props: any) => {
  const { compId } = useAppContext();
  const selectedCheckboxRef = useRef<HTMLDivElement>(null);
  const [error1, selectedItems]: any = useObservable(getSelectedItems(compId));
  const { item } = props;
  useEffect(() => {
    const checkBoxInput = selectedCheckboxRef?.current?.querySelector(
      ':nth-child(1) > input',
    );
    if (checkBoxInput) {
      checkBoxInput.setAttribute('tabIndex', '-1');
    }
  }, [selectedItems]);
  const handleOnChange = () => {
    if (!selectedItems.includes(item.id)) {
      handleSelectedItemsChange(
        compId,
        SELECTED_ITEMS_BEHAVIOR.ADD_ITEM,
        item.id,
      );
    } else {
      handleSelectedItemsChange(
        compId,
        SELECTED_ITEMS_BEHAVIOR.REMOVE_ITEM,
        item.id,
      );
    }
  };
  const isSelected = selectedItems?.includes(item.id);
  const handleOnKeyDown = (event: any) => {
    const enterBarKey = 'Enter';
    if (event.key && event.key !== enterBarKey) {
      return;
    } else {
      handleOnChange();
    }
  };
  return (
    <div
      role="checkbox"
      id="selectCheckbox"
      ref={selectedCheckboxRef}
      aria-checked={isSelected || 'false'}
      tabIndex={0}
      aria-label={isSelected ? 'checkbox selected' : 'checkbox'}
      style={{ display: 'flex' }}
      onKeyDown={handleOnKeyDown}
    >
      {selectedItems ? (
        <Checkbox checked={isSelected} label="" onChange={handleOnChange} />
      ) : null}
    </div>
  );
};

export default ItemCheckBox;
