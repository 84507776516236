const SORT_BY = {
  CREATED_AT: 'CREATED_AT',
  NAME: 'NAME',
  TYPE: 'TYPE',
  TIMES_FAVORITED: 'TIMES_FAVORITED',
  UNIQUE_VIEWS: 'UNIQUE_VIEWS',
  LAST_MODIFIED: 'LAST_MODIFIED',
  SIZE: 'SIZE',
};
const ORIENTATION = {
  DESC: 'DESC',
  ASC: 'ASC',
};
export const SORT = {
  ORIENTATION,
  SORT_BY,
};
export const TableColumns = {
  LAST_UPDATED: 'LAST_UPDATED',
  VIEWS_COUNTER: 'VIEWS_COUNTER',
  FAVORITES: 'FAVORITES',
  FAVORITES_COUNTER: 'FAVORITES_COUNTER',
  CONTRIBUTORS: 'CONTRIBUTORS',
  SEARCH_BAR: 'SEARCH_BAR',
};
