import React from 'react';
import SortDown from 'wix-ui-icons-common/on-stage/SortDown';
import SortUp from 'wix-ui-icons-common/on-stage/SortUp';
import { SORT } from '../../../../../../../Constants/TableHeader.Constants';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { classes } from '../TableHeader.st.css';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_SORT_BY } from '../../../../../../BiEvents/Constants/BiConstants';
import { useAppContext } from '../../../../../../Shared/AppContext';

const ViewsComponent = ({
  name,
  sortBy,
  orientation,
  handleTitleColumnOnClick,
  disabledTableHeader,
}: any) => {
  const { compId } = useAppContext();
  const className = disabledTableHeader ? classes.disabledHeader : '';
  const suffixIcon: any =
    sortBy === SORT.SORT_BY.UNIQUE_VIEWS &&
    (orientation === SORT.ORIENTATION.DESC ? (
      <SortDown className={className} />
    ) : (
      <SortUp className={className} />
    ));
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
      }}
    >
      <TextButton
        className={classes.tableHeaderColumn}
        contentClassName={classes.contentEllipsis}
        suffixIcon={suffixIcon}
        priority={TextButtonPriority.secondary}
        onClick={() => {
          if (!disabledTableHeader) {
            handleTitleColumnOnClick({
              sortBy,
              selectedId: SORT.SORT_BY.UNIQUE_VIEWS,
            });
            biService(
              { button_description: 'views', is_first: false },
              BI_FILE_SHARE_WIDGET_SORT_BY,
              compId,
            );
          }
        }}
        title={name}
      >
        {name}
      </TextButton>
    </div>
  );
};
export default ViewsComponent;
