export const FolderPermissionType = {
  PUBLIC: 'PUBLIC',
  MEMBER: 'MEMBER',
  PAID: 'PAID',
  HIDDEN: 'HIDDEN',
};

export enum Availability {
  UNKNOWN = 'UNKNOWN',
  PUBLIC = 'PUBLIC',
  MEMBER = 'MEMBER',
  PAID = 'PAID',
}

export enum Reason {
  /** No exact reason. Depends on status if action is ALLOWED or FORBIDDEN */
  DEFAULT_REASON = 'DEFAULT_REASON',
  /** This action is not allowed because it tried on root folder */
  NOT_ALLOWED_ON_ROOT = 'NOT_ALLOWED_ON_ROOT',
  /** Site member is missing permission to perform action */
  MISSING_PERMISSION = 'MISSING_PERMISSION',
  /** Site member who is performing this action is private so it is not allowed because it will expose site member */
  MUST_BE_PUBLIC = 'MUST_BE_PUBLIC',
  /** Only available for item owners */
  OWNED_BY_ANOTHER_USER = 'OWNED_BY_ANOTHER_USER',
  /** Can't delete non empty folder */
  FOLDER_NOT_EMPTY = 'FOLDER_NOT_EMPTY',
  /**
   * Additionally is_allowed_for_all_members will be set if all members are granted this action
   * Usually this means user must log in in order to perform this action
   */
  MUST_BE_A_MEMBER = 'MUST_BE_A_MEMBER',
  /** Action only available on root or first level subfolders */
  NOT_ALLOWED_ON_SUBFOLDERS_THIS_DEEP = 'NOT_ALLOWED_ON_SUBFOLDERS_THIS_DEEP',
  /** Action only available on subfolders */
  NOT_ALLOWED_ON_FILES = 'NOT_ALLOWED_ON_FILES',
  /** Library item is not found */
  NOT_FOUND = 'NOT_FOUND',
}
export enum Status {
  /** Action is allowed */
  ALLOWED = 'ALLOWED',
  /** Action is forbidden without any specific reason */
  FORBIDDEN = 'FORBIDDEN',
}

export enum Role {
  ADMIN = 'Admin',
  MEMBER = 'Member',
}
