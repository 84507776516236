import { from } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { searchService } from '../Services/Search.service';
import { getObservableById, setObservableById } from './helper';

const searchSubject$ = new Map();
const searchStatus$ = new Map();
const disableButtonsStatus$ = new Map();

export const getSearchSubject$ = (compId: string) =>
  getObservableById(searchSubject$, compId, { callApi: false, value: '' });

export const setSearchSubject$ = (searchSubject: any, compId: string) =>
  setObservableById(searchSubject$, searchSubject, compId);

export const getSearchStatus$ = (compId: string) =>
  getObservableById(searchStatus$, compId, false);

export const setSearchStatus$ = (searchStatus: any, compId: string) =>
  setObservableById(searchStatus$, searchStatus, compId);

export const getDisableButtonsStatus$ = (compId: string) =>
  getObservableById(disableButtonsStatus$, compId, false);

export const setDisableButtonsStatus$ = (
  disableButtonsStatus: any,
  compId: string,
) => setObservableById(disableButtonsStatus$, disableButtonsStatus, compId);

export const searchObservable$ = (compId: string) =>
  getSearchSubject$(compId).pipe(
    debounceTime(1750),
    distinctUntilChanged(
      (oldObj, newObj) => JSON.stringify(oldObj) === JSON.stringify(newObj),
    ),
    mergeMap(() => from(searchService(compId))),
  );
