import React, { forwardRef } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TextButtonAction } from './TextButtonAction/TextButtonAction';
import { classes } from './ActionsToolBar.st.css';
import Delete from 'wix-ui-icons-common/on-stage/Delete';
import MoveTo from 'wix-ui-icons-common/on-stage/MoveTo';
import Download from 'wix-ui-icons-common/on-stage/Download';
import { MODAL_TYPES } from '../../../../../../Constants/Modals.Constants';
import { setOpenedModal } from '../../../../../Services/Modals.service';
import { ACTIONS_TOOLBAR } from '../../../../../../Constants/ActionsToolBar.Constant';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../../../Services/SelectedItems.service';
import {
  downloadItems,
  getIsDownloadEnabled,
} from '../../../../../Services/ItemsDownload.service';
import MoveWrapper from '../../../../../Shared/MoveWrapper/MoveWrapper';
import withClickOutsideListener from '../../../../../../Shared/HOC/withClickOutsideListener';
import withMemberAuthorization from '../../../../../../Shared/HOC/withMemberAuthorization';
import { getAppSettings } from '../../../../../Services/AppSettings.service';
import { getSelectedFolder } from '../../../../../Services/SelectedFolder.service';
import { getActionAuthStatus } from '../../../TableWrapper/ItemsWrapper/Item/ItemActions/ItemActionsMenu/MenuOptions/helper';
import { ITEM_MENU_OPTIONS } from '../../../../../../Constants/ItemMenu.Constant';
import { useAppContext } from '../../../../../Shared/AppContext';

const ActionsToolBar = forwardRef((props: any, ref: any) => {
  const { t } = useTranslation();
  const { compId } = useAppContext();
  const [, selectedItems]: any = useObservable(getSelectedItems(compId));
  const [, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [, isDownloadEnabled]: any = useObservable(
    getIsDownloadEnabled(compId),
  );
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const { open, setOpen, memberAuthorization } = props;
  const deleteTitle = t('app.widget.actions.toolBar.delete');
  const moveTitle = t('app.widget.actions.toolBar.move');
  const downloadTitle = t('app.widget.actions.toolBar.download');
  const getIsDisplayed = (action: string) => {
    return selectedFolder !== undefined &&
      selectedFolder.libraryItems &&
      selectedFolder.libraryItems.length > 0
      ? getActionAuthStatus(selectedFolder.libraryItems[0], action)
      : false;
  };

  const actionsToolBarItems = [
    {
      prefixIcon: <Delete />,
      text: deleteTitle,
      type: ACTIONS_TOOLBAR.DELETE,
      isDisplayed: getIsDisplayed(ITEM_MENU_OPTIONS.DELETE),
    },
    {
      prefixIcon: <MoveTo />,
      text: moveTitle,
      type: ACTIONS_TOOLBAR.MOVE,
      isDisplayed: getIsDisplayed(ITEM_MENU_OPTIONS.MOVE),
    },
    {
      prefixIcon: <Download />,
      text: downloadTitle,
      type: ACTIONS_TOOLBAR.DOWNLOAD,
      isDisplayed: true,
    },
  ];
  const handleMove = () => {
    setOpen(true);
  };
  const handleOnClick = (type: string) => {
    switch (type) {
      case ACTIONS_TOOLBAR.DELETE:
        setOpenedModal(MODAL_TYPES.DELETE,compId);
        break;
      case ACTIONS_TOOLBAR.DOWNLOAD:
        selectedItems && downloadItems(t, compId);
        break;
      case ACTIONS_TOOLBAR.MOVE:
        handleMove();
        break;
      default:
        break;
    }
  };

  const generateActionsToolBarItems = () => {
    let isEnabled: boolean = true;
    return actionsToolBarItems.map((actionItem: any) => {
      isEnabled =
        actionItem.type === ACTIONS_TOOLBAR.DOWNLOAD
          ? isDownloadEnabled !== undefined && isDownloadEnabled === true
          : true;
      const isDisplayed = actionItem.isDisplayed;
      const isMoveAction = actionItem.type === ACTIONS_TOOLBAR.MOVE;
      return (
        <div
          className={isMobile ? classes.mobileIconContainer : ''}
          ref={isMoveAction ? ref : null}
        >
          {isDisplayed ? (
            <TextButtonAction
              isEnabled={isEnabled}
              prefixIcon={actionItem.prefixIcon}
              text={isMobile === false ? actionItem.text : ''}
              onClick={() =>
                memberAuthorization({
                  args: [actionItem.type],
                  callBack: handleOnClick,
                })
              }
              className={classes.TextButtonContainer}
            />
          ) : null}
          {isMoveAction && open === true ? (
            <MoveWrapper handleOnCloseMove={() => setOpen && setOpen(false)} />
          ) : null}
        </div>
      );
    });
  };
  return (
    <div
      className={
        isMobile
          ? classes.mobileActionsToolBarContainer
          : classes.actionsToolBarContainer
      }
    >
      {generateActionsToolBarItems()}
    </div>
  );
});

export default withMemberAuthorization(
  withClickOutsideListener(ActionsToolBar),
);
