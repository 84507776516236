import React, { useEffect, useState } from 'react';
import { Favorite } from '../../../../../../icons/Favorite';
import { FavoriteFill } from '../../../../../../icons/FavoriteFill';
import { st, classes } from './ItemFavorites.st.css';
import { IconToggle, IconToggleLabelPlacement } from 'wix-ui-tpa';
import { favoriteChange } from '../../../../../../Services/ItemFavorite.service';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import withMemberAuthorization from '../../../../../../../Shared/HOC/withMemberAuthorization';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';
import { handleMemberAuth } from '../../../../../../Shared/memberAuthorizationHelper';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useAppContext } from '../../../../../../Shared/AppContext';

const ItemFavorites = (props: any) => {
  const { compId } = useAppContext();
  const { item, memberAuthorization, openPrivacyModal } = props;
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const [, tableSettings]: any = useObservable(getTableSettings(compId));
  const { t } = useTranslation();
  const [state, setState] = useState({
    checked: item?.isFavorited > 0,
    counter: item?.timesFavorited || '0',
    isShow: false,
  });

  const favoritePermission = item?.authorizeActions?.find(
    (itemData: any) => itemData?.action === 'FAVORITE',
  );

  const handleOnChange = () => {
    handleMemberAuth({
      permissions: favoritePermission,
      callBack: handleSetChecked,
      openPrivacyModal,
      memberAuthorization,
    });
  };

  const handleSetChecked = () => {
    favoriteChange(item, !state.checked, t, compId);
    const tempCounter = parseInt(state.counter, 10);
    let tempResult: any;
    setState((prev) => ({ ...prev, checked: !state.checked }));
    if (state.checked === true) {
      tempResult = tempCounter - 1;
      if (tempResult === 0) {
        tempResult = '0';
      }
      setState((prev) => ({ ...prev, counter: tempResult }));
    } else {
      tempResult = tempCounter + 1;
      setState((prev) => ({ ...prev, counter: tempResult }));
    }
  };

  const _setIsShown = () => {
    const tempIsShowItem = tableSettings.filter((item: any) => {
      if (item.id === 'FAVORITES_COUNTER') {
        return item;
      }
    });
    if (tempIsShowItem && tempIsShowItem.length > 0) {
      setState((prev) => ({ ...prev, isShow: tempIsShowItem[0].value }));
    }
  };

  useEffect(() => {
    if (tableSettings && tableSettings.length > 0) {
      _setIsShown();
    }
  }, [tableSettings]);

  return (
    <>
      {tableSettings && tableSettings.length > 0 ? (
        <IconToggle
          className={st(classes.IconToggleStyles, { isMobile })}
          checkedIcon={<FavoriteFill handleOnChange={handleOnChange} />}
          icon={<Favorite handleOnChange={handleOnChange} />}
          label={state.isShow ? state.counter : null}
          onChange={() => {
            handleOnChange();
            // memberAuthorization({ args: [], callBack: handleSetChecked() });
          }}
          checked={state.checked}
          labelPlacement={
            isMobile
              ? IconToggleLabelPlacement.START
              : IconToggleLabelPlacement.END
          }
        />
      ) : null}
    </>
  );
};

export default withMemberAuthorization(ItemFavorites);
