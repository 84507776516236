import React from 'react';
import { classes } from './SkeletonItemWrapper/SkeletonItem/skeletonItem.st.css';
import {
  IconToggle,
  IconToggleLabelPlacement,
  Text,
  TextPriority,
} from 'wix-ui-tpa';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import { getTableSettings } from '../../../../Services/TableSettings.service';
import { TableColumns } from '../../../../../Constants/TableHeader.Constants';
import {
  st,
  classes as favoriteIconStyle,
} from './Item/ItemFavorites/ItemFavorites.st.css';
import {
  st as st_SharedText,
  classes as SharedTextStyle,
} from '../../../../Shared/SharedText.st.css';
import Favorite from 'wix-ui-icons-common/on-stage/Favorite';
import { useAppContext } from '../../../../Shared/AppContext';

export const MobileSkeleton = ({ t, isMobile }: any) => {
  const { compId } = useAppContext();
  const [, tableDisplaySettings]: any = useObservable(getTableSettings(compId));

  const getDisplaySettings = (tableColumn: string) => {
    return (
      tableDisplaySettings &&
      tableDisplaySettings.find((data: any) => data.id === tableColumn)
    );
  };

  const displaySettings = {
    displayLastUpdated:
      getDisplaySettings(TableColumns.LAST_UPDATED)?.value || false,
    displayViews:
      getDisplaySettings(TableColumns.VIEWS_COUNTER)?.value || false,
    displayFavorites:
      getDisplaySettings(TableColumns.FAVORITES)?.value || false,
    favoritesCounter:
      getDisplaySettings(TableColumns.FAVORITES_COUNTER)?.value || false,
  };
  const className: string = `${st_SharedText(
    SharedTextStyle.textAndDividersColor,
    {
      isMobile,
    },
  )} ${SharedTextStyle.contentEllipsis}`;
  return (
    <div className={classes.itemInfoMobileViewContainer}>
      <div className={classes.itemInfoMobileViewSubContainer}>
        {displaySettings.displayLastUpdated && (
          <Text className={className}>
            {t('app.widget.table.content.LastUpdated', {
              view: new Date(),
            })}
          </Text>
        )}
        {displaySettings.displayLastUpdated && displaySettings.displayViews && (
          <div className={classes.dotContainer}>
            <Text className={className} priority={TextPriority.secondary}>
              ·
            </Text>
          </div>
        )}
        {displaySettings.displayViews && (
          <div>
            <Text className={className}>
              {t('app.widget.table.items.views_counter', { views: 0 })}
            </Text>
          </div>
        )}
      </div>
      {displaySettings.displayFavorites && (
        <div>
          <IconToggle
            className={st(favoriteIconStyle.IconToggleStyles, { isMobile })}
            icon={<Favorite />}
            label={displaySettings.favoritesCounter ? '0' : ''}
            labelPlacement={IconToggleLabelPlacement.START}
          />
        </div>
      )}
    </div>
  );
};
