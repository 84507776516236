import { BehaviorSubject } from 'rxjs';

export const getObservableById = (
  observableSubjectMap: Map<string, BehaviorSubject<any>>,
  compId: string,
  initParams?: any,
) => {
  let subject = observableSubjectMap.get(compId);
  if (!subject) {
    subject = new BehaviorSubject(initParams as any);
    observableSubjectMap.set(compId, subject);
  }
  return subject;
};

export const setObservableById = (
  observableSubjectMap: Map<string, BehaviorSubject<any>>,
  newData: any,
  compId: string,
) => {
  let subject = observableSubjectMap.get(compId);
  if (!subject) {
    subject = new BehaviorSubject<any>(newData);
    observableSubjectMap.set(compId, subject);
  }
  subject.next(newData);
};
