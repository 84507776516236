import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { getObservableById, setObservableById } from './helper';

const selectedFolderIdSubject$ = new Map();
const selectedFolderSubject$ = new Map();

export const getSelectedFolderId$ = (compId: string) =>
  getObservableById(selectedFolderIdSubject$, compId);
export const setSelectedFolderId$ = (FolderId: any, compId: string) =>
  setObservableById(selectedFolderIdSubject$, FolderId, compId);

export const getSelectedFolder$ = (compId: string) =>
  getObservableById(selectedFolderSubject$, compId);
export const setSelectedFolder$ = (Folder: any, compId: string) =>
  setObservableById(selectedFolderSubject$, Folder, compId);

export const addMoreFolderItems$ = async (newItems: any, compId: string) => {
  const tempResult = getSelectedFolder$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.libraryItems = [
    ...newResult.libraryItems,
    ...newItems.libraryItems,
  ];
  if (newItems.metaData) {
    newResult.metaData = newItems.metaData;
  } else {
    newResult.metaData = null;
  }
  setSelectedFolder$(newResult, compId);
};
export const removeItemsFromSelectedFolder$ = async (
  ids: any,
  compId: string,
) => {
  const tempResult = getSelectedFolder$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = previousResult?.libraryItems?.filter(
    (item: any) => !ids.includes(item?.id),
  );
  setSelectedFolder$(
    {
      libraryItems: newResult,
      folderAuthorizedActions: previousResult?.folderAuthorizedActions,
      metaData: previousResult?.metaData,
    },
    compId,
  );
};
