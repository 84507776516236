import React from 'react';
import { classes } from './iconStyle.st.css';

export const PdfFill = (props: any) => {
  const { isMobile } = props;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 23H5.5C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23Z"
        fill="#FA6050"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#FA6050"
        fillOpacity="0.35"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62989 14.6388C8.54189 14.7288 8.42389 14.7728 8.27489 14.7728H7.62489V13.7878H8.27489C8.42389 13.7878 8.54189 13.8318 8.62989 13.9198C8.71789 14.0078 8.76189 14.1278 8.76189 14.2818C8.76189 14.4298 8.71789 14.5488 8.62989 14.6388ZM9.01689 13.1618C8.80189 13.0538 8.55489 12.9998 8.27489 12.9998H6.69489V16.9998H7.62489V15.5628H8.27489C8.55489 15.5628 8.80189 15.5098 9.01689 15.4028C9.23189 15.2968 9.39889 15.1468 9.51789 14.9518C9.63689 14.7578 9.69689 14.5348 9.69689 14.2818C9.69689 14.0278 9.63689 13.8048 9.51789 13.6138C9.39889 13.4198 9.23189 13.2708 9.01689 13.1618ZM12.6357 15.8839C12.4417 16.1019 12.1727 16.2109 11.8287 16.2109H11.3307V13.7879H11.8287C12.1727 13.7879 12.4417 13.8979 12.6357 14.1159C12.8297 14.3339 12.9267 14.6269 12.9267 14.9969C12.9267 15.3689 12.8297 15.6649 12.6357 15.8839ZM12.9237 13.2549C12.6127 13.0849 12.2467 12.9999 11.8287 12.9999H10.4017V16.9999H11.8287C12.2467 16.9999 12.6127 16.9139 12.9237 16.7419C13.2367 16.5709 13.4777 16.3339 13.6497 16.0349C13.8217 15.7349 13.9067 15.3889 13.9067 14.9969C13.9067 14.6059 13.8217 14.2599 13.6497 13.9589C13.4777 13.6579 13.2367 13.4239 12.9237 13.2549ZM17.3047 13.7882V13.0002H14.6567V17.0002H15.5857V15.4162H17.2037V14.6272H15.5857V13.7882H17.3047Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.639 10.2372C27.234 10.0852 26.914 9.76525 26.762 9.36125L24.001 2.00025H6.501C5.672 1.99925 5 2.67125 5 3.50025V40.5002C5 41.3283 5.672 42.0002 6.5 42.0002H33.5C34.329 42.0002 35 41.3282 35 40.4992V12.9982L27.639 10.2372Z"
        fill="#FA6050"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="#FA6050"
        fillOpacity="0.35"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2024 25.8242C13.9534 26.0582 13.6124 26.1762 13.1804 26.1762H11.3754V23.5472H13.1804C13.6124 23.5472 13.9534 23.6622 14.2024 23.8932C14.4514 24.1242 14.5764 24.4452 14.5764 24.8552C14.5764 25.2662 14.4514 25.5892 14.2024 25.8242ZM14.5114 22.8532C14.1294 22.6632 13.6854 22.5672 13.1804 22.5672H10.2424V30.4322H11.3754V27.1552H13.1804C13.6854 27.1552 14.1294 27.0592 14.5114 26.8692C14.8914 26.6782 15.1884 26.4092 15.4014 26.0602C15.6144 25.7122 15.7214 25.3102 15.7214 24.8552C15.7214 24.3932 15.6144 23.9922 15.4014 23.6512C15.1884 23.3102 14.8914 23.0452 14.5114 22.8532ZM22.3431 28.04C22.1231 28.484 21.8101 28.829 21.4031 29.074C20.9951 29.32 20.5101 29.442 19.9451 29.442H18.4821V23.558H19.9451C20.5101 23.558 20.9951 23.681 21.4031 23.927C21.8101 24.172 22.1231 24.515 22.3431 24.955C22.5631 25.394 22.6731 25.908 22.6731 26.495C22.6731 27.082 22.5631 27.597 22.3431 28.04ZM21.9911 23.074C21.4051 22.736 20.7221 22.567 19.9451 22.567H17.3491V30.433H19.9451C20.7221 30.433 21.4051 30.265 21.9911 29.927C22.5771 29.59 23.0361 29.126 23.3661 28.535C23.6961 27.945 23.8611 27.265 23.8611 26.495C23.8611 25.732 23.6961 25.056 23.3661 24.466C23.0361 23.875 22.5771 23.411 21.9911 23.074ZM30.4612 23.5469V22.5679H25.6092V30.4329H26.7422V26.9349H30.2632V25.9559H26.7422V23.5469H30.4612Z"
        fill="white"
      />
    </svg>
  );
};
