import React from 'react';
import { classes } from './iconStyle.st.css';

export const FolderFill = (props: any) => {
  const { isMobile } = props;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 7.00002H13.999L8.5 5.99902L3 7.00002V18.5C3 19.328 3.672 20 4.5 20H19.5C20.328 20 21 19.328 21 18.5V8.50002C21 7.67202 20.328 7.00002 19.5 7.00002Z"
        fill="#5538C8"
        className={classes.fillIconFolderColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.999 7H3.00101V5.5C3.00101 4.672 3.67201 4 4.50101 4L10.364 4.001C10.924 4.001 11.458 4.235 11.837 4.647L13.999 7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.999 7H3.00101V5.5C3.00101 4.672 3.67201 4 4.50101 4L10.364 4.001C10.924 4.001 11.458 4.235 11.837 4.647L13.999 7Z"
        fill="#5538C8"
        fillOpacity="0.35"
        className={classes.fillIconFolderColor}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 13H23L13 11L3 13V35.5C3 36.329 3.672 37 4.5 37H35.5C36.328 37 37 36.329 37 35.5V14.5C37 13.671 36.328 13 35.5 13Z"
        fill="#5538C8"
        className={classes.fillIconFolderColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 13H13H3V9.5C3 8.671 3.672 8 4.5 8H17.271C17.967 8 18.632 8.29 19.104 8.8L23 13Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 13H13H3V9.5C3 8.671 3.672 8 4.5 8H17.271C17.967 8 18.632 8.29 19.104 8.8L23 13Z"
        fill="#5538C8"
        fillOpacity="0.35"
        className={classes.fillIconFolderColor}
      />
    </svg>
  );
};
