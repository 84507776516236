import { FileSharingService } from '../Api/Api';
import { HttpClient } from '@wix/http-client';
import { menuSelectedItem$ } from '../Observables/MenuSelectedItem';
import { lastValueFrom, take } from 'rxjs';
import { ITEMTYPE } from '../../Constants/ItemType.Constants';
import { setToast } from './Toast.service';
import { TOAST } from '../../Constants/Toast.Constants';
import {
  getIsDownloadEnabled$,
  setIsDownloadEnabled$,
} from '../Observables/isDownloadEnabled.observable';
import { downloadFileBi } from '../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';
import { getSelectedFolder } from './SelectedFolder.service';
import { getSelectedItems } from './SelectedItems.service';

let API: any = null;
API = new FileSharingService();

export const getIsDownloadEnabled = (compId: string) =>
  getIsDownloadEnabled$(compId);
export const setIsDownloadEnabled = (
  isDownloadEnabled: boolean,
  compId: string,
) => {
  setIsDownloadEnabled$(isDownloadEnabled, compId);
};
export const downloadItems = async (t: any, compId: string) => {
  const selectedItems: any = await getSelectedItemsHelper(compId);
  const currentItem: any = await getCurrentItemHelper();
  let filteredSelectedItemsIds;
  let filteredSelectedItems: any;
  let libraryItemIds: any = [];

  if (selectedItems.length > 0) {
    filteredSelectedItems = await filterItems(selectedItems, compId);
    filteredSelectedItemsIds = filteredSelectedItems.map(
      (item: any) => item.id,
    );
    libraryItemIds = [...filteredSelectedItemsIds];
  } else {
    libraryItemIds = [currentItem.id];
  }

  const downloadUrl = await getDownloadInfo({ libraryItemIds });
  if (downloadUrl) {
    const name =
      filteredSelectedItems && filteredSelectedItems?.length === 1
        ? filteredSelectedItems[0]?.name
        : currentItem.name;
    libraryItemIds &&
    libraryItemIds.length === 1 &&
    downloadUrl?.isArchive === false
      ? downloadSingleItem(downloadUrl?.url, name, currentItem, compId)
      : downloadMultipleItems(downloadUrl?.url);
  } else {
    setToast({
      skin: TOAST.error,
      content: t('app.widget.notification.download.error'),
    });
  }
};

export const getDownloadInfo = async (libraryItemIds: any) => {
  try {
    const { data } = await API.downloadItems({ action: libraryItemIds });
    return data;
  } catch (e) {
    const error: any = e;
    setToast({
      skin: TOAST.error,
      content: error.message,
    });
  }
};

export const downloadSingleItem = (
  url: string,
  itemName: string,
  currentItem: any,
  compId: string,
) => {
  const httpClient = new HttpClient();
  httpClient.get(url, { responseType: 'blob' }).then((response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', itemName);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 1000);
  });
  downloadFileBi(currentItem, compId);
};

export const downloadMultipleItems = (url: string) => {
  const form = document.createElement('form');
  form.contentType = false;
  form.processData = false;
  form.setAttribute('method', 'GET');
  form.setAttribute('action', `${url}`);
  document.body.appendChild(form);
  form.submit();
  setTimeout(() => {
    document.body.removeChild(form);
  }, 1000);
};

const getSelectedItemsHelper = async (compId: string) => {
  const tempResult = getSelectedItems(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const getCurrentItemHelper = async () => {
  const tempResult = menuSelectedItem$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const filterItems = async (selectedItemsIds: any, compId: string) => {
  const tempResult = getSelectedFolder(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = JSON.parse(JSON.stringify(previousResult));
  newResult.libraryItems = newResult.libraryItems.filter((item: any) =>
    selectedItemsIds.includes(item.id),
  );
  newResult.libraryItems = newResult.libraryItems.filter(
    (item: any) =>
      (item.folderFields && item.folderFields.childrenCount !== 0) ||
      item.fileFields,
  );
  return newResult.libraryItems;
};

export const isDownload = async (compId: string) => {
  const tempResult = getSelectedFolder(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const tempResult2 = getSelectedItems(compId).asObservable();
  const lastTempResult2 = tempResult2.pipe(take(1));
  const previousResult2 = await lastValueFrom(lastTempResult2);
  const selectedItemsIds: any = [...previousResult2];
  if (previousResult) {
    const newResult = JSON.parse(JSON.stringify(previousResult));
    newResult.libraryItems = newResult.libraryItems.filter((item: any) =>
      selectedItemsIds.includes(item?.id),
    );
    newResult.libraryItems = newResult.libraryItems.filter(
      (item: any) =>
        (item.folderFields && item.folderFields.childrenCount !== 0) ||
        item.fileFields,
    );
    if (newResult.libraryItems && newResult.libraryItems.length > 0) {
      setIsDownloadEnabled(true, compId);
    } else {
      setIsDownloadEnabled(false, compId);
    }
  }
};
