import {
  getSort$,
  setSort$,
  getSortLastValue$,
} from '../Observables/Sort.observable';

export const getSort = (compId: string) => {
  return getSort$(compId);
};

export const setSort = (Toast: any, compId: string) => {
  setSort$(Toast, compId);
};

export const getSortLastValue = (compId: string) => {
  return getSortLastValue$(compId);
};
