import { FileSharingService } from '../Api/Api';
import {
  getRootFolder$,
  setRootFolder$,
} from '../Observables/RootFolder.observable';
import { setBreadCrumbs } from './BreadCrumbs.service';
import { authorizeActions } from './SelectedFolder.service';
import { Status } from '../../Constants/Permissions';

let API: any = null;
API = new FileSharingService();

export const getRootFolder = (compId: string) => {
  return getRootFolder$(compId);
};

export const setRootFolder = async (
  selectedFolderId: string,
  compId: string,
) => {
  if (selectedFolderId !== '') {
    try {
      let authorizedActions: any = [];
      const authorizeActionsResponse: any = await authorizeActions(
        { libraryItems: [{ id: selectedFolderId }] },
        undefined,
        compId,
      );
      if (
        authorizeActionsResponse &&
        authorizeActionsResponse.libraryItems &&
        authorizeActionsResponse.libraryItems.length > 0
      ) {
        authorizedActions =
          authorizeActionsResponse.libraryItems[0].authorizeActions;
      }
      let pricingPlansIds: any[] = [];
      let noPlansAvailable: boolean = false;
      const listRoles = await API.getListRoles({
        ofCurrentUser: false,
        libraryItemId: selectedFolderId,
        siteVisitors: false,
      });
      if (listRoles?.data && listRoles.data?.roles) {
        pricingPlansIds = listRoles.data.roles
          .filter(
            (role: any) =>
              role.roleType === 'PLAN' &&
              role?.permissions &&
              role.permissions?.length,
          )
          .map((role: any) => role.id);
        noPlansAvailable = listRoles?.data?.roles
          ?.filter(
            (role: any) => role.roleType === 'PLAN' && role.permissions.length,
          )
          .every((pricingPlan: any) => pricingPlan.visible === false);
      }
      const viewPermissions =
        authorizeActionsResponse?.libraryItems[0]?.authorizeActions?.find(
          (itemData: any) => itemData.action === 'VIEW',
        );
      if (viewPermissions?.status === Status.ALLOWED) {
        const folderInfo = await API.getFolderInfoById(selectedFolderId);
        const tempFolderInfo = {
          id: folderInfo.data.libraryItems[0].id,
          value: folderInfo.data.libraryItems[0].name,
          authorizeActions: authorizedActions,
          pricingPlansIds,
          noPlansAvailable,
        };
        setBreadCrumbs([tempFolderInfo], compId);
        setRootFolder$(tempFolderInfo, compId);
      } else if (viewPermissions?.status !== Status.ALLOWED) {
        const folderTree = await API.getFolderTree({
          rootLibraryItemId: selectedFolderId,
        });
        const { data } = folderTree;
        const selectedFolderInfo = data?.folderTree?.subfolders?.filter(
          (item: any) => selectedFolderId === item?.folderId,
        );
        const tempFolderInfo = {
          id: '',
          value:
            selectedFolderInfo?.length > 0 ? selectedFolderInfo[0].name : '',
          authorizeActions: authorizedActions,
          pricingPlansIds,
          noPlansAvailable,
        };
        setBreadCrumbs(
          selectedFolderInfo?.length > 0 ? [tempFolderInfo] : undefined,
          compId,
        );
        setRootFolder$(tempFolderInfo, compId);
      } else {
        setRootFolder$(undefined, compId);
        setBreadCrumbs(undefined, compId);
      }
    } catch (e) {
      const error: any = e;
      return error.message;
    }
  } else {
    setRootFolder$(undefined, compId);
    setBreadCrumbs(undefined, compId);
  }
};
