import React, { useEffect, useState} from 'react';
import BreadcrumbsWrapper from './BreadcrumbsWrapper/BreadcrumbsWrapper';
import ToolBarWrapper from './ToolBarWrapper/ToolBarWrapper';
import { st, classes } from './HeaderWrapper.st.css';
import SearchTextField from './SearchTextField/SearchTextField';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../Services/SelectedItems.service';
import { getRootFolder } from '../../../Services/RootFolder.service';
import { getTableSettings } from '../../../Services/TableSettings.service';
import { TableColumns } from '../../../../Constants/TableHeader.Constants';
import { Status } from '../../../../Constants/Permissions';
import { getUser } from '../../../Services/User.service';
import { getAppSettings } from '../../../Services/AppSettings.service';
import { useAppContext } from '../../../Shared/AppContext';

const HeaderWrapper = () => {
  const { compId } = useAppContext();
  const [, selectedItems]: any = useObservable(getSelectedItems(compId));
  const [, rootFolder]: any = useObservable(getRootFolder(compId));
  const [, tableDisplaySettings]: any = useObservable(getTableSettings(compId));
  const [, user]: any = useObservable(getUser());
  const [, appSettings]: any = useObservable(getAppSettings());
  const isUserLoggedIn = user && user?.isLoggedIn;
  const viewPermissions =
    rootFolder &&
    rootFolder.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );

  const [displaySettings, setDisplaySettings] = useState({
    search: false,
  });
  const handleDisplaySettings = () => {
    const showSearch = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.SEARCH_BAR,
    );
    setDisplaySettings({
      search: showSearch && showSearch.value,
    });
  };

  useEffect(() => {
    if (tableDisplaySettings && tableDisplaySettings.length > 0) {
      handleDisplaySettings();
    }
  }, [tableDisplaySettings]);
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  return (
    <div tabIndex={0} aria-label="Actions">
      <div className={st(classes.headerWrapperContainer, { isEditorX })}>
        <div className={classes.breadCrumbsContainer}>
          <BreadcrumbsWrapper />
        </div>
        <div>
          <ToolBarWrapper />
        </div>
      </div>
      {displaySettings.search ? (
        rootFolder !== undefined &&
        selectedItems &&
        selectedItems.length <= 0 &&
        !(
          (viewPermissions && viewPermissions.status === Status.FORBIDDEN) ||
          !isUserLoggedIn
        ) ? (
          <div className={st(classes.searchWrapper, { isEditorX })}>
            <SearchTextField />
          </div>
        ) : (
          <div className={st(classes.searchWrapper, { isEditorX })} />
        )
      ) : (
        <div />
      )}
    </div>
  );
};

export default HeaderWrapper;
