import React from 'react';
import {
  ITEM_MENU_OPTIONS,
  STATUS,
} from '../../../../../../../../Constants/ItemMenu.Constant';
import { handleSelectedItemsChange } from '../../../../../../../Services/SelectedItems.service';
import { SELECTED_ITEMS_BEHAVIOR } from '../../../../../../../../Constants/SelectedItems.Constants';
import {
  ADMIN,
  MODAL_TYPES,
} from '../../../../../../../../Constants/Modals.Constants';
import { setOpenedModal } from '../../../../../../../Services/Modals.service';
import { MenuOptions } from './MenuOptions/MenuOptions';
import { getActionAuthStatus } from './MenuOptions/helper';
import useObservable from '../../../../../../../../Shared/Hooks/useObservable.Hook';
import { getUser } from '../../../../../../../Services/User.service';
import { downloadItems } from '../../../../../../../Services/ItemsDownload.service';
import { setRenameItemStatus } from '../../../../../../../Services/ItemRename.service';
import withMemberAuthorization from '../../../../../../../../Shared/HOC/withMemberAuthorization';
import {
  deleteFileOrFolderBi,
  menuClickBi, moveFileClickBi,
} from '../../../../../../../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';
import {useTranslation} from "@wix/yoshi-flow-editor";
import { useAppContext } from '../../../../../../../Shared/AppContext';

const ItemActionsMenu = ({
  item,
  setOpen,
  handleOpenMove,
  memberAuthorization,
}: any) => {
  const { compId } = useAppContext();
  const canRename = getActionAuthStatus(item, ITEM_MENU_OPTIONS.RENAME);

  const canMove = getActionAuthStatus(item, ITEM_MENU_OPTIONS.MOVE);

  const canDelete = getActionAuthStatus(item, ITEM_MENU_OPTIONS.DELETE);

  const canManage = canRename && canMove && canDelete;

  const [error, user]: any = useObservable(getUser());
  const {t} = useTranslation();
  const isAdmin: boolean = user?.role === ADMIN;
  const handleOpenModal = (type: string) => {
    setOpen && setOpen();
    setOpenedModal(type,compId);
  };
  const handleDownloadClick = () => {
    const downloadPermissions =
      item &&
      item.authorizeActions?.find(
        (itemData: any) => itemData.action === ITEM_MENU_OPTIONS.DOWNLOAD,
      );
    if (downloadPermissions.status === STATUS.FORBIDDEN) {
      memberAuthorization({
        args: [t],
        callBack: downloadItems,
      });
    } else {
      downloadItems(t, compId);
      setOpen && setOpen();
    }
  };

  const handleOnClick = (type: string) => {
    switch (type) {
      case ITEM_MENU_OPTIONS.SELECT:
        handleSelectedItemsChange(compId,SELECTED_ITEMS_BEHAVIOR.ADD_ITEM, item.id);
        break;
      case ITEM_MENU_OPTIONS.DOWNLOAD:
        menuClickBi(ITEM_MENU_OPTIONS.DOWNLOAD, item, compId);
        handleDownloadClick();
        break;
      case ITEM_MENU_OPTIONS.SHARE:
        menuClickBi(ITEM_MENU_OPTIONS.SHARE, item, compId);
        handleOpenModal(MODAL_TYPES.SHARE);
        break;
      case ITEM_MENU_OPTIONS.RENAME:
        menuClickBi(ITEM_MENU_OPTIONS.RENAME, item, compId);
        setOpen && setOpen();
        setRenameItemStatus({
          folderId: item?.id,
          renamingStatus: true,
          parentFolderId: item?.parentFolderId,
          newName: item?.name,
        });
        break;
      case ITEM_MENU_OPTIONS.PERMISSIONS:
        // TODO:
        break;
      case ITEM_MENU_OPTIONS.MOVE:
        menuClickBi(ITEM_MENU_OPTIONS.MOVE, item, compId);
        moveFileClickBi(item, 'click', 1, compId);
        handleOpenMove();
        break;
      case ITEM_MENU_OPTIONS.DELETE:
        menuClickBi(ITEM_MENU_OPTIONS.DELETE, item, compId);
        deleteFileOrFolderBi(item, 'click', 1, compId);
        handleOpenModal(MODAL_TYPES.DELETE);
        break;
      case ITEM_MENU_OPTIONS.REPORT:
        menuClickBi(ITEM_MENU_OPTIONS.REPORT, item, compId);
        memberAuthorization({
          args: [isAdmin ? MODAL_TYPES.REPORT_RESPONSE : MODAL_TYPES.REPORT],
          callBack: handleOpenModal,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <MenuOptions
        item={item}
        canManage={canManage}
        handleOnClick={(type: string) => handleOnClick(type)}
      />
    </div>
  );
};

export default withMemberAuthorization(ItemActionsMenu);
