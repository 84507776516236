import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import {
  RequestLogin$,
  navigateToPricingPlans$,
  UserSubject$,
} from '../Observables/User.observable';
import { Reason, Status } from '../../Constants/Permissions';
import { getRootFolder } from '../Services/RootFolder.service';

export const memberAuthorizationHelper = async () => {
  const requestLoginTempResult = RequestLogin$.asObservable();
  const requestLoginLastTempResult = requestLoginTempResult.pipe(take(1));
  const requestLogin = await lastValueFrom(requestLoginLastTempResult);
  return requestLogin;
};

export const getUserHelper = async () => {
  const userTempResult = UserSubject$.asObservable();
  const userTempResult2 = userTempResult.pipe(take(1));
  const userResult = await lastValueFrom(userTempResult2);
  return userResult;
};

export const navigateToPricingPlansAppHelper = async () => {
  const navigateToPricingPlansAppTempResult =
    navigateToPricingPlans$.asObservable();
  const navigateToPricingPlansAppLastTempResult =
    navigateToPricingPlansAppTempResult.pipe(take(1));
  const navigateToPricingPlansApp = await lastValueFrom(
    navigateToPricingPlansAppLastTempResult,
  );
  return navigateToPricingPlansApp;
};

export const handleMemberAuth = async ({
  permissions,
  callBack,
  openPrivacyModal,
  memberAuthorization,
  memberAuthorizationArgs = [],
}: any) => {
  const user = await getUserHelper();
  if (user?.privacyStatus === 'PRIVATE') {
    openPrivacyModal(callBack);
  } else if (permissions.status === Status.FORBIDDEN) {
    if (permissions.reason === Reason.MUST_BE_PUBLIC) {
      openPrivacyModal(callBack);
    } else if (permissions.reason === Reason.MUST_BE_A_MEMBER) {
      memberAuthorization({ memberAuthorizationArgs, callBack });
    }
  } else {
    callBack(...memberAuthorizationArgs);
  }
};
export const getRootFolderHelper = async (compId: string) => {
  const rootFolderTempResult = getRootFolder(compId).asObservable();
  const rootFolderLastTempResult = rootFolderTempResult.pipe(take(1));
  const rootFolder = await lastValueFrom(rootFolderLastTempResult);
  return rootFolder;
};
