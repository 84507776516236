import React, { useEffect, useState } from 'react';
import { Dialog } from 'wix-ui-tpa';
import { MODAL_TYPES } from '../../../Constants/Modals.Constants';
import ShareWrapper from './ShareWrapper/ShareWrapper';
import DeleteWrapper from './DeleteWrapper/DeleteWrapper';
import ReportWrapper from './ReportWrapper/ReportWrapper';
import { setOpenedModal } from '../../Services/Modals.service';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import { getMenuSelectedItem } from '../../Services/MenuSelectedItem';
import { getSelectedItems } from '../../Services/SelectedItems.service';
import { classes } from './DialogWrapper.st.css';
import { ReportDialogResponse } from './ReportWrapper/ReportDialogResponse';
import JoinCommunityWrapper from './JoinCommunityWrapper/JoinCommunityWrapper';
import { useAppContext } from '../AppContext';

let contentClassName: any = '';
let childrenWrapperClassName: any = '';
const DialogWrapper = ({ type, isMobile }: any) => {
  const { compId } = useAppContext();

  const [state, setState] = useState({
    contentClassName: '',
    childrenWrapperClassName: '',
  });
  const [error1, currentSelectedItem]: any = useObservable(
    getMenuSelectedItem(),
  );
  const [error2, selectedItems]: any = useObservable(getSelectedItems(compId));

  const handleOnClose = () => {
    setOpenedModal('',compId);
  };
  const updateClassName = (modalType: string) => {
    switch (modalType) {
      case MODAL_TYPES.SHARE:
        contentClassName =
          isMobile === true
            ? classes.mobileShareModalWrapperContent
            : classes.shareModalWrapperContent;
        setState((prev) => ({ ...prev, contentClassName }));
        break;
      case MODAL_TYPES.DELETE:
        contentClassName =
          isMobile === true
            ? classes.mobilContentClassName
            : classes.contentClassName;

        setState((prev) => ({
          ...prev,
          contentClassName,
        }));
        break;
      case MODAL_TYPES.REPORT:
        contentClassName =
          isMobile === true
            ? classes.reportWrapperMobileView
            : classes.contentClassName;
        childrenWrapperClassName = classes.childrenWrapperClassName;
        setState((prev) => ({
          ...prev,
          contentClassName,
          childrenWrapperClassName,
        }));
        break;
      case MODAL_TYPES.REPORT_RESPONSE:
        contentClassName =
          isMobile === true
            ? classes.reportResponseModalContentMobileView
            : classes.reportResponseModalContent;
        childrenWrapperClassName =
          isMobile === true ? classes.childrenWrapperClassNameMobileView : '';
        setState((prev) => ({
          ...prev,
          contentClassName,
          childrenWrapperClassName,
        }));
        break;
    }
  };
  useEffect(() => {
    updateClassName(type);
  }, [type]);

  const renderDialog = () => {
    switch (type) {
      case MODAL_TYPES.JOINCOMMUNITY:
        return (
          <JoinCommunityWrapper
            isMobile={isMobile}
            currentItem={currentSelectedItem}
            handleOnClose={handleOnClose}
          />
        );
      case MODAL_TYPES.SHARE:
        return (
          <ShareWrapper isMobile={isMobile} currentItem={currentSelectedItem} />
        );
      case MODAL_TYPES.DELETE:
        return (
          <DeleteWrapper
            isMobile={isMobile}
            currentItem={currentSelectedItem}
            handleOnClose={handleOnClose}
          />
        );
      case MODAL_TYPES.REPORT:
        return (
          <ReportWrapper
            currentItem={currentSelectedItem}
            handleOnClose={handleOnClose}
          />
        );
      case MODAL_TYPES.REPORT_RESPONSE:
        return (
          <ReportDialogResponse
            isMobile={isMobile}
            handleOnClose={handleOnClose}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      {currentSelectedItem || selectedItems ? (
        <Dialog
          notFullscreenOnMobile={true}
          contentClassName={state.contentClassName}
          childrenWrapperClassName={state.childrenWrapperClassName}
          isOpen={true}
          onClose={handleOnClose}
        >
          {renderDialog()}
        </Dialog>
      ) : null}
    </>
  );
};

export default DialogWrapper;
