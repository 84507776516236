import {  lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { getSelectedFolder } from '../Services/SelectedFolder.service';
import { setSelectedFolder$ } from './SelectedFolder.observable';
import { getObservableById, setObservableById } from './helper';

export const uploadingFileStatus$ = new Map();

export const getUploadingFileStatus$ = (compId: string) =>
  getObservableById(uploadingFileStatus$, compId, {
    status: 'CLOSED',
    libraryItems: [],
  });
export const setUploadingFileStatus$ = (
  newUploadingStatus: any,
  compId: string,
) => setObservableById(uploadingFileStatus$, newUploadingStatus, compId);

export const addFilesToSelectedFolder$ = async (
  newLibraryItems: any,
  compId: string,
) => {
  const tempResult = getSelectedFolder(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.libraryItems = [...newLibraryItems, ...newResult.libraryItems];
  setSelectedFolder$(newResult, compId);
};
