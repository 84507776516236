import React, { useEffect, useState, useCallback, useRef } from 'react';
import ItemWrapper from './Item/ItemWrapper';
import { SkeletonItemWrapper } from './SkeletonItemWrapper/SkeletonItemWrapper';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import EmptyState from '../../../../Shared/EmptyStates/EmptyFolder/EmptyFolder';
import {
  getSelectedFolder,
  loadMoreFolderItems,
} from '../../../../Services/SelectedFolder.service';
import {
  AddFolderStatus,
  getAddFolderStatus,
} from '../../../../Services/AddNewFolder.service';
import { getSearchSubject } from '../../../../Services/Search.service';
import NoResults from '../../../../Shared/EmptyStates/NoResults/NoResults';
import { getUploadingFileStatus } from '../../../../Services/Upload/Upload.service';
import { FileSkeletonWrapper } from './FileSkeletonWrapper/FileSkeletonWrapper';
import EmptyStateNoFolders from '../../../../Shared/EmptyStates/EmptyStateNoFolders/EmptyStateNoFolders';
import { getIsLoading } from '../../../../Services/Loading.service';
import { useAppContext } from '../../../../Shared/AppContext';
import { getRootFolder } from '../../../../Services/RootFolder.service';

const ItemsListWrapper = () => {
  const { compId } = useAppContext();
  const tableRef = useRef<HTMLDivElement>(null);
  const [error1, addFolderStatus] = useObservable(getAddFolderStatus(compId));
  const [error2, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [error3, rootFolder]: any = useObservable(getRootFolder(compId));
  const [error4, searchSubject]: any = useObservable(getSearchSubject(compId));
  const [error5, uploadingFiles]: any = useObservable(getUploadingFileStatus(compId));
  const [error6, isLoading]: any = useObservable(getIsLoading(compId));


  const [state, setState] = useState<any>({
    libraryItems: undefined,
    sort: null,
    metaData: null,
    tableSettings: [],
    hoveredOrSelectedItems: [],
  });

  const setHoveredOrSelectedItems = (type: string, itemId: string) => {
    let tempHoveredOrSelectedItems: any = [];
    const currentItemId = itemId;
    const currentItemIndex = state.libraryItems.findIndex(
      (item: any) => item.id === currentItemId,
    );
    let previousItemId;
    if (currentItemIndex > 0) {
      previousItemId = state.libraryItems[currentItemIndex - 1].id;
    }
    if (type === 'MouseEnter') {
      tempHoveredOrSelectedItems = [];
      tempHoveredOrSelectedItems.push(currentItemId);
      if (currentItemIndex > 0) {
        tempHoveredOrSelectedItems.push(previousItemId);
      }
    }
    setState((prev: any) => ({
      ...prev,
      hoveredOrSelectedItems: tempHoveredOrSelectedItems,
    }));
  };

  const getPreviousItems = (selectedItems: []) => {
    const tempPreviousSelectedItemId: any = [];
    selectedItems.forEach((selectedItemId) => {
      const currentItemIndex = state.libraryItems.findIndex(
        (item: any) => item.id === selectedItemId,
      );
      if (currentItemIndex > 0) {
        const previousItemId = state.libraryItems[currentItemIndex - 1].id;
        tempPreviousSelectedItemId.push(previousItemId);
      }
    });
    return tempPreviousSelectedItemId;
  };

  const generateItems = () => {
    if (state.libraryItems && state.libraryItems.length > 0) {
      return state.libraryItems.map((item: any, index: number) => {
        return (
          <ItemWrapper
            index={index}
            getPreviousItems={getPreviousItems}
            hoveredOrSelectedItems={state.hoveredOrSelectedItems}
            setHoveredOrSelectedItems={setHoveredOrSelectedItems}
            item={item}
            key={item.id}
            tableSettings={state.tableSettings}
          />
        );
      });
    }
  };

  const isBottom = (el: any) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 500;
  };

  const trackScrolling = useCallback(() => {
    const wrappedElement = tableRef?.current;
    if (wrappedElement && isBottom(wrappedElement)) {
      if (
        state.libraryItems.length > 0 &&
        state.metaData &&
        state.metaData.nextCursor
      ) {
        loadMoreFolderItems(
          state.libraryItems[0].parentFolderId,
          state.metaData.nextCursor,
          compId,
        );
      }
      document.removeEventListener('scroll', trackScrolling);
    }
  }, [state.libraryItems, state.metaData]);

  useEffect(() => {
    if (selectedFolder && selectedFolder.libraryItems) {
      setState((prev: any) => ({
        ...prev,
        libraryItems: selectedFolder.libraryItems,
        metaData: selectedFolder.metaData,
      }));
      if (selectedFolder.libraryItems.length > 0) {
        document.addEventListener('scroll', trackScrolling);
      }
    }
    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [selectedFolder, trackScrolling]);

  return (
    <>
      {uploadingFiles !== undefined &&
        uploadingFiles.status === 'OPENED' &&
        uploadingFiles.libraryItems.length > 0 && (
          <FileSkeletonWrapper status={addFolderStatus} />
        )}
      {addFolderStatus !== AddFolderStatus.CLOSED && (
        <SkeletonItemWrapper status={addFolderStatus} />
      )}
      {state.libraryItems &&
      state.libraryItems.length > 0 &&
      selectedFolder !== undefined &&
      rootFolder !== undefined ? (
        <div ref={tableRef} id="table" tabIndex={0} aria-label="Items list">
          {generateItems()}
        </div>
      ) : selectedFolder !== undefined &&
        state.libraryItems &&
        state.libraryItems.length === 0 &&
        searchSubject &&
        searchSubject?.value !== undefined &&
        searchSubject?.value !== '' ? (
        <NoResults />
      ) : selectedFolder !== undefined &&
        rootFolder !== undefined &&
        isLoading === false &&
        state.libraryItems &&
        state.libraryItems.length === 0 &&
        addFolderStatus === AddFolderStatus.CLOSED ? (
        <>
          <EmptyState />
        </>
      ) : selectedFolder === undefined &&
        state.libraryItems === undefined &&
        rootFolder === undefined &&
        isLoading !== true &&
        isLoading !== undefined ? (
        <EmptyStateNoFolders />
      ) : null}
    </>
  );
};

export default ItemsListWrapper;
