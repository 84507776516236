import React from 'react';
import Search from 'wix-ui-icons-common/Search';
import { TextField, TextFieldTheme } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import { getBreadCrumbs } from '../../../../Services/BreadCrumbs.service';
import {
  setSearchSubject,
  getSearchSubject,
  setDisableButtonsStatus,
} from '../../../../Services/Search.service';
import { st, classes } from './SearchTextField.st.css';
import { getSelectedFolder } from '../../../../Services/SelectedFolder.service';
import { getAppSettings } from '../../../../Services/AppSettings.service';
import { useAppContext } from '../../../../Shared/AppContext';

const SearchTextField = () => {
  const { t } = useTranslation();
  const { compId } = useAppContext();
  const [error, breadCrumbs]: any = useObservable(getBreadCrumbs(compId));
  const [error2, searchObj]: any = useObservable(getSearchSubject(compId));
  const [error3, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [error4, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';

  const currentFolderName =
    breadCrumbs?.length > 0 ? breadCrumbs[breadCrumbs.length - 1].value : '';
  const placeholder = t('app.widget.header.search.placeholder', {
    0: currentFolderName,
  });
  const disabledTextField =
    selectedFolder !== undefined &&
    selectedFolder?.libraryItems?.length === 0 &&
    !(searchObj && searchObj?.value !== undefined && searchObj?.value !== '');
  const handleOnChange = (newValue: any, callApi: boolean) => {
    setSearchSubject({ callApi, value: newValue }, compId);
  };

  return (
    <div
      className={
        isMobile !== undefined && !isMobile ? classes.searchDesktopWidth : ''
      }
    >
      <TextField
        className={st(classes.searchTextField, {
          isMobile,
          isDisabled: disabledTextField,
        })}
        disabled={disabledTextField}
        onFocus={() => setDisableButtonsStatus(true, compId)}
        onBlur={() => {
          if (searchObj && searchObj?.value?.length <= 0) {
            setDisableButtonsStatus(false, compId);
          }
        }}
        value={searchObj && searchObj.value !== null ? searchObj.value : ''}
        prefix={<Search />}
        theme={TextFieldTheme.Line}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e.target.value, true)}
        withClearButton
        onClear={() => {
          handleOnChange(null, false);
        }}
        height="40px"
      />
    </div>
  );
};

export default SearchTextField;
