import React from 'react';
import { st, classes } from '../../../../../../Shared/SharedText.st.css';
import { classes as headerClasses } from '../TableHeader.st.css';
import { Text } from 'wix-ui-tpa';

const ContributorsComponent = ({ name }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        width: '100%',
      }}
      title={name}
    >
      <Text
        className={`${st(classes.textAndDividersColor, { isMobile: false })} ${
          headerClasses.contentEllipsis
        }`}
      >
        {name}
      </Text>
    </div>
  );
};
export default ContributorsComponent;
