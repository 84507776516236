import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Status } from '../../../../Constants/Permissions';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedFolder } from '../../../Services/SelectedFolder.service';
import EmptyStates from '../EmptyStates';
import { getAppSettings } from '../../../Services/AppSettings.service';
import { useAppContext } from '../../AppContext';

const EmptyFolder = () => {
  const { t } = useTranslation();
  const { compId } = useAppContext();
  const [, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [, appSettings]: any = useObservable(getAppSettings());
  const showFileButton =
    selectedFolder !== undefined &&
    selectedFolder.folderAuthorizedActions?.[0]?.status === Status.ALLOWED;
  const isEditor =
    appSettings !== undefined && appSettings.viewMode === 'Editor';
  const text1 = showFileButton
    ? isEditor
      ? t('app.widget.emptyState.empty.text1.editor')
      : t('app.widget.emptyState.empty.text1')
    : t('app.widget.emptyState.noPermissions.title');
  const text2 = showFileButton
    ? isEditor
      ? t('app.widget.emptyState.empty.text2.editor')
      : t('app.widget.emptyState.empty.text2')
    : t('app.widget.emptyState.noPermissions.subTitle');
  return <EmptyStates title={text1} subTitle={text2} />;
};

export default EmptyFolder;
