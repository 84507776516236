import { take, lastValueFrom } from 'rxjs';
import { SELECTED_ITEMS_BEHAVIOR } from '../../Constants/SelectedItems.Constants';
import {
  addItemToSelectedItems$,
  getSelectedItems$,
  removeItemFromSelectedItems$,
  setSelectedItems$,
} from '../Observables/SelectedItems.observable';
import { isDownload } from './ItemsDownload.service';
import { getSelectedFolder } from './SelectedFolder.service';

export const getSelectedItems = (compId: string) => {
  return getSelectedItems$(compId);
};

export const setSelectedItems = (selectedItems: any, compId: string) =>
  setSelectedItems$(selectedItems, compId);

export const handleSelectedItemsChange = (
  compId: string,
  Type: string,
  id?: string,
) => {
  switch (Type) {
    case SELECTED_ITEMS_BEHAVIOR.SELECT_ALL:
      selectAllItems(compId);
      break;
    case SELECTED_ITEMS_BEHAVIOR.DESELECT_ALL:
      setSelectedItems$([], compId);
      break;
    case SELECTED_ITEMS_BEHAVIOR.ADD_ITEM:
      if (id) {
        addItemToSelectedItems$(id, compId);
      }
      break;
    case SELECTED_ITEMS_BEHAVIOR.REMOVE_ITEM:
      if (id) {
        removeItemFromSelectedItems$(id, compId);
      }
      break;
  }
};

const selectAllItems = async (compId: string) => {
  const tempResult = getSelectedFolder(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = previousResult.libraryItems.map((item: any) => item.id);
  setSelectedItems$(newResult, compId);
  await isDownload(compId);
};
