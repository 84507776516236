import { lastValueFrom, take } from 'rxjs';
import { menuSelectedItem$ } from '../Observables/MenuSelectedItem';
import { getSelectedItems } from '../Services/SelectedItems.service';

export const getSelectedItemsHelper = async (compId: string) => {
  const tempResult = getSelectedItems(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

export const getCurrentItemHelper = async () => {
  const tempResult = menuSelectedItem$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
