import { getBiEventsParams } from '../Services/BiEvents.service';
import { lastValueFrom, take } from 'rxjs';
import {
  BI_FILE_SHARE_WIDGET_APP_LOADED,
  BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER,
  BI_FILE_SHARE_WIDGET_DETAILS,
  BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE,
  BI_FILE_SHARE_WIDGET_FAVORITE_CLICK,
  BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK,
  BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK,
  BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK,
  BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED,
  BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK,
  BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED,
  BI_FILE_SHARE_WIDGET_RENAME_FILE,
  BI_FILE_SHARE_WIDGET_REPORT,
  BI_FILE_SHARE_WIDGET_SEARCH,
  BI_FILE_SHARE_WIDGET_SORT_BY,
  BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER,
} from './Constants/BiConstants';
import { biFileShareWidgetAppLoaded } from './BiFunctions/biFileShareWidgetAppLoaded';
import { biFileShareWidgetNewFileClick } from './BiFunctions/biFileShareWidgetNewFileClick';
import { biFileShareWidgetNewFolderClick } from './BiFunctions/biFileShareWidgetNewFolderClick';
import { biFileShareWidgetNewFileCreated } from './BiFunctions/biFileShareWidgetNewFileCreated';
import { biFileShareWidgetNewFolderCreated } from './BiFunctions/biFileShareWidgetNewFolderCreated';
import { biFileShareWidgetFileMenuClick } from './BiFunctions/biFileShareWidgetFileMenuClick';
import { biFileShareWidgetDeleteFileOrFolder } from './BiFunctions/biFileShareWidgetDeleteFileOrFolder';
import { biFileShareWidgetMoveFileClick } from './BiFunctions/biFileShareWidgetMoveFileClick';
import { biFileShareWidgetRenameFile } from './BiFunctions/biFileShareWidgetRenameFile';
import { biFileShareWidgetSortBy } from './BiFunctions/biFileShareWidgetSortBy';
import { biFileShareWidgetSearch } from './BiFunctions/biFileShareWidgetSearch';
import { biFileShareWidgetViewFileOrOpenFolder } from './BiFunctions/biFileShareWidgetViewFileOrOpenFolder';
import { biFileShareWidgetFavoriteClick } from './BiFunctions/biFileShareWidgetFavoriteClick';
import { biFileShareWidgetDownloadFile } from './BiFunctions/biFileShareWidgetDownloadFile';
import { biFileShareWidgetReport } from './BiFunctions/biFileShareWidgetReport';
import {biFileShareWidgetDetails} from "./BiFunctions/biFileShareWidgetDetails";

const biService = async (params: any, biType: string, compId: string) => {
  let biEventParams = null;

  if (biEventParams === null) {
    const tempObservable$ = getBiEventsParams(compId).pipe(take(1));
    biEventParams = await lastValueFrom(tempObservable$);
  }
  const newParams = { ...biEventParams, ...params };
  switch (biType) {
    case BI_FILE_SHARE_WIDGET_APP_LOADED:
      return biFileShareWidgetAppLoaded(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK:
      return biFileShareWidgetNewFileClick(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK:
      return biFileShareWidgetNewFolderClick(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED:
      return biFileShareWidgetNewFileCreated(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED:
      return biFileShareWidgetNewFolderCreated(newParams);
    case BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK:
      return biFileShareWidgetFileMenuClick(newParams);
    case BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER:
      return biFileShareWidgetDeleteFileOrFolder(newParams);
    case BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK:
      return biFileShareWidgetMoveFileClick(newParams);
    case BI_FILE_SHARE_WIDGET_RENAME_FILE:
      return biFileShareWidgetRenameFile(newParams);
    case BI_FILE_SHARE_WIDGET_SORT_BY:
      return biFileShareWidgetSortBy(newParams);
    case BI_FILE_SHARE_WIDGET_SEARCH:
      return biFileShareWidgetSearch(newParams);
    case BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER:
      return biFileShareWidgetViewFileOrOpenFolder(newParams);
    case BI_FILE_SHARE_WIDGET_FAVORITE_CLICK:
      return biFileShareWidgetFavoriteClick(newParams);
    case BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE:
      return biFileShareWidgetDownloadFile(newParams);
    case BI_FILE_SHARE_WIDGET_REPORT:
      return biFileShareWidgetReport(newParams);
    case BI_FILE_SHARE_WIDGET_DETAILS:
      return biFileShareWidgetDetails(newParams);
    default:
  }
};
export default biService;
