import { AppSettingsInterface } from '../Interfaces/AppSettings.Interface';
import {
  getAppSettings$,
  setAppSettings$,
} from '../Observables/AppSettings.observable';

export const setAppSettings = (appSettings: AppSettingsInterface) => {
  setAppSettings$(appSettings);
};

export const getAppSettings = () => {
  return getAppSettings$();
};
