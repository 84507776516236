import React, { useEffect, useState } from 'react';
import { classes } from './MainToolBar.st.css';
import FolderButton from './FolderButton/FolderButton';
import FileButton from './FileButton/FileButton';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedFolder } from '../../../../../Services/SelectedFolder.service';
import { getRootFolder } from '../../../../../Services/RootFolder.service';
import { Status } from '../../../../../../Constants/Permissions';
import { getUser } from '../../../../../Services/User.service';
import { ADMIN } from '../../../../../../Constants/Modals.Constants';
import { AddFolderStatus } from '../../../../../Observables/AddNewFolder.observable';
import { getAddFolderStatus } from '../../../../../Services/AddNewFolder.service';
import { getDisableButtonsStatus } from '../../../../../Services/Search.service';
import { getAppSettings } from '../../../../../Services/AppSettings.service';
import { useAppContext } from '../../../../../Shared/AppContext';

const MainToolBar = () => {
  const { compId } = useAppContext();
  const [, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [, rootFolder]: any = useObservable(getRootFolder(compId));
  const [, user]: any = useObservable(getUser());
  const [, addFolderStatus] = useObservable(getAddFolderStatus(compId));
  const [, disableButtonStatus] = useObservable(getDisableButtonsStatus(compId));
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const isAdmin: boolean = user?.role === ADMIN;
  const [state, setState]: any = useState({
    createFolder: {},
    uploadFile: {},
  });
  const isCreateFolderDisabled =
    (addFolderStatus !== undefined &&
      addFolderStatus !== AddFolderStatus.CLOSED) ||
    (rootFolder === undefined && selectedFolder === undefined);
  const isUploadFileDisabled =
    rootFolder === undefined && selectedFolder === undefined;
  useEffect(() => {
    if (selectedFolder) {
      const uploadFileObj = selectedFolder?.folderAuthorizedActions?.[0] || {};
      const createFolderObj =
        selectedFolder?.folderAuthorizedActions?.[1] || {};
      setState({
        createFolder: createFolderObj,
        uploadFile: uploadFileObj,
      });
    }
  }, [selectedFolder]);
  const showFolderButton =
    state?.createFolder?.status === Status.ALLOWED || isAdmin;
  const showFileButton =
    state?.uploadFile?.status === Status.ALLOWED || isAdmin;
  return (
    <div className={classes.mainToolBarContainer}>
      {!disableButtonStatus ? (
        <>
          {showFolderButton ? (
            <FolderButton
              folderPermissions={state?.createFolder}
              isCreateFolderDisabled={isCreateFolderDisabled}
              isMobile={isMobile}
            />
          ) : null}
          {showFileButton ? (
            <FileButton
              uploadFilePermissions={state?.uploadFile}
              isUploadFileDisabled={isUploadFileDisabled}
              isMobile={isMobile}
            />
          ) : null}
        </>
      ) : (
        <div className={classes.hiddenButtonsContainer} />
      )}
    </div>
  );
};

export default MainToolBar;
