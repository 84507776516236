import React from 'react';
import { classes } from './iconStyle.st.css';

export const MiscFill = (props: any) => {
  const { isMobile } = props;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 23H5.5C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23Z"
        fill="#343842"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#343842"
        fillOpacity="0.35"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17V18H15V17H16V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V17H9ZM12.5 11V15.085L13.795 13.795L14.5 14.5L12 17L9.5 14.5L10.205 13.795L11.5 15.085V11H12.5Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.639 10.2372C27.234 10.0852 26.914 9.76525 26.762 9.36125L24.001 2.00025H6.501C5.672 1.99925 5 2.67125 5 3.50025V40.5002C5 41.3283 5.672 42.0002 6.5 42.0002H33.5C34.329 42.0002 35 41.3282 35 40.4992V12.9982L27.639 10.2372Z"
        fill="#343842"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="#343842"
        fillOpacity="0.35"
        className={classes.fillIconFileColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0003 29V31.5C14.0003 31.775 14.2253 32 14.5003 32H25.5003C25.7753 32 26.0003 31.775 26.0003 31.5V29H27.0003V31.5C27.0003 32.327 26.3273 33 25.5003 33H14.5003C13.6733 33 13.0003 32.327 13.0003 31.5V29H14.0003ZM20.5003 19.0004V27.6174L23.2903 24.8194L23.9993 25.5264L20.0003 29.5354L16.0013 25.5264L16.7083 24.8194L19.5003 27.6184V19.0004H20.5003Z"
        fill="white"
      />
    </svg>
  );
};
