import {
  getIsLoading$,
  setIsLoading$,
} from '../Observables/Loading.observable';

export const setIsLoading = (value: boolean, compId: string) => {
  setIsLoading$(value, compId);
};

export const getIsLoading = (compId: string) => {
  return getIsLoading$(compId);
};
