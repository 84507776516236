import {
  getOpenedModal$,
  setOpenedModal$,
} from '../Observables/Modals.observable';

export const setOpenedModal = (openedModal: string,compId:string) => {
  setOpenedModal$(openedModal,compId);
};

export const getOpenedModal = (compId:string) => {
  return getOpenedModal$(compId);
};
