import { HttpClient } from '@wix/http-client';
import {
  queryLibraryFolderItems,
} from '@wix/ambassador-file-sharing-v1-library-item/http';
import { getInstance } from '../Services/Instance.service';

export class EditorFileSharingService {
  editorFileSharingService: any;
  instance: string = '';
  constructor() {
    getInstance().subscribe((data: string) => {
      this.instance = data;
      if (this.instance) {
        this.editorFileSharingService = new HttpClient({
          baseURL: 'https://editor.wixapps.net/',
          headers: { Authorization: this.instance },
        });
      }
    });
  }

  getAllFolders = async () => {
    try {
      const result = await this.editorFileSharingService.request(
        queryLibraryFolderItems({}),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };
}
