export const setSelectedFolderIdInSettings = (
  settingsParamKey: string,
  folderId: string,
  isEditor: boolean,
  Wix: any,
) => {
  if (isEditor) {
    try {
      Wix?.Data.Public.set(
        settingsParamKey,
        folderId,
        { scope: 'COMPONENT' },
        function () {},
        function (f: any) {
          console.log('set public data failed', f);
        },
      );
    } catch (e) {
      console.log('error ', e);
    }
  }
};
