import {
  setBreadCrumbs$,
  addToBreadCrumbs$,
  getBreadCrumbs$,
} from '../Observables/BreadCrumbs.observable';

export const getBreadCrumbs = (compId: string) => {
  return getBreadCrumbs$(compId);
};

export const setBreadCrumbs = (breadCrumb: any, compId: string) => {
  setBreadCrumbs$(breadCrumb, compId);
};

export const addToBreadCrumbs = (breadCrumb: any, compId: string) => {
  addToBreadCrumbs$(breadCrumb, compId);
};
