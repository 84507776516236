import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import FileSkeletonItem from './SkeletonItem/FileSkeletonItem';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { getUploadingFileStatus } from '../../../../../Services/Upload/Upload.service';
import { useAppContext } from '../../../../../Shared/AppContext';

export const FileSkeletonWrapper = ({ status }: any) => {
  const { t } = useTranslation();
  const { compId } = useAppContext();

  const [error1, uploadingFiles]: any = useObservable(
    getUploadingFileStatus(compId),
  );

  return (
    <>
      {uploadingFiles !== undefined &&
        uploadingFiles.status === 'OPENED' &&
        uploadingFiles.libraryItems.length > 0 &&
        uploadingFiles.libraryItems.map((item: any) => {
          return <FileSkeletonItem item={item} />;
        })}
    </>
  );
};
