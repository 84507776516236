import { take, lastValueFrom } from 'rxjs';
import { getObservableById, setObservableById } from './helper';

const SortSubject$ = new Map();

export const getSort$ = (compId: string) =>
  getObservableById(SortSubject$, compId, {
    sortBy: '',
    orientation: '',
  });
export const setSort$ = (sortObj: any, compId: string) =>
  setObservableById(SortSubject$, sortObj, compId);

export const getSortLastValue$ = async (compId: string) => {
  const tempResult = getSort$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
