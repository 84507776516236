import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { isDownload } from '../Services/ItemsDownload.service';
import { getObservableById, setObservableById } from './helper';

const selectedItemsSubject$ = new Map();

export const getSelectedItems$ = (compId: string) =>
  getObservableById(selectedItemsSubject$, compId, []);

export const setSelectedItems$ = async (selectedItems: any, compId: string) => {
  setObservableById(selectedItemsSubject$, selectedItems, compId);
  await isDownload(compId);
};

export const removeItemFromSelectedItems$ = async (
  id: string,
  compId: string,
) => {
  const tempResult = getSelectedItems$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = previousResult.filter((item: any) => item !== id);
  setSelectedItems$(newResult, compId);
  await isDownload(compId);
};

export const addItemToSelectedItems$ = async (id: string, compId: string) => {
  const tempResult = getSelectedItems$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  let newResult: any = [...previousResult];
  newResult = [...newResult, id];
  setSelectedItems$(newResult, compId);
  await isDownload(compId);
};
