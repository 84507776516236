import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IHostProps,
  useTranslation,
  WidgetProps,
  useWixSdk,
  useBi,
} from '@wix/yoshi-flow-editor';
import useObservable from '../Shared/Hooks/useObservable.Hook';
import { getAppSettings, setAppSettings } from './Services/AppSettings.service';
import {
  setRequestLogin,
  setUser,
  setNavigateToPricingPlans,
} from './Services/User.service';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../settingsParams';
import FolderTable from './Components/FolderTable/FolderTable';
import { st, classes } from './Widget.st.css';
import {
  setSelectedFolder,
  setSelectedFolderId,
} from './Services/SelectedFolder.service';
import { setRootFolder } from './Services/RootFolder.service';
import { searchObservable$ } from './Observables/Search.observable';
import { setBiEventsParams } from './Services/BiEvents.service';
import biService from './BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_APP_LOADED } from './BiEvents/Constants/BiConstants';
import { getBreadCrumbs } from './Services/BreadCrumbs.service';
import {
  GetAllFolders,
  setAllFolders,
} from '../Settings/Services/Folders.service';
import { setToast } from './Services/Toast.service';
import { TOAST } from '../Constants/Toast.Constants';
import { setSelectedFolderIdInSettings } from './Shared/add-ons/helpers';
import { setIsLoading } from './Services/Loading.service';
import { AppContextProvider } from './Shared/AppContext';

export type ControllerProps = {
  host: IHostProps;
  isMobile: boolean;
  user: any;
  t: any;
  instance: string;
  baseUrl: string;
  requestLogin: any;
  navigateToPricingPlansApp: any;
  appParams: any;
  compId: string;
  isEditor_X: boolean;
  biToken: string;
  isEditor: boolean;
  id: string;
};

const Widget: FC<WidgetProps<ControllerProps>> = (props: ControllerProps) => {
  const {
    isMobile,
    user,
    baseUrl,
    instance,
    requestLogin,
    navigateToPricingPlansApp,
    appParams,
    compId = props.id,
    isEditor_X,
    biToken,
    isEditor,
  } = props;
  const { t }: { t: any } = useTranslation();
  const settings = useSettings();
  const selectedFolderIdFromSettings = settings.get(settingsParams.folderId);
  const [error2, appSettings]: any = useObservable(getAppSettings());
  const [error1, breadCrumbs]: any = useObservable(getBreadCrumbs(compId));
  const [state, setState] = useState({
    isLoadingBiEventSent: false,
  });
  const { accessibilityEnabled, dimensions, formFactor, viewMode, scrollTo } =
    props.host;
  const rtlStatus = settings.get(settingsParams.alignment);
  const { Wix } = useWixSdk();
  useObservable(searchObservable$(compId));
  const bi = useBi();

  const memoizedValue = useMemo(() => {
    const _appSettings = {
      accessibilityEnabled,
      dimensions,
      formFactor,
      viewMode,
      isMobile,
      instance,
      t,
      baseUrl,
      isEditor_X,
      isEditor,
      scrollTo,
    };
    return _appSettings;
  }, [
    isEditor_X,
    accessibilityEnabled,
    dimensions,
    formFactor,
    instance,
    isMobile,
    t,
    viewMode,
    baseUrl,
    isEditor,
    scrollTo,
  ]);

  const _getAllFolders = useCallback(async () => {
    const result = await GetAllFolders('widget');
    if (result && result?.data && result?.data?.libraryItems?.length === 0) {
      setSelectedFolderIdInSettings('folderId', '', isEditor, Wix);
      setAllFolders(result.data);
    } else if (
      result &&
      result?.data &&
      result?.data?.libraryItems?.length > 0
    ) {
      if (selectedFolderIdFromSettings === '-1') {
        setSelectedFolderIdInSettings(
          'folderId',
          result?.data?.libraryItems?.[0].id,
          isEditor,
          Wix,
        );
        setSelectedFolderIdInSettings(
          'selectedFolderIdInLiveSite',
          result?.data?.libraryItems?.[0].id,
          isEditor,
          Wix,
        );
        setRootFolder(result?.data?.libraryItems?.[0].id, compId);
        setSelectedFolder(result?.data?.libraryItems?.[0].id, compId);
        setAllFolders(result.data);
      }
    } else {
      setToast({
        skin: TOAST.error,
        content: result.message,
      });
    }
  }, [selectedFolderIdFromSettings, settings]);
  useEffect(() => {
    const { appDefinitionId, instanceId } = appParams;
    setBiEventsParams(
      {
        visitor_id: user?.id,
        app_id: appDefinitionId,
        instance_id: instanceId,
        logger: bi,
        comp_id: compId,
        owner: user?.role,
        biToken,
      },
      compId,
    );
    if (!appSettings) {
      if (instance) {
        setAppSettings(memoizedValue);
        setUser(user);
        setRequestLogin(requestLogin);
        setNavigateToPricingPlans(navigateToPricingPlansApp);
      }
      if (
        selectedFolderIdFromSettings &&
        selectedFolderIdFromSettings !== '' &&
        selectedFolderIdFromSettings !== '-1'
      ) {
        setRootFolder(selectedFolderIdFromSettings, compId);
        setSelectedFolderId(selectedFolderIdFromSettings, compId);
      } else {
        setRootFolder('', compId);
        setSelectedFolderId('', compId);
        setSelectedFolder(undefined, compId);
      }
    } else {
      if (
        selectedFolderIdFromSettings &&
        selectedFolderIdFromSettings !== '' &&
        selectedFolderIdFromSettings !== '-1'
      ) {
        setIsLoading(true, compId);
        setAppSettings(memoizedValue);
        setUser(user);
        setRootFolder(selectedFolderIdFromSettings, compId);
        setSelectedFolderId(selectedFolderIdFromSettings, compId);
        setRequestLogin(requestLogin);
        setNavigateToPricingPlans(navigateToPricingPlansApp);
      } else {
        setAppSettings(memoizedValue);
        setUser(user);
        setRootFolder('', compId);
        setSelectedFolderId('', compId);
        setSelectedFolder(undefined, compId);
      }
    }
  }, [
    // appSettings,
    // baseUrl,
    instance,
    // memoizedValue,
    selectedFolderIdFromSettings,
    // user,
  ]);
  useEffect(() => {
    if (!state?.isLoadingBiEventSent && breadCrumbs !== undefined) {
      biService(
        {
          folder_id: selectedFolderIdFromSettings,
          folder_name: breadCrumbs[0]?.value,
          isEditor: viewMode === 'Editor',
        },
        BI_FILE_SHARE_WIDGET_APP_LOADED,
        compId,
      );
      setState((prevState) => ({ ...prevState, isLoadingBiEventSent: true }));
    }
  }, [breadCrumbs]);
  useEffect(() => {
    if (selectedFolderIdFromSettings === '-1') {
      _getAllFolders();
    }
    setRootFolder('', compId);
  }, [selectedFolderIdFromSettings, instance]);
  return (
    <AppContextProvider value={{ compId }}>
      {selectedFolderIdFromSettings === undefined ? null : (
        <div
          tabIndex={0}
          aria-label={`Shared Folder: ${breadCrumbs && breadCrumbs[0]?.value}`}
          className={st(classes.root, {})}
          dir={rtlStatus}
        >
          <div className={st(classes.widgetContainer, {})}>
            <FolderTable />
          </div>
        </div>
      )}
    </AppContextProvider>
  );
};

export default Widget;
