import React, { useEffect, useState} from 'react';
import LastUpdatedComponent from './TableColumns/LastUpdatedComponent';
import ViewsComponent from './TableColumns/ViewsComponent';
import FavoritesComponent from './TableColumns/FavoritesComponent';
import ContributorsComponent from './TableColumns/ContributorsComponent';
import { st, classes } from './TableHeader.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import NameComponent from './TableColumns/NameComponent';
import CheckBoxComponent from '../CheckBoxComponent';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import {
  SORT,
  TableColumns,
} from '../../../../../../Constants/TableHeader.Constants';
import { getSelectedItems } from '../../../../../Services/SelectedItems.service';
import { getSort, setSort } from '../../../../../Services/Sort.service';
import { getTableSettings } from '../../../../../Services/TableSettings.service';
import {
  getSelectedFolder,
  getSelectedFolderId,
  setSelectedFolder,
} from '../../../../../Services/SelectedFolder.service';
import { changeSortDirection } from './Sort/helpers';
import { setIsLoading } from '../../../../../Services/Loading.service';
import { getRootFolder } from '../../../../../Services/RootFolder.service';
import { isDisableTableHeader } from '../../../../../Shared/accessModalsHelper';
import { getAppSettings } from '../../../../../Services/AppSettings.service';
import SortWrapper from './Sort/SortWrapper';
import { getIsServerError } from '../../../../../Services/ServerError.service';
import { useAppContext } from '../../../../../Shared/AppContext';

const TableHeader = () => {
  const { compId } = useAppContext();
  const [error1, selectedItems]: any = useObservable(getSelectedItems(compId));
  const [error2, sortObj]: any = useObservable(getSort(compId));
  const [error3, tableSettings]: any = useObservable(getTableSettings(compId));
  const [error4, selectedFolderId]: any = useObservable(getSelectedFolderId(compId));
  const [error5, rootFolder]: any = useObservable(getRootFolder(compId));
  const [error6, selectedFolder]: any = useObservable(getSelectedFolder(compId));
  const [error7, appSettings]: any = useObservable(getAppSettings());
  const [error8, serverError]: any = useObservable(getIsServerError(compId));
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const [isShowCheckBox, setIsShowCheckBox] = useState(false);
  const [state, setState] = useState({
    isShowLastUpdated: false,
    isShowViews: false,
    isShowFavorites: false,
    isShowContributors: false,
  });
  const {
    sortBy = SORT.SORT_BY.LAST_MODIFIED,
    orientation = SORT.ORIENTATION.DESC,
  } = sortObj || {};

  const handleTitleColumnOnClick = ({ sortBy, selectedId }: any) => {
    if (sortBy === selectedId) {
      const tempOrientation = changeSortDirection({ orientation });
      setSort({ sortBy: selectedId, orientation: tempOrientation }, compId);
    } else {
      setSort({ sortBy: selectedId, orientation }, compId);
    }
    setIsLoading(true, compId);
    setSelectedFolder(selectedFolderId, compId);
  };
  const { t }: { t: any } = useTranslation();
  const name: string = t('app.widget.table.header.Name');
  const LastUpdates: string = t('app.widget.table.header.LastUpdated');
  const Views: string = t('app.widget.table.header.Views');
  const Favorites: string = t('app.widget.table.header.Favorites');
  const Contributors: string = t('app.widget.table.header.Contributors');
  const disabledTableHeader = isDisableTableHeader({
    rootFolder,
    selectedFolder,
    serverError,
  });
  const _setIsShown = () => {
    const tempIsShowLastUpdated = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.LAST_UPDATED) {
        return item;
      }
    });
    const tempIsShowViews = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.VIEWS_COUNTER) {
        return item;
      }
    });
    const tempIsShowFavorites = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.FAVORITES) {
        return item;
      }
    });
    const tempIsShowContributors = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.CONTRIBUTORS) {
        return item;
      }
    });
    if (
      tempIsShowLastUpdated &&
      tempIsShowLastUpdated.length > 0 &&
      tempIsShowViews &&
      tempIsShowViews.length > 0 &&
      tempIsShowFavorites &&
      tempIsShowFavorites.length > 0 &&
      tempIsShowContributors &&
      tempIsShowContributors.length > 0
    ) {
      setState((prev) => ({
        ...prev,
        isShowLastUpdated: tempIsShowLastUpdated[0].value,
        isShowViews: tempIsShowViews[0].value,
        isShowFavorites: tempIsShowFavorites[0].value,
        isShowContributors: tempIsShowContributors[0].value,
      }));
    }
  };
  useEffect(() => {
    if (tableSettings && tableSettings.length > 0) {
      _setIsShown();
    }
    if (selectedItems && selectedItems.length > 0) {
      setIsShowCheckBox(false);
    }
  }, [selectedItems, tableSettings]);
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  return !isMobile ? (
    <div
      tabIndex={0}
      aria-label="Sorting options"
      className={st(classes.tableHeaderContainer, { isEditorX })}
      onMouseEnter={() => setIsShowCheckBox(true)}
      onMouseLeave={() => setIsShowCheckBox(false)}
    >
      <div className={classes.tableHeaderContainerCheckBox}>
        {selectedItems &&
        selectedItems.length > 0 &&
        isShowCheckBox === false ? (
          <CheckBoxComponent />
        ) : (
          <div>
            <CheckBoxComponent disabledCheckBox={disabledTableHeader} />
          </div>
        )}
      </div>
      <div className={classes.tableHeaderContainerName}>
        <NameComponent
          disabledTableHeader={disabledTableHeader}
          name={name}
          orientation={orientation}
          sortBy={sortBy}
          handleTitleColumnOnClick={handleTitleColumnOnClick}
        />
      </div>
      {state.isShowLastUpdated ? (
        <div className={classes.tableHeaderContainerLastUpdated}>
          <LastUpdatedComponent
            disabledTableHeader={disabledTableHeader}
            name={LastUpdates}
            orientation={orientation}
            sortBy={sortBy}
            handleTitleColumnOnClick={handleTitleColumnOnClick}
          />
        </div>
      ) : null}
      {state.isShowViews ? (
        <div className={classes.tableHeaderContainerViews}>
          <ViewsComponent
            disabledTableHeader={disabledTableHeader}
            name={Views}
            orientation={orientation}
            sortBy={sortBy}
            handleTitleColumnOnClick={handleTitleColumnOnClick}
          />
        </div>
      ) : null}
      {state.isShowFavorites ? (
        <div className={classes.tableHeaderContainerFavorites}>
          <FavoritesComponent
            disabledTableHeader={disabledTableHeader}
            name={Favorites}
            orientation={orientation}
            sortBy={sortBy}
            handleTitleColumnOnClick={handleTitleColumnOnClick}
          />
        </div>
      ) : null}
      {state.isShowContributors ? (
        <div className={classes.tableHeaderContainerContributors}>
          <ContributorsComponent name={Contributors} />
        </div>
      ) : null}
      <SortWrapper />
    </div>
  ) : (
    <div></div>
  );
};

export default TableHeader;
