import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { getObservableById, setObservableById } from './helper';

const breadCrumbsSubject$ = new Map();

// export const addToBreadCrumbs$ = async (breadCrumb: any) => {
//   const tempResult = breadCrumbsSubject$.asObservable();
//   const lastTempResult = tempResult.pipe(take(1));
//   const previousResult = await lastValueFrom(lastTempResult);
//   let newResult = [...previousResult];
//   newResult = newResult.filter(x => x.id !== breadCrumb.id);
//   breadCrumbsSubject$.next(newResult);
// };

export const addToBreadCrumbs$ = async (breadCrumb: any, compId: string) => {
  const tempResult = getBreadCrumbs$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = [...previousResult];
  newResult.push(breadCrumb);
  setBreadCrumbs$(newResult, compId);
};

export const getBreadCrumbs$ = (compId: string) =>
  getObservableById(breadCrumbsSubject$, compId);

export const setBreadCrumbs$ = (rootBreadCrumb: any, compId: string) =>
  setObservableById(breadCrumbsSubject$, rootBreadCrumb, compId);
