import { take, lastValueFrom } from 'rxjs';
import {
  DELETE_TYPE,
  DELETE_GLITCH,
} from '../../Constants/DeleteToasts.Constants';
import { ITEMTYPE } from '../../Constants/ItemType.Constants';
import { getSelectedFolder } from '../Services/SelectedFolder.service';
import { sendNotification } from './DeleteNotificationSendHelper';

export const getLastSelectedFolderLibraryItems = async (compId: string) => {
  const tempResult = getSelectedFolder(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const getNumOfFiles = (libraryItemIds: any, previousResult: any) => {
  const numOfFiles = previousResult.libraryItems.filter(
    (item: any) => libraryItemIds.includes(item.id) && ITEMTYPE.FILE in item,
  );
  return numOfFiles;
};

const getNumOfFolders = (libraryItemIds: any, previousResult: any) => {
  const numOfFolders = previousResult.libraryItems.filter(
    (item: any) => libraryItemIds.includes(item.id) && ITEMTYPE.FOLDER in item,
  );
  return numOfFolders;
};

const getToastType = (numOfFiles: any, numOfFolders: any) => {
  const toastType =
    numOfFiles.length === 1 && numOfFolders.length === 0
      ? DELETE_TYPE.SINGLE_FILE
      : numOfFiles.length > 1 && numOfFolders.length === 0
      ? DELETE_TYPE.MULTIPLE_FILES
      : numOfFiles.length === 0 && numOfFolders.length === 1
      ? DELETE_TYPE.SINGLE_FOLDER
      : numOfFiles.length === 0 && numOfFolders.length > 1
      ? DELETE_TYPE.MULTIPLE_FOLDERS
      : numOfFiles.length === 1 && numOfFolders.length > 1
      ? DELETE_TYPE.SINGLE_FILE_MULTIPLE_FOLDERS
      : numOfFiles.length > 1 && numOfFolders.length === 1
      ? DELETE_TYPE.SINGLE_FOLDER_MULTIPLE_FILES
      : numOfFiles.length === 1 && numOfFolders.length === 1
      ? DELETE_TYPE.SINGLE_FILE_SINGLE_FOLDER
      : numOfFiles.length > 1 && numOfFolders.length > 1
      ? DELETE_TYPE.MULTIPLE_FOLDERS_MULTIPLE_FILES
      : '';
  return toastType;
};

const getToastGlitch = (numOfFiles: any, numOfFolders: any) => {
  const toastType =
    numOfFiles.length === 1 && numOfFolders.length === 0
      ? DELETE_GLITCH.SINGLE_FILE_GLITCH
      : numOfFiles.length > 1 && numOfFolders.length === 0
      ? DELETE_GLITCH.MULTIPLE_FILES_GLITCH
      : numOfFiles.length === 0 && numOfFolders.length === 1
      ? DELETE_GLITCH.SINGLE_FOLDER_GLITCH
      : numOfFiles.length === 0 && numOfFolders.length > 1
      ? DELETE_GLITCH.MULTIPLE_FOLDERS_GLITCH
      : numOfFiles.length === 1 && numOfFolders.length > 1
      ? DELETE_GLITCH.ITEMS_GLITCH
      : numOfFiles.length > 1 && numOfFolders.length === 1
      ? DELETE_GLITCH.ITEMS_GLITCH
      : numOfFiles.length === 1 && numOfFolders.length === 1
      ? DELETE_GLITCH.ITEMS_GLITCH
      : numOfFiles.length > 1 && numOfFolders.length > 1
      ? DELETE_GLITCH.MULTI_GLITCH
      : '';
  return toastType;
};

export const setNotification = async (libraryItemIds: any, t: any, compId: string) => {
  const previousResult = await getLastSelectedFolderLibraryItems(compId);
  const numOfFiles = getNumOfFiles(libraryItemIds, previousResult);
  const numOfFolders = getNumOfFolders(libraryItemIds, previousResult);
  let fileName = '';
  let folderName = '';
  if (numOfFiles.length > 0) {
    fileName = numOfFiles[0].name;
  }
  if (numOfFolders.length > 0) {
    folderName = numOfFolders[0].name;
  }
  const toastType = getToastType(numOfFiles, numOfFolders);
  sendNotification(
    t,
    toastType,
    numOfFiles.length,
    numOfFolders.length,
    fileName,
    folderName,
  );
};

export const handleErrorsNotifications = async (
  libraryItemIds: any,
  t: any,
  compId: string,
) => {
  const previousResult = await getLastSelectedFolderLibraryItems(compId);
  const numOfFiles = getNumOfFiles(libraryItemIds, previousResult);
  const numOfFolders = getNumOfFolders(libraryItemIds, previousResult);
  const toastType = getToastGlitch(numOfFiles, numOfFolders);
  sendNotification(t, toastType, numOfFiles.length, numOfFolders.length);
};
