import React, { useState } from 'react';
import { st, classes } from './ReportWrapper.st.css';
import {
  Text,
  TextTypography,
  RadioButtonGroup,
  RadioButton,
  Button,
  ButtonPriority,
} from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { reportDialogOptions } from './helper';
import { UNWANTED_OR_SPAM } from './reportTypes';
import { setOpenedModal } from '../../../Services/Modals.service';
import { MODAL_TYPES } from '../../../../Constants/Modals.Constants';
import { reportItems } from '../../../Services/ReportModal.service';
import { getAppSettings } from '../../../Services/AppSettings.service';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import {useAppContext} from "../../AppContext";

const ReportWrapper = ({ currentItem, handleOnClose }: any) => {
  const { t }: { t: any } = useTranslation();
  const { compId} = useAppContext();
  const [error1, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = getAppSettings !== undefined ? AppSettings?.isMobile : '';
  const [state, setState] = useState({
    selectedOption: UNWANTED_OR_SPAM,
  });
  const title = t('app.widget.report.dialog.title');
  const subTitle = t('app.widget.report.dialog.options.title');
  const secondaryButtonText = t('app.widget.report.dialog.cancel');
  const primaryButtonText = t('app.widget.report.dialog.report');
  const radioButtonOptions = reportDialogOptions(t);
  const { selectedOption } = state;
  const secondaryButtonHandleOnClick = () => {
    handleOnClose && handleOnClose();
  };
  const primaryButtonHandleOnClick = () => {
    reportItems({ item: currentItem, reason: state.selectedOption,compId });
  };
  return (
    <div className={classes.ReportWrapperContainer}>
      <div className={classes.ReportTitleContainer}>
        <Text className={classes.modalTitle}>{title}</Text>
      </div>
      <div className={classes.RadioButtonOptionsContainer}>
        <Text className={classes.modalSubTitle} >{subTitle}</Text>
        <RadioButtonGroup
          name="areAreRadio"
          value={selectedOption}
          withSpacing
          onChange={(value: any) => {
            setState({ selectedOption: value });
          }}
        >
          {radioButtonOptions?.map((item: any) => (
            <RadioButton
              className={classes.radioButtonWrapper}
              value={item?.value}
              label={item?.label}
            />
          ))}
        </RadioButtonGroup>
      </div>
      <div
        className={
          isMobile !== undefined && isMobile === true
            ? classes.reportActionsMobileContainer
            : classes.reportActionsContainer
        }
      >
        <Button
          upgrade
          priority={ButtonPriority.basicSecondary}
          className={st(classes.secondaryButtonContainer, {
            isDesktop: !isMobile,
          })}
          onClick={secondaryButtonHandleOnClick}
        >
          {secondaryButtonText}
        </Button>
        <Button
          upgrade
          className={st(classes.primaryButtonContainer, {
            isDesktop: !isMobile,
          })}
          onClick={primaryButtonHandleOnClick}
        >
          {primaryButtonText}
        </Button>
      </div>
    </div>
  );
};

export default ReportWrapper;
