import React, { useEffect } from 'react';
import { Button, ButtonPriority, Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../DeleteWrapper/DeleteWrapper.st.css';
import { joinCommunity } from '../../../Services/User.service';
import { setIsLoading } from '../../../Services/Loading.service';
import { useAppContext } from '../../AppContext';

const JoinCommunityWrapper = ({ handleOnClose, isMobile }: any) => {
  const { t }: { t: any } = useTranslation();
  const { compId } = useAppContext();

  useEffect(() => {}, []);

  const actionMenuDeleteBi = (description: string) => {
    // bi event here.
  };
  const primaryButtonHandleOnClick = () => {
    setIsLoading(true, compId);
    joinCommunity(handleOnClose,compId);
  };

  const secondaryButtonHandleOnClick = () => {
    handleOnClose && handleOnClose();
  };

  const title = t('app.widget.accessModal.joinCommunity.title');
  const warningMessage = t('app.widget.accessModal.joinCommunity.subTitle');
  const secondaryButtonText = t(
    'app.widget.accessModal.joinCommunity.maybeLater',
  );
  const primaryButtonText = t('app.widget.accessModal.joinCommunity.confirm');
  return (
    <div className={classes.DeleteWrapperContainer}>
      <div className={classes.modalContent}>
        <Text className={st(classes.modalTitle, { isMobile })}>{title}</Text>
        <div className={classes.textContainer}>
          <Text className={st(classes.modalWarningContent, { isMobile })}>
            <div>{warningMessage}</div>
          </Text>
        </div>
        <Button
          upgrade
          priority={ButtonPriority.basicSecondary}
          className={classes.secondaryButtonContainer}
          onClick={secondaryButtonHandleOnClick}
        >
          {secondaryButtonText}
        </Button>
        <Button
          upgrade
          className={classes.primaryButtonContainer}
          onClick={primaryButtonHandleOnClick}
        >
          {primaryButtonText}
        </Button>
      </div>
    </div>
  );
};

export default JoinCommunityWrapper;
