import React from 'react';
import { st, classes } from './EmptyStates.st.css';
import { Button, ButtonPriority, Divider, Text } from 'wix-ui-tpa';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../Services/AppSettings.service';
import {
  st as stSharedDivider,
  classes as dividerClasses,
} from '../SharedDivider.st.css';

const EmptyStates = ({
  title,
  subTitle,
  primaryText,
  secondaryText,
  primaryButtonOnClick,
  secondaryButtonOnClick,
}: any) => {
  const [, appSettings]: any = useObservable(getAppSettings());
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  return (
    <>
      {!isMobile && (
        <Divider
          className={stSharedDivider(dividerClasses.dividerStyle, {
            isEditorX,
            isMobile,
          })}
        />
      )}
      <div
        className={
          classes.emptyStatesContainer +
          ' ' +
          (isMobile ? classes.mobileContainer : '')
        }
      >
        <Text className={classes.emptyStatesTitle}> {title} </Text>
        <Text className={classes.emptyStatesSubTitle}> {subTitle} </Text>
        <div
          className={
            isMobile
              ? classes.emptyStatesBtn_container_mobile
              : classes.emptyStatesBtn_container
          }
        >
          {primaryText && (
            <Button
              priority={ButtonPriority.basicSecondary}
              onClick={primaryButtonOnClick}
              className={classes.secondaryButtonContainer}
            >
              {primaryText}
            </Button>
          )}
          {secondaryText && (
            <Button
              className={classes.primaryButtonContainer}
              onClick={secondaryButtonOnClick}
            >
              {secondaryText}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default EmptyStates;
