import { FileSharingService } from '../Api/Api';
import {
  getSelectedFolder$,
  setSelectedFolder$,
} from '../Observables/SelectedFolder.observable';
import { setIsServerError } from './ServerError.service';
import { lastValueFrom, take } from 'rxjs';
import {
  getSearchStatus$,
  setSearchStatus$,
  getSearchSubject$,
  setSearchSubject$,
  getDisableButtonsStatus$,
  setDisableButtonsStatus$,
} from '../Observables/Search.observable';
import { setIsLoading } from './Loading.service';
import {
  authorizeActions,
  getSelectedFolderId,
  setSelectedFolder,
  setSelectedFolderId,
} from './SelectedFolder.service';
import { getSort } from './Sort.service';
import biService from '../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_SEARCH } from '../BiEvents/Constants/BiConstants';
import { setBreadCrumbs, getBreadCrumbs } from './BreadCrumbs.service';

let API: any;
API = new FileSharingService();

// const searchService = async (value: string) => {
//   let result = null;
//   try {
//     result = await SearchApi(value);
//     // eslint-disable-next-line prettier/prettier
//   } catch (e: any) {
//     result = e;
//   }
//   return result;
// };

export const setSearchSubject = (searchSubject: any, compId: string) => {
  setSearchSubject$(searchSubject, compId);
};

export const getSearchSubject = (compId: string) => {
  return getSearchSubject$(compId);
};
export const setSearchStatus = (searchStatus: any, compId: string) => {
  setSearchStatus$(searchStatus, compId);
};

export const getSearchStatus = (compId: string) => {
  return getSearchStatus$(compId);
};

export const setDisableButtonsStatus = (
  disableButtonsStatus: boolean,
  compId: string,
) => {
  setDisableButtonsStatus$(disableButtonsStatus, compId);
};

export const getDisableButtonsStatus = (compId: string) => {
  return getDisableButtonsStatus$(compId);
};

export const searchService = async (compId: string) => {
  let results_count = 0;
  let folder_name = '';
  const selectedFolder = await getSelectedFolder(compId);
  if (selectedFolder === undefined) {
    return;
  }
  const searchValue = await getSearchValue(compId);
  if (!searchValue.callApi) {
    if (searchValue.value === '') {
      setSearchStatus(false, compId);
    } else {
      setSearchStatus(true, compId);
    }
    setIsLoading(false, compId);
    return;
  }
  setIsLoading(true, compId);
  setSearchStatus(undefined, compId);
  const selectedFolderId = await getCurrentSelectedId(compId);
  const sortValue = await getSortValue(compId);
  const breadCrumbs = await getBreadCrumbsValue(compId);
  try {
    const query: any = {
      sort: {
        sortBy: sortValue.sortBy,
        orientation: sortValue.orientation,
      },
    };

    if (searchValue && searchValue.value !== '') {
      query.filter = {
        descendantsOfFolderId: selectedFolderId,
        search: searchValue.value,
      };
    } else {
      query.filter = {
        parentLibraryItemIds: [selectedFolderId],
      };
    }

    const res = await API.searchByValue(query);

    if (
      res?.data &&
      res?.data?.libraryItems &&
      res?.data?.libraryItems?.length > 0
    ) {
      results_count = res?.data?.libraryItems?.length;
      const authorizedFolders = await authorizeActions(
        res.data,
        res.data.libraryItems[0].parentFolderId,
        compId,
      );
      if (authorizedFolders !== undefined && authorizedFolders?.libraryItems) {
        const data = {
          libraryItems: authorizedFolders.libraryItems,
          folderAuthorizedActions: authorizedFolders.folderAuthorizedActions,
          metaData: res.data.metaData,
        };
        setSelectedFolder$(data, compId);
      }
      setIsLoading(false, compId);
      if (searchValue && searchValue.value !== '') {
        setSearchStatus(true, compId);
      } else {
        setSearchStatus(false, compId);
      }
    } else {
      const selectedFolderLastValue = await getSelectedFolder(compId);
      setSelectedFolder$(
        {
          ...selectedFolderLastValue,
          ...res.data,
        },
        compId,
      );
      if (searchValue && searchValue.value !== '') {
        setSearchStatus(true, compId);
      } else {
        setSearchStatus(false, compId);
      }
      setIsLoading(false, compId);
    }
    folder_name =
      breadCrumbs && breadCrumbs?.length > 0
        ? breadCrumbs[breadCrumbs?.length - 1]?.value
        : breadCrumbs[0]?.value;
    biService(
      {
        origin: breadCrumbs?.length === 1 ? 'home' : 'folder',
        results_count,
        search_text: searchValue?.value,
        folder_name,
      },
      BI_FILE_SHARE_WIDGET_SEARCH,
      compId,
    );
  } catch (e) {
    const error: any = e;
    setIsServerError(
      {
        isError: true,
        message: error.message,
      },
      compId,
    );
    setIsLoading(false, compId);
    return error.message;
  }
};

export const getCurrentSelectedId = async (compId: string) => {
  const tempResult = getSelectedFolderId(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const getSearchValue = async (compId: string) => {
  const tempResult = getSearchSubject(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const getSortValue = async (compId: string) => {
  const tempResult = getSort(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
export const getSelectedFolder = async (compId: string) => {
  const tempResult = getSelectedFolder$(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
const getBreadCrumbsValue = async (compId: string) => {
  const tempResult = getBreadCrumbs(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

export const resetSearch = async (compId: string) => {
  setSearchStatus(false, compId);
  setSearchSubject({ callApi: true, value: '' }, compId);
  setDisableButtonsStatus(false, compId);
};

export const navigateToParentFolder = async (item: any, compId: string) => {
  try {
    setIsLoading(true, compId);
    const tempResult = getSelectedFolderId(compId).asObservable();
    const lastTempResult = tempResult.pipe(take(1));
    const previousResult = await lastValueFrom(lastTempResult);
    const folderId =
      item.path.length > 0 ? item.path[item.path.length - 1].libraryItemId : '';

    const breadCrumbs = item.path
      .slice(1)
      .map((folder: { name: string; libraryItemId: string }) => {
        return {
          value: folder.name,
          id: folder.libraryItemId,
        };
      });
    setBreadCrumbs(breadCrumbs, compId);
    setSearchSubject({ callApi: false, value: '' }, compId);
    setDisableButtonsStatus(false, compId);
    if (folderId === previousResult) {
      setSelectedFolder(folderId, compId);
    } else {
      setSelectedFolderId(folderId, compId);
    }
  } catch (e) {}
};
