import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { getSelectedFolder } from '../Services/SelectedFolder.service';
import { getObservableById, setObservableById } from './helper';
import { setSelectedFolder$ } from './SelectedFolder.observable';

export enum AddFolderStatus {
  CLOSED,
  OPENED,
  IN_PROGRESS,
}
const addFolderStatus$ = new Map();

export const getAddFolderStatus$ = (compId: string) =>
  getObservableById(addFolderStatus$, compId, AddFolderStatus.CLOSED);

export const setAddFolderStatus$ = (status: AddFolderStatus, compId: string) =>
  setObservableById(addFolderStatus$, status, compId);

export const addFolder$ = async (newFolder: any, compId: string) => {
  const tempResult = getSelectedFolder(compId).asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.libraryItems = [newFolder, ...newResult.libraryItems];
  setSelectedFolder$(newResult, compId);
};
