import React, { useEffect, useState } from 'react';
import { st, classes as styles } from './ItemGenerator.st.css';
import ItemLastUpdated from '../ItemLastUpdated/ItemLastUpdated';
import ItemViews from '../ItemViews/ItemViews';
import ItemFavorites from '../ItemFavorites/ItemFavorites';
import ItemContributors from '../ItemContributors/ItemContributors';
import ItemActions from '../ItemActions/ItemActions';
import ItemCheckBox from '../ItemCheckBox/ItemCheckBox';
import ItemName from '../ItemName/ItemName';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import {
  addToBreadCrumbs,
  setBreadCrumbs,
} from '../../../../../../Services/BreadCrumbs.service';
import {
  setSelectedFolderId,
  viewFileService,
} from '../../../../../../Services/SelectedFolder.service';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import { setIsLoading } from '../../../../../../Services/Loading.service';
import { getSelectedItems } from '../../../../../../Services/SelectedItems.service';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import {
  getSearchSubject,
  setDisableButtonsStatus,
  setSearchSubject,
} from '../../../../../../Services/Search.service';
import withMemberAuthorization from '../../../../../../../Shared/HOC/withMemberAuthorization';
import { Status } from '../../../../../../../Constants/Permissions';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER } from '../../../../../../BiEvents/Constants/BiConstants';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';
import { ItemInfoMobileView } from './ItemInfoMobileView';
import { useAppContext } from '../../../../../../Shared/AppContext';

const ItemGenerator = (props: any) => {
  const { item, memberAuthorization } = props;
  const { compId } = useAppContext();
  const [error, selectedItems]: any = useObservable(getSelectedItems(compId));
  const [error2, tableSettings]: any = useObservable(getTableSettings(compId));
  const [error3, appSettings]: any = useObservable(getAppSettings());
  const [error4, searchObj]: any = useObservable(getSearchSubject(compId));
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const [state, setState] = useState({
    isShowItemLastUpdated: false,
    isShowItemViews: false,
    isShowItemFavorites: false,
    isShowItemContributors: false,
  });
  const handleOnItemClick = (receivedItem: any) => {
    const folderPermissions =
      item &&
      item.authorizeActions?.find(
        (itemData: any) => itemData.action === 'VIEW',
      );
    if (folderPermissions.status === Status.FORBIDDEN) {
      memberAuthorization({
        args: [receivedItem],
        callBack: handleChangeFolder,
      });
    } else {
      handleChangeFolder(receivedItem);
    }
  };
  const handleChangeFolder = (receivedItem: any) => {
    if (ITEMTYPE.FILE in item) {
      viewFileService(item.id, compId);
    } else if (ITEMTYPE.FOLDER in item) {
      if (
        !(
          searchObj &&
          searchObj?.value !== undefined &&
          searchObj?.value !== ''
        )
      ) {
        const obj = {
          value:
            receivedItem.name.length > 20
              ? `${receivedItem.name.substr(0, 17)}...`
              : receivedItem.name,
          id: receivedItem.id,
        };
        addToBreadCrumbs(obj, compId);
      } else {
        const newBreadCrumbs = item.path
          .slice(1)
          .map((folder: { name: string; libraryItemId: string }) => {
            return {
              value: folder.name,
              id: folder.libraryItemId,
            };
          });

        const currentFolder = {
          value:
            receivedItem.name.length > 20
              ? `${receivedItem.name.substr(0, 17)}...`
              : receivedItem.name,
          id: receivedItem.id,
        };
        newBreadCrumbs.push(currentFolder);
        setBreadCrumbs(newBreadCrumbs, compId);
      }
      setSelectedFolderId(receivedItem.id, compId);
      setSearchSubject({ callApi: false, value: '' }, compId);
      setDisableButtonsStatus(false, compId);
      if (appSettings && appSettings.scrollTo) {
        appSettings.scrollTo();
      }
    }
    biService(
      {
        file_id: receivedItem?.id,
        file_name: receivedItem?.name,
        file_owner: receivedItem?.createdByName,
        file_size: receivedItem?.fileFields
          ? receivedItem?.fileFields?.size
          : receivedItem?.folderFields?.childrenCount,
        file_type: receivedItem?.fileFields
          ? receivedItem?.fileFields?.extension
          : '',
        favorites_count: receivedItem?.timesFavorited,
        is_folder: !receivedItem?.fileFields,
        views_count: receivedItem?.uniqueViews,
        is_restricted: receivedItem?.availability !== 'PUBLIC',
      },
      BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER,
      compId,
    );
    setIsLoading(true, compId);
  };

  const _setIsShown = () => {
    const tempIsShowItemLastUpdated = tableSettings.filter((item: any) => {
      if (item.id === 'LAST_UPDATED') {
        return item;
      }
    });
    const tempIsShowItemViews = tableSettings.filter((item: any) => {
      if (item.id === 'VIEWS_COUNTER') {
        return item;
      }
    });
    const tempIsShowItemFavorites = tableSettings.filter((item: any) => {
      if (item.id === 'FAVORITES') {
        return item;
      }
    });
    const tempIsShowItemContributors = tableSettings.filter((item: any) => {
      if (item.id === 'CONTRIBUTORS') {
        return item;
      }
    });
    if (
      tempIsShowItemLastUpdated &&
      tempIsShowItemLastUpdated.length > 0 &&
      tempIsShowItemViews &&
      tempIsShowItemViews.length > 0 &&
      tempIsShowItemFavorites &&
      tempIsShowItemFavorites.length > 0 &&
      tempIsShowItemContributors &&
      tempIsShowItemContributors.length > 0
    ) {
      setState((prev) => ({
        ...prev,
        isShowItemLastUpdated: tempIsShowItemLastUpdated[0].value,
        isShowItemViews: tempIsShowItemViews[0].value,
        isShowItemFavorites: tempIsShowItemFavorites[0].value,
        isShowItemContributors: tempIsShowItemContributors[0].value,
      }));
    }
  };

  useEffect(() => {
    if (tableSettings && tableSettings.length > 0) {
      _setIsShown();
    }
  }, [tableSettings]);
  const actionsMenu = () => {
    return (
      <div
        className={
          isMobile
            ? styles.itemGeneratorMobileContainerActions
            : styles.itemGeneratorContainerActions
        }
      >
        {selectedItems && selectedItems.length > 0 ? (
          <></>
        ) : (
          <ItemActions item={item} />
        )}
      </div>
    );
  };
  return (
    <div
      className={
        isMobile !== undefined && !isMobile
          ? st(styles.itemGeneratorMainContainer, { isEditorX })
          : styles.itemGeneratorMainContainerMobile
      }
    >
      {(isMobile && selectedItems && selectedItems.length > 0) || !isMobile ? (
        <div className={styles.itemGeneratorContainerCheckBox}>
          <ItemCheckBox item={item} />
        </div>
      ) : (
        <div
          className={isMobile ? '' : styles.itemGeneratorContainerCheckBox}
        ></div>
      )}

      <div
        className={
          styles.itemGeneratorContainerName +
          ' ' +
          (isMobile ? styles.itemGeneratorContainerNameMobileView : '')
        }
      >
        <div
          className={
            isMobile
              ? styles.itemNameAndActionsMenuContainerMobile
              : styles.itemNameAndActionsMenuContainer
          }
        >
          <ItemName item={item} handleChangeFolder={handleOnItemClick} />
          {isMobile && actionsMenu()}
        </div>
        <div>
          {isMobile && (
            <div className={styles.itemGeneratorContainerMobileView}>
              <ItemInfoMobileView item={item} />
            </div>
          )}
        </div>
      </div>

      {state.isShowItemLastUpdated === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerLastUpdated}>
          <ItemLastUpdated item={item} />
        </div>
      ) : null}

      {state.isShowItemViews === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerViews}>
          <ItemViews item={item} />
        </div>
      ) : null}

      {state.isShowItemFavorites === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerFavorites}>
          <ItemFavorites item={item} />
        </div>
      ) : null}

      {state.isShowItemContributors === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerContributors}>
          <ItemContributors item={item} />
        </div>
      ) : null}

      {!isMobile && actionsMenu()}
    </div>
  );
};

export default withMemberAuthorization(ItemGenerator);
