import { TOAST } from '../../Constants/Toast.Constants';
import { FileSharingService } from '../Api/Api';
import { setIsLoading } from './Loading.service';
import { setToast } from './Toast.service';
import biService from '../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_FAVORITE_CLICK } from '../BiEvents/Constants/BiConstants';

let API: any = null;
API = new FileSharingService();

export const favoriteChange = async (
  item: any,
  value: boolean,
  t: any,
  compId: string,
) => {
  try {
    const response = await API.favoriteChangeAPI({
      actions: [
        {
          libraryItemId: item?.id,
          isFavorite: value,
        },
      ],
    });
    if (response?.message) {
      setIsLoading(false, compId);
      setToast({
        skin: TOAST.error,
        content: response.message,
      });
    } else {
      setIsLoading(false, compId);
      if (value)
      setToast({
        skin: TOAST.success,
        content: t('app.widget.favorites.add.success.notification'),
      });
      else{
        setToast({
          skin: TOAST.success,
          content: t('app.widget.favorites.remove.success.notification'),
        });
      }
      biService(
        {
          file_id: item?.id,
          file_name: item?.name,
          file_owner: item?.createdByName,
          file_size: item?.fileFields
            ? item?.fileFields?.size
            : item?.folderFields?.childrenCount,
          file_type: item?.fileFields ? item?.fileFields?.extension : '',
          favorites_count: item?.timesFavorited,
          is_folder: !item?.fileFields,
          views_count: item?.uniqueViews,
          file_owner_id: item?.createdByProfile?.id,
        },
        BI_FILE_SHARE_WIDGET_FAVORITE_CLICK,
        compId,
      );
    }
  } catch (e) {
    const error: any = e;
    setToast({
      skin: TOAST.error,
      content: error.message,
    });
    throw error;
  }
};
