import React from 'react';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../../../Services/SelectedItems.service';
import SearchTextField from '../../../HeaderWrapper/SearchTextField/SearchTextField';
import SelectedItemsAndActionsToolbar from './SelectedItemsAndActionsToolbar';
import { getTableSettings } from '../../../../../Services/TableSettings.service';
import { TableColumns } from '../../../../../../Constants/TableHeader.Constants';
import { getRootFolder } from '../../../../../Services/RootFolder.service';
import { getUser } from '../../../../../Services/User.service';
import { Status } from '../../../../../../Constants/Permissions';
import { classes } from './MobileHeaderBottom.st.css';
import { useAppContext } from '../../../../../Shared/AppContext';

const MobileHeaderBottomWrapper = () => {
  const { compId } = useAppContext();
  const [, selectedItems]: any = useObservable(getSelectedItems(compId));
  const [, tableDisplaySettings]: any = useObservable(getTableSettings(compId));
  const [, rootFolder]: any = useObservable(getRootFolder(compId));
  const [, user]: any = useObservable(getUser());
  const isUserLoggedIn = user && user?.isLoggedIn;
  const viewPermissions =
    rootFolder &&
    rootFolder.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );
  const displaySearch =
    tableDisplaySettings &&
    tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.SEARCH_BAR,
    );

  return (
    <>
      {selectedItems !== undefined && selectedItems?.length === 0 ? (
        displaySearch &&
        displaySearch?.value &&
        rootFolder !== undefined &&
        selectedItems &&
        selectedItems.length <= 0 &&
        !(
          (viewPermissions && viewPermissions.status === Status.FORBIDDEN) ||
          !isUserLoggedIn
        ) ? (
          <SearchTextField />
        ) : displaySearch && !displaySearch?.value ? (
          <div className={classes.hiddenSearchContainer}></div>
        ) : (
          <></>
        )
      ) : (
        <SelectedItemsAndActionsToolbar />
      )}
    </>
  );
};

export default MobileHeaderBottomWrapper;
