import React from 'react';
import { st, classes } from './MoveContent.st.css';
// import { onRowClick } from '../../../Services/Move/Move.service';
import Folder from 'wix-ui-icons-common/on-stage/Folder';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import { MoveEmptyState } from '../MoveEmptyState/MoveEmptyState';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../Services/SelectedItems.service';
import { getMenuSelectedItem } from '../../../Services/MenuSelectedItem';
import { classes as sharedClasses } from '../../SharedText.st.css';
import { useAppContext } from '../../AppContext';

export const MoveContent = (props: any) => {
  const { currentSubFolders, handleIsRowClicked, isMobile } = props;
  const { compId } = useAppContext();
  const [error1, selectedItemsIds]: any = useObservable(
    getSelectedItems(compId),
  );
  const [error2, currentItem]: any = useObservable(getMenuSelectedItem());

  return (
    <div>
      {currentSubFolders?.length !== 0 ? (
        currentSubFolders?.map((data: any) => {
          const disabledRowClassName =
            selectedItemsIds !== undefined && selectedItemsIds.length > 0
              ? selectedItemsIds?.includes(data.folderId)
                ? classes.disabledRow
                : ''
              : currentItem?.id === data.folderId
              ? classes.disabledRow
              : '';
          return (
            <div
              id={data.folderId}
              onClick={() => {
                if (!disabledRowClassName) {
                  handleIsRowClicked(true, data.folderId, data.name);
                }
              }}
            >
              <div className={classes.row + ' ' + disabledRowClassName}>
                <span className={classes.iconStyle}>
                  <Folder />
                </span>
                <div
                  className={
                    classes.folder_Name +
                    ' ' +
                    sharedClasses.contentEllipsis +
                    ' ' +
                    (isMobile ? classes.foldr_name_mobile : ' ')
                  }
                >
                  {data.name}
                </div>
                <span className={classes.iconStyle}>
                  <ChevronRight />
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <MoveEmptyState />
      )}
    </div>
  );
};
