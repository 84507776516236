import React from 'react';
import { classes } from './ReportDialogResponse.st.css';
import { Button, Text, TextTypography } from 'wix-ui-tpa';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getUser } from '../../../Services/User.service';
import { ADMIN } from '../../../../Constants/Modals.Constants';
import { useTranslation } from '@wix/yoshi-flow-editor';

export const ReportDialogResponse = ({ handleOnClose, isMobile }: any) => {
  const { t }: { t: any } = useTranslation();
  const [error, user]: any = useObservable(getUser());
  const isAdmin: boolean = user?.role === ADMIN;
  const title = isAdmin
    ? t('app.widget.report.dialog.response.admin.title')
    : t('app.widget.report.dialog.response.title');
  const dialogResponseMsg = isAdmin
    ? t('app.widget.report.dialog.response.admin.message')
    : t('app.widget.report.dialog.response.message');
  const primaryButtonText = isAdmin
    ? t('app.widget.report.dialog.response.admin.GotIt')
    : t('app.widget.report.dialog.response.done');
  const subTitle = t('app.widget.report.dialog.response.sub.title');
  const primaryButtonHandleOnClick = () => {
    handleOnClose && handleOnClose();
  };
  return (
    <div>
      <div className={classes.modalContent}>
        <Text
          className={
            classes.modalTitle +
            ' ' +
            (isMobile ? classes.mobileModalTitle : '')
          }
        >
          {title}
        </Text>
        <div className={classes.textContainer}>
          <Text className={classes.dialogResponse}>
            {!isAdmin && <div>{subTitle}</div>}
            <div>{dialogResponseMsg}</div>
          </Text>
        </div>
        <Button
          upgrade
          className={classes.primaryButtonContainer}
          onClick={primaryButtonHandleOnClick}
        >
          {primaryButtonText}
        </Button>
      </div>
    </div>
  );
};
