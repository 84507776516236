import biService from '../BiService';
import {
  BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER,
  BI_FILE_SHARE_WIDGET_DETAILS,
  BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE,
  BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK,
  BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK,
} from '../Constants/BiConstants';

import {
  FILE_ICON_STYLE,
  SORT_BY_OPTIONS,
} from '../../../Constants/Display.Constants';

const menuClickBi = (actionName: string, item: any, compId: string) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields?.size,
      file_type: item?.fileFields?.extension,
      button_description: actionName,
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      num_of_files_selected: 1,
      views_count: item?.uniqueViews,
    },
    BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK,
    compId,
  );
};
const deleteFileOrFolderBi = (
  item: any,
  description: string,
  num_of_files_selected: number,
  compId: string,
) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields
        ? item?.fileFields?.size
        : item?.folderFields?.childrenCount,
      file_type: item?.fileFields ? item?.fileFields?.extension : '',
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      num_of_files_selected,
      views_count: item?.uniqueViews,
      description,
    },
    BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER,
    compId,
  );
};
const moveFileClickBi = (
  item: any,
  description: string,
  num_of_files_selected: number,
  compId: string,
) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields
        ? item?.fileFields?.size
        : item?.folderFields?.childrenCount,
      file_type: item?.fileFields ? item?.fileFields?.extension : '',
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      num_of_files_selected,
      views_count: item?.uniqueViews,
      description,
    },
    BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK,
    compId,
  );
};
const downloadFileBi = (item: any, compId: string) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields
        ? item?.fileFields?.size
        : item?.folderFields?.childrenCount,
      file_type: item?.fileFields ? item?.fileFields?.extension : '',
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      views_count: item?.uniqueViews,
      file_owner_id: item?.createdByProfile?.id,
    },
    BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE,
    compId,
  );
};

const getDisplaySettingsData = (settingsForTable: any) => {
  const displaySettingsObject: any = {};
  settingsForTable.forEach((data: any) => {
    Object.assign(displaySettingsObject, data);
  });
  return displaySettingsObject;
};

const getSortByLabelById = (sortBy: string) => {
  switch (sortBy) {
    case SORT_BY_OPTIONS.UNIQUE_VIEWS:
      return 'most viewed';
    case SORT_BY_OPTIONS.TIMES_FAVORITED:
      return 'most favorites';
    case SORT_BY_OPTIONS.LAST_MODIFIED:
      return 'last updated';
    case SORT_BY_OPTIONS.NAME_ASC:
      return 'A-Z';
    case SORT_BY_OPTIONS.NAME_DESC:
      return 'Z-A';
    case SORT_BY_OPTIONS.TYPE:
      return 'item type';
    case SORT_BY_OPTIONS.SIZE:
      return 'item size';
    default:
      return '';
  }
};
const getIconLabelById = (icon: string) => {
  switch (icon) {
    case FILE_ICON_STYLE.FILL_ICON:
      return 'classic';
    case FILE_ICON_STYLE.LABEL_ICON:
      return 'extension';
    case FILE_ICON_STYLE.LINE_ICON:
      return 'line';
    default:
      return '';
  }
};

const widgetDetailsBi = (
  displaySettings: any,
  sortSettings: any,
  alignmentSettings: any,
  fileIconStyleSettings: any,
  folderId: any,
  folderName: any,
  compId: string,
) => {
  const displaySettingsData = getDisplaySettingsData(displaySettings);
  const display_date = displaySettingsData?.LAST_UPDATED || false;
  const display_favorites = displaySettingsData?.FAVORITES || false;
  const display_favorites_counter =
    displaySettingsData?.FAVORITES_COUNTER || false;
  const display_owner = displaySettingsData?.CONTRIBUTORS || false;
  const display_search_bar = displaySettingsData?.SEARCH_BAR || false;
  const display_size = displaySettingsData?.FILE_SIZE_NUMBER_OF_ITEMS || false;
  const display_views = displaySettingsData?.VIEWS_COUNTER || false;

  biService(
    {
      display_date,
      display_favorites,
      display_favorites_counter,
      display_owner,
      display_search_bar,
      display_size,
      display_views,
      parent_folder_id: folderId,
      folder_name: folderName,
      icon: getIconLabelById(fileIconStyleSettings),
      layout_alignment: alignmentSettings === 'ltr',
      sort_by: getSortByLabelById(sortSettings),
    },
    BI_FILE_SHARE_WIDGET_DETAILS,
    compId,
  );
};

export {
  deleteFileOrFolderBi,
  menuClickBi,
  moveFileClickBi,
  downloadFileBi,
  widgetDetailsBi,
};
