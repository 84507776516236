import { TOAST } from '../../Constants/Toast.Constants';
import { FileSharingService } from '../Api/Api';
import { removeItemsFromSelectedFolder$ } from '../Observables/SelectedFolder.observable';
import { setIsLoading } from './Loading.service';
import { setOpenedModal } from './Modals.service';
import { setSelectedItems } from './SelectedItems.service';
import { setToast } from './Toast.service';
import {
  handleErrorsNotifications,
  setNotification,
} from '../Shared/DeleteNotificationHelper';

let API: any = null;
API = new FileSharingService();

export const deleteItemsFromFolder = async ({
  libraryItemIds,
  parentFolderId,
  t,
  biCallBack,
  compId,
}: any) => {
  try {
    setOpenedModal('',compId);
    const response = await API.delete({
      actions: [
        {
          libraryItemIds,
          parentFolderId,
        },
      ],
    });
    if (response.data) {
      setNotification(libraryItemIds, t, compId);
      removeItemsFromSelectedFolder$(libraryItemIds, compId);
      biCallBack && biCallBack('deleted');
    } else {
      handleErrorsNotifications(libraryItemIds, t, compId);
    }
    setIsLoading(false, compId);
    setSelectedItems([], compId);
  } catch (e) {
    const error: any = e;
    const text = t('app.widget.notification.delete.GENERAL_ERROR');
    setToast({
      skin: TOAST.error,
      content: text,
    });
    throw error;
  }
};
