import React from 'react';
import { FILE_ICON_STYLE } from '../../../Constants/Display.Constants';
import { PdfFill } from '../../icons/PDF_Fill';
import { PdfTag } from '../../icons/PDF_Tag';
import { FileOutline } from '../../icons/File_Outline';
import { ImgFill } from '../../icons/IMG_Fill';
import { ImgTag } from '../../icons/IMG_Tag';
import { VidFill } from '../../icons/VID_Fill';
import { VidTag } from '../../icons/VID_Tag';
import { MiscFill } from '../../icons/MISC_Fill';
import { MiscTag } from '../../icons/MISC_Tag';
import { XlsFill } from '../../icons/XLS_Fill';
import { XlsTag } from '../../icons/XLS_Tag';
import { DocFill } from '../../icons/DOC_Fill';
import { DocTag } from '../../icons/DOC_Tag';
import { PptFill } from '../../icons/PPT_Fill';
import { PptTag } from '../../icons/PPT_Tag';
import { returnFileType } from './FileTypeHelper';
import { FILETYPE } from '../../../Constants/FileIconConstants';

export const getFileIcon = (
  fileType: string,
  fileIconStyle: string,
  isMobile: boolean,
) => {
  const receivedType = returnFileType(fileType);
  switch (receivedType) {
    case FILETYPE.PDF:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <PdfFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <PdfTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.IMAGE:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <ImgFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <ImgTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }
    case FILETYPE.MEDIA:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <VidFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <VidTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }
    case FILETYPE.ARCHIEVE:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <MiscFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <MiscTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.XLS:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <XlsFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <XlsTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.DOC:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <DocFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <DocTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.PPT:
      if (fileIconStyle === FILE_ICON_STYLE.FILL_ICON) {
        return <PptFill isMobile={isMobile} />;
      } else if (fileIconStyle === FILE_ICON_STYLE.LABEL_ICON) {
        return <PptTag isMobile={isMobile} />;
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    default:
      return;
  }
};
