import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import {
  SORT_BY_OPTIONS,
  FILE_ICON_STYLE,
} from './Constants/Display.Constants';

export type ISettingsParams = {
  folderId: SettingsParamType.Text;
  selectedFolderIdInLiveSite: SettingsParamType.Text;
  displaySettings: SettingsParamType.Object;
  alignment: SettingsParamType.Text;
  sortBySettings: SettingsParamType.String;
  fileIconStyle: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  folderId: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => '-1',
  },
  selectedFolderIdInLiveSite: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => ``,
  },
  displaySettings: {
    type: SettingsParamType.Object,
    getDefaultValue: ({ t }) => [
      { SEARCH_BAR: true },
      { FILE_SIZE_NUMBER_OF_ITEMS: true },
      { LAST_UPDATED: true },
      { VIEWS_COUNTER: true },
      { FAVORITES: true },
      { FAVORITES_COUNTER: true },
      { CONTRIBUTORS: true },
    ],
  },
  alignment: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'ltr',
  },
  sortBySettings: {
    type: SettingsParamType.String,
    getDefaultValue: () => SORT_BY_OPTIONS.LAST_MODIFIED,
  },
  fileIconStyle: {
    type: SettingsParamType.String,
    getDefaultValue: () => FILE_ICON_STYLE.FILL_ICON,
  },
});
