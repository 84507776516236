import { fileShareViewAppPage } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetAppLoaded = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    folder_id,
    folder_name,
    biToken,
    isEditor,
  } = props;
  const params: any = {
    instance_id,
    app_id,
    visitor_id,
    comp_id,
    view_type: 'widget',
    app_site_id: instance_id,
    widget_folder_id: folder_id,
    folder_name,
  };
  if (isEditor) {
    params.biToken = biToken;
  }
  logger?.report(fileShareViewAppPage({ ...params }));
};
