import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;

  buttonsAndFavoriteColor: StyleParamType.Color;
  mainButtonsColor: StyleParamType.Color;
  buttonsFont: StyleParamType.Font;

  fillIconFolderColor: StyleParamType.Color;
  fillIconFileColor: StyleParamType.Color;
  labelIconLabelColor: StyleParamType.Color;
  labelIconLineColor: StyleParamType.Color;
  lineIconColor: StyleParamType.Color;
  folderNameFont: StyleParamType.Font;
  columnsTitleAndItemsInfoFont: StyleParamType.Font;
  itemsNameFont: StyleParamType.Font;
  textAndDividersColor: StyleParamType.Color;
  itemsHoverColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  backgroundColorWithoutOpacity: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  changeFileIconColor: StyleParamType.Boolean;
  buttonsRoundedCorner: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonsAndFavoriteColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  fillIconFolderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  fillIconFileColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  labelIconLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  labelIconLineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  lineIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  folderNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  columnsTitleAndItemsInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  itemsNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  textAndDividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemsHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.02),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  backgroundColorWithoutOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  changeFileIconColor: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  buttonsRoundedCorner: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
