import {
  getTableSettings$,
  setTableSettings$,
} from '../Observables/TableSettings.observable';

export const getTableSettings = (compId: string) => {
  return getTableSettings$(compId);
};

export const setTableSettings = (tableSettings: any, compId: string) => {
  setTableSettings$(tableSettings, compId);
};
