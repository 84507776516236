import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Divider, Spinner, Text } from 'wix-ui-tpa';
import { classes } from './LoadingWrapper.st.css';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../Services/AppSettings.service';
import { st, classes as dividerClasses } from '../SharedDivider.st.css';

const LoadingWrapper = () => {
  const { t } = useTranslation();
  const [, appSettings]: any = useObservable(getAppSettings());
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const text = t('app.widget.spinner.text');

  return (
    <>
      {!isMobile && (
        <Divider
          className={st(dividerClasses.dividerStyle, { isEditorX, isMobile })}
        />
      )}
      <div
        className={
          classes.loadingContainer +
          ' ' +
          (isMobile ? classes.mobileContainer : '')
        }
      >
        <div>
          <Spinner className={classes.spinnerWrapper} diameter={50} />
        </div>
        <div>
          <Text className={st(classes.loadingText, { isMobile })}> {text}</Text>
        </div>
      </div>
    </>
  );
};

export default LoadingWrapper;
