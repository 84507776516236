import {
  getMenuSelectedItem$,
  setMenuSelectedItem$,
} from '../Observables/MenuSelectedItem';

export const setMenuSelectedItem = (currentSelectedItem: any) => {
  setMenuSelectedItem$(currentSelectedItem);
};

export const getMenuSelectedItem = () => {
  return getMenuSelectedItem$();
};
