import {
  getBiEventsParams$,
  setBiEventsParams$,
} from '../Observables/BiEvents.observable';

export const getBiEventsParams = (compId: string) => {
  return getBiEventsParams$(compId);
};
export const setBiEventsParams = (biEventsParams: object, compId: string) => {
  setBiEventsParams$(biEventsParams, compId);
};
