import React from 'react';
import { classes } from './iconStyle.st.css';

export const FavoriteFill = (props: any) => {
  return (
    <svg
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props?.handleOnChange && props.handleOnChange();
        }
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.0999 9.9C18.9999 9.6 18.6999 9.3 18.3999 9.3L14.4999 8.8L12.6999 5.2C12.3999 4.6 11.3999 4.6 11.0999 5.2L9.39987 8.7L5.39987 9.3C5.09987 9.3 4.79988 9.6 4.69988 9.9C4.59988 10.2 4.69987 10.6 4.89987 10.8L7.69988 13.5L6.99987 17.5C6.89987 17.8 7.09987 18.2 7.39987 18.4C7.59987 18.5 7.69987 18.6 7.89987 18.6C7.99987 18.6 8.19987 18.6 8.29987 18.5L11.7999 16.6L15.3999 18.5C15.6999 18.7 16.0999 18.6 16.2999 18.4C16.4999 18.2 16.6999 17.9 16.6999 17.5L15.9999 13.6L18.8999 10.8C19.0999 10.6 19.1999 10.2 19.0999 9.9Z"
        fill="#7247FF"
        className={classes.favoriteIcon}
      />
    </svg>
  );
};
