import { take, lastValueFrom } from 'rxjs';
import { TOAST } from '../../Constants/Toast.Constants';
import { FileSharingService } from '../Api/Api';
import {
  getItemRenameStatus$,
  itemRenameStatusSubject$,
  setItemRenameStatus$,
  setRenamingItem$,
} from '../Observables/ItemRename.observable';
import { setIsLoading } from './Loading.service';
import { setToast } from './Toast.service';
import biService from '../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_RENAME_FILE } from '../BiEvents/Constants/BiConstants';
import { setSelectedFolder$ } from '../Observables/SelectedFolder.observable';
import { getSelectedFolder } from './Search.service';

let API: any = null;
API = new FileSharingService();

export const getItemRenameStatus = () => {
  return getItemRenameStatus$();
};
export const setRenameItemStatus = (renameFolderStatus: any) =>
  setItemRenameStatus$(renameFolderStatus);

export const setRenamingItem = (newName: string) => {
  setRenamingItem$(newName);
};

export const renameItem = async (t: any, item: any, compId: string) => {
  const tempResult = itemRenameStatusSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  try {
    const response = await API.renameItem({
      actions: [
        {
          libraryItemId: previousResult.folderId,
          newName: previousResult.newName,
          parentFolderId: previousResult.parentFolderId,
        },
      ],
    });
    if (response?.message) {
      setToast({
        skin: TOAST.error,
        content: t('app.widget.notification.renameFolder_Glitch'),
      });
    } else {
      setToast({
        skin: TOAST.success,
        content: t('app.widget.notification.renameFolder_Success'),
      });
      updateViewAfterRename(item?.id, previousResult.newName, compId);
      biService(
        {
          file_id: item?.id,
          file_name: item?.name,
          file_owner: item?.createdByName,
          file_size: item?.fileFields
            ? item?.fileFields?.size
            : item?.folderFields?.childrenCount,
          file_type: item?.fileFields ? item?.fileFields?.extension : '',
          favorites_count: item?.timesFavorited,
          is_folder: !item?.fileFields,
          views_count: item?.uniqueViews,
        },
        BI_FILE_SHARE_WIDGET_RENAME_FILE,
        compId,
      );
    }
    setIsLoading(false, compId);
    setRenameItemStatus({
      folderId: '',
      renamingStatus: false,
      parentFolderId: '',
      newName: '',
    });
  } catch (e) {
    const error: any = e;
    setToast({
      skin: TOAST.error,
      content: t('app.widget.notification.renameFolder_Glitch'),
    });
    setRenameItemStatus({
      folderId: '',
      renamingStatus: false,
      parentFolderId: '',
      newName: '',
    });
    throw error;
  }
};
const updateViewAfterRename = async (id: any, newName: string,compId: string) => {
  const selectedFolder: any = await getSelectedFolder(compId);
  selectedFolder.libraryItems = selectedFolder?.libraryItems.map(
    (item: any) => {
      if (item.id === id) {
        if (item?.name !== undefined) {
          if (item?.fileFields?.extension) {
            item.name = `${newName}.${item?.fileFields?.extension}`;
          } else {
            item.name = newName;
          }
        }
      }
      return item;
    },
  );
  setSelectedFolder$(JSON.parse(JSON.stringify(selectedFolder)),compId);
};
