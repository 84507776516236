import React from 'react';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa';
import CheckBoxComponent from '../CheckBoxComponent';
import { st, classes } from './TableHeaderSelected.st.css';
import { getSelectedItems } from '../../../../../Services/SelectedItems.service';
import { getAppSettings } from '../../../../../Services/AppSettings.service';
import { useAppContext } from '../../../../../Shared/AppContext';

const TableHeaderSelected = () => {
  const { compId } = useAppContext();
  const [, selectedItems]: any = useObservable(getSelectedItems(compId));
  const [, appSettings]: any = useObservable(getAppSettings());
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const { t }: { t: any } = useTranslation();

  return (
    <div
      className={
        st(classes.tableHeaderSelectedContainer, { isEditorX }) +
        ' ' +
        (isMobile ? classes.tableHeaderSelectedContainerMobile : '')
      }
    >
      {selectedItems !== undefined && (
        <>
          <CheckBoxComponent />
          <Text className={st(classes.selectedText, { isMobile })}>
            {t('app.widget.table.items.selected', {
              selectedItems: selectedItems.length,
            })}
          </Text>
        </>
      )}
    </div>
  );
};
export default TableHeaderSelected;
