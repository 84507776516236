import { getObservableById, setObservableById } from './helper';

export const isServerError$ = new Map();

export const getIsServerError$ = (compId: string) =>
  getObservableById(isServerError$, compId, {
    isError: false,
    message: '',
  });

export const setIsServerError$ = (error: any, compId: string) =>
  setObservableById(isServerError$, error, compId);
