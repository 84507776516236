import { useCallback, useEffect, useState } from 'react';

const useObservable = (observable: any) => {
  const [value, setValue] = useState();
  const [error, setError] = useState();

  const listenObservable = useCallback(() => {
    const subscription = observable.subscribe(setValue, setError);
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [observable]);

  useEffect(() => {
    const tempTimeOut = setTimeout(() => {
      listenObservable();
    }, 1);
    return () => {
      clearTimeout(tempTimeOut);
    };
  }, [listenObservable, observable]);

  return [error, value];
};

export default useObservable;
